import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../../generic';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export enum MedicalConditionAbdomenKeys {
  akutesAbdomen = 'AKUTES_ABDOMEN',
  giBlutung = 'GI_BLUTUNG',
  obereGiBlutung = 'OBERE_GI_BLUTUNG',
  untereGiBlutung = 'UNTERE_GI_BLUTUNG',
  kolik = 'KOLIK',
  enteritis = 'ENTERITIS',
}

export const MEDICAL_CONDITION_ABDOMEN_ELEMENT_KEY = 'erkrankungenAbdomen';

export type MedicalConditionAbdomenRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof MEDICAL_CONDITION_ABDOMEN_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          erkrankungenAbdomen?: MultiSelectFieldValueTyped<MedicalConditionAbdomenKeys>;
          erkrankungenAbdomenSonstige?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
    | {
        readonly inputState: InputState.NORMAL;
        readonly values: null;
      }
  );

const abdomenLabels: Record<MedicalConditionAbdomenKeys, string> = {
  [MedicalConditionAbdomenKeys.akutesAbdomen]: 'Akutes Abdomen',
  [MedicalConditionAbdomenKeys.giBlutung]: 'GI-Blutung',
  [MedicalConditionAbdomenKeys.obereGiBlutung]: 'obere',
  [MedicalConditionAbdomenKeys.untereGiBlutung]: 'untere',
  [MedicalConditionAbdomenKeys.kolik]: 'Kolik (z.B. Niere, Galle)',
  [MedicalConditionAbdomenKeys.enteritis]: 'Enteritis',
};

export function getAbdomenTupel(key: MedicalConditionAbdomenKeys): [MedicalConditionAbdomenKeys, string] {
  return [key, abdomenLabels[key]];
}

export function getAbdomenTupelsFor(keys: MedicalConditionAbdomenKeys[]): [MedicalConditionAbdomenKeys, string][] {
  return keys.map((k) => {
    return getAbdomenTupel(k);
  });
}
