import { useEffect, useState } from 'react';
import { AccessRoutePlacement, AccessRouteSide, AccessRouteValue } from '../../../../backendModels/access-route.model';
import Dialog from '@mui/material/Dialog';
import {
  Autocomplete,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material';
import {
  AccessRouteLocationKeys,
  AccessRouteSizeKeys,
  AccessRouteTypeKeys,
  getDefaultAccessRoute,
  getDefaultLocation,
  getLocationLabels,
  getSideLabels,
  getSizeLabels,
  getTypeLabels,
} from '../../../../models/accessRoute';
import _ from 'lodash';

function createAccessRoute(
  accessRoute: string,
  side: AccessRouteSide,
  location: string | undefined,
  size: string | undefined,
  alreadyPlaced: boolean | undefined,
): AccessRouteValue {
  return {
    accessRoute: accessRoute,
    side: side,
    location: location,
    size: size,
    accessRoutePlacement: alreadyPlaced
      ? AccessRoutePlacement.IS_ALREADY_PLACED
      : AccessRoutePlacement.VALUE_NOT_AVAILABLE,
  };
}

interface AddAccessRouteDialogProps {
  open: boolean;
  openChanged: (open: boolean) => void;
  /**
   * Callback that is invoked when a new accessRoute is added.
   * @param medication The access route that was added.
   */
  addAccessRoute: (medication: AccessRouteValue) => void;
}
export default function AddAccessRouteDialog(props: AddAccessRouteDialogProps) {
  const { open, openChanged, addAccessRoute } = props;

  const [accessRouteType, setAccessRouteType] = useState<AccessRouteTypeKeys>(getDefaultAccessRoute());
  const [location, setLocation] = useState<AccessRouteLocationKeys | undefined>(undefined);
  const [size, setSize] = useState<AccessRouteSizeKeys | undefined>(undefined);
  const [side, setSide] = useState<AccessRouteSide>(AccessRouteSide.NONE);
  const [alreadyPlaced, setAlreadyPlaced] = useState<boolean>(false);

  const typeLabels = getTypeLabels();
  const locationLabels = getLocationLabels(accessRouteType);
  const sideLabels = getSideLabels();
  const sizeLabels = getSizeLabels(accessRouteType);

  const enableDetails = accessRouteType !== AccessRouteTypeKeys.TRANSNASAL_APPLICATOR;

  useEffect(() => {
    setLocation(getDefaultLocation(accessRouteType));
    setSize(
      accessRouteType === AccessRouteTypeKeys.TRANSNASAL_APPLICATOR ? AccessRouteSizeKeys.NASAL_STANDARD : undefined,
    );
    setSide(AccessRouteSide.NONE);
    setAlreadyPlaced(false);
  }, [accessRouteType]);

  function handleAdd() {
    const newAccessRoute = createAccessRoute(
      accessRouteType as string,
      side,
      location as string,
      size as string,
      alreadyPlaced,
    );
    addAccessRoute(newAccessRoute);
    handleClose();
  }

  function handleClose() {
    const defaultAccessRoute = getDefaultAccessRoute();
    setAccessRouteType(defaultAccessRoute);
    setLocation(getDefaultLocation(defaultAccessRoute));
    setSize(undefined);
    setSide(AccessRouteSide.NONE);
    setAlreadyPlaced(false);
    openChanged(false);
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth closeAfterTransition={false}>
      <DialogTitle>Zugang hinzufügen</DialogTitle>
      <DialogContent>
        <div>
          <Autocomplete
            fullWidth
            options={typeLabels}
            value={_.find(typeLabels, { value: accessRouteType }) ?? null}
            onChange={(event, value) => setAccessRouteType(value?.value as AccessRouteTypeKeys)}
            renderInput={(params) => <TextField {...params} label='Art' />}
          />
          <div className='row'>
            <Autocomplete
              options={locationLabels}
              value={_.find(locationLabels, { value: location }) ?? null}
              onChange={(event, value) => setLocation(value?.value as AccessRouteLocationKeys)}
              fullWidth
              disabled={!enableDetails}
              renderInput={(params) => <TextField {...params} label='Ort' />}
            />
            <Autocomplete
              options={sideLabels}
              value={_.find(sideLabels, { value: side }) ?? null}
              onChange={(event, value) => setSide(value?.value as AccessRouteSide)}
              fullWidth
              disabled={!enableDetails}
              renderInput={(params) => <TextField {...params} label='Seite' />}
            />
          </div>
          <Autocomplete
            options={sizeLabels}
            value={_.find(sizeLabels, { value: size }) ?? null}
            onChange={(event, value) => setSize(value?.value as AccessRouteSizeKeys)}
            fullWidth
            disabled={!enableDetails}
            renderInput={(params) => <TextField {...params} label='Größe' />}
          />
          <FormControlLabel
            control={<Checkbox checked={alreadyPlaced} onChange={(event) => setAlreadyPlaced(event.target.checked)} />}
            label='Bereits vorhanden'
            className='add-margin-top'
            disabled={!enableDetails}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button className='cancel-button' onClick={handleClose} variant='outlined'>
          Abbrechen
        </Button>
        <Button onClick={handleAdd} autoFocus variant='contained'>
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
