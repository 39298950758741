import { VitalParameterCode } from '../backendModels/trends.model';
import { VitalParameterUnit } from '../backendModels/unit.model';

export function isTrendType(trendType: VitalParameterCode): trendType is VitalParameterCode {
  return (
    trendType === 'HR' ||
    trendType === 'NIBP_SYS' ||
    trendType === 'NIBP_DIA' ||
    trendType === 'SPO2' ||
    trendType === 'CO2'
  );
}

export enum TrendSelection {
  parameterIsSelected = 'PARAMETER_IS_SELECTED',
  valueNotAvailable = 'VALUE_NOT_AVAILABLE',
}

export interface TrendParameter {
  type: VitalParameterCode;
  value: number;
  unit: VitalParameterUnit;
  selection: TrendSelection;
}

export interface TrendEntry {
  timestamp: number;
  parameters: TrendParameter[];
}

export interface TrendsRecord {
  type: 'trends';
  elementKey: 'trends';
  trends: TrendEntry[];
}
