import { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { CardId } from '../../../../models/diviCard';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './PreIntervention.scss?inline';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { useFreshCallback } from '../../../../utils/hooks';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import {
  getPreInterventionTuplesFor,
  PRE_INTERVENTION_RECORD_ELEMENT_KEY,
  PreInterventionKeys,
  PreInterventionRecord,
} from '../../../../models/genericElements/preIntervention';
import { NodeType } from '../../../../backendModels/report.model';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import { isGenericRecordDeletable } from '../../../../models/generic';

const preInterventionCheckboxItems: [PreInterventionKeys, string][] = getPreInterventionTuplesFor([
  PreInterventionKeys.vorabinterventionmedikamente,
  PreInterventionKeys.vorabinterventionmassnahmen,
]);

interface PreInterventionProps {
  nodeType: NodeType;
}

export default function PreIntervention({ nodeType }: PreInterventionProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, PRE_INTERVENTION_RECORD_ELEMENT_KEY) as PreInterventionRecord;

  const note =
    record.inputState === InputState.ENTERED ? (record.values.vorabinterventionBeschreibung?.text ?? '') : '';
  const types = record.inputState === InputState.ENTERED ? (record.values.vorabInterventionTyp?.options ?? []) : [];

  const changeMeasuresNote = useFreshCallback((value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<PreInterventionRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value == '') {
          delete draft.values?.vorabinterventionBeschreibung;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        } else {
          draft.values = {
            ...draft.values,
            vorabinterventionBeschreibung: {
              fieldType: 'text',
              text: value,
            },
          };
        }
      },
      PRE_INTERVENTION_RECORD_ELEMENT_KEY,
    );
  });

  const handleChangePreInterventionCheckbox = (values: PreInterventionKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<PreInterventionRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (values.length === 0) {
          delete draft.values?.vorabInterventionTyp;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        } else {
          draft.values = {
            ...draft.values,
            vorabInterventionTyp: {
              fieldType: 'multiSelect',
              options: values,
            },
          };
        }
      },
      PRE_INTERVENTION_RECORD_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard cardType={CardId.PreIntervention}>
      <div className='symptoms-list'>
        <CheckboxList
          items={preInterventionCheckboxItems}
          selectedValues={types}
          onValuesChange={handleChangePreInterventionCheckbox}
        />
      </div>
      <DebouncedTextField
        id='measures-filled-textarea'
        label='Medikamente und Maßnahmen ...'
        multiline
        fullWidth
        rows={1}
        onDebounceChange={changeMeasuresNote}
        value={note}
      />
    </DiviFieldCard>
  );
}
