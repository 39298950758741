import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { NodeType } from '../../../../backendModels/report.model';
import {
  EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ExtendedPatientDataRecord,
  getNewBornAgeTuplesFor,
  NewBornAgeKeys,
} from '../../../../models/genericElements/extendedPatientData';
import RadioList from '../../../customs/radioList/RadioList';
import { InputState } from '../../../../backendModels/general.model';
import { useFreshCallback } from '../../../../utils/hooks';
import { Draft } from 'immer';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import _ from 'lodash';
import { isGenericRecordDeletable } from '../../../../models/generic';
import {
  PATIENT_DATA_RECORD_ELEMENT_KEY,
  PatientDataRecord,
  PatientPropertyKeys,
} from '../../../../models/genericElements/patientData';
import { getTouchedClassName } from '../../../../utils/recordWithTouched';

const newBornAgeRadioItems: [NewBornAgeKeys, string][] = getNewBornAgeTuplesFor([
  NewBornAgeKeys.eineWocheAlt,
  NewBornAgeKeys.zwischenZweiUndVierWochenAlt,
]);

interface AgeProps {
  nodeType: NodeType;
}

export default function Age({ nodeType }: AgeProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault('generic', nodeType, PATIENT_DATA_RECORD_ELEMENT_KEY) as PatientDataRecord;

  const extendedRecord = findRecordOrDefault(
    'generic',
    nodeType,
    EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ) as ExtendedPatientDataRecord;

  const handleNewBornAgeChange = useFreshCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ExtendedPatientDataRecord>) => {
        draft.inputState = InputState.ENTERED;
        draft.values = {
          ...draft.values,
          newbornAge: {
            fieldType: 'singleSelect',
            option: event.target.value as NewBornAgeKeys,
          },
        };
      },
      EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
    );
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<PatientDataRecord>) => {
        draft.inputState = InputState.ENTERED;
        draft.values = {
          ...draft.values,
          age: {
            fieldType: 'numeric',
            number: 0,
          },
          touched: {
            options: _.union([PatientPropertyKeys.age], draft.values?.touched?.options ?? []),
            fieldType: 'multiSelect',
          },
        };
      },
      PATIENT_DATA_RECORD_ELEMENT_KEY,
    );
  });

  const handleAgeChange = useFreshCallback((value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<PatientDataRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;

        if (value !== (draft.values?.age?.number?.toString() ?? '')) {
          draft.values = {
            ...draft.values,
            touched: {
              options: _.union([PatientPropertyKeys.age], draft.values?.touched?.options ?? []),
              fieldType: 'multiSelect',
            },
          };
        }

        if (value != '') {
          draft.values = {
            ...draft.values,
            age: {
              fieldType: 'numeric',
              number: Number(value),
            },
          };
          const newValue = Number(value);
          if (newValue > 0) {
            delete draft.values?.newbornAge;
          }
        } else {
          delete draft.values?.age;
        }

        if (isGenericRecordDeletable(draft)) {
          deleteRecord();
        }
      },
      PATIENT_DATA_RECORD_ELEMENT_KEY,
    );
  });

  return (
    <DiviFieldCard cardType={CardId.Age}>
      <div className='column'>
        <DebouncedTextField
          className={getTouchedClassName(record, PatientPropertyKeys.age)}
          label='Jahre'
          type='number'
          inputProps={{ step: 1, min: 0 }}
          value={record?.values?.age?.number.toString() ?? ''}
          onDebounceChange={handleAgeChange}
        />
        <RadioList
          className='symptoms-list add-margin-top'
          items={newBornAgeRadioItems}
          value={extendedRecord.values?.newbornAge?.option ?? null}
          onChange={handleNewBornAgeChange}
        />
      </div>
    </DiviFieldCard>
  );
}
