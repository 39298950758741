import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { Components, createTheme, Theme, ThemeProvider } from '@mui/material';
import corpulsTheme from '../Theme/CorpulsTheme';
import _ from 'lodash';

// These objects are tracked by reference, i.e. a change of a reference count value will not trigger a rerender.
// If the component is not attached directly under document (e.g. in a shadow dom), you need to provide your own values.
export interface CSSContextType {
  referenceCounts: Map<CSSStyleSheet, number>;
  adoptedStyleSheets: CSSStyleSheet[];
  additionalThemeComponents: Components<Omit<Theme, 'components'>>;
}

const cssContextDefaults: CSSContextType = {
  referenceCounts: new Map<CSSStyleSheet, number>(),
  adoptedStyleSheets: document.adoptedStyleSheets,
  additionalThemeComponents: {},
};

export const CSSContext = createContext(cssContextDefaults);

const stylesheetCache = new Map<string, CSSStyleSheet>();

function stringToStylesheet(content: string) {
  if (stylesheetCache.has(content)) {
    return stylesheetCache.get(content)!;
  }

  const stylesheet = new CSSStyleSheet();
  stylesheet.replaceSync(content);

  stylesheetCache.set(content, stylesheet);
  return stylesheet;
}

// Call this function whenever you want to use a stylesheet in a component.
export function useCSS(stylesheet: CSSStyleSheet | string) {
  const { referenceCounts, adoptedStyleSheets } = useContext(CSSContext);
  const stylesheetAsObject = stylesheet instanceof CSSStyleSheet ? stylesheet : stringToStylesheet(stylesheet);

  useEffect(() => {
    if (referenceCounts.has(stylesheetAsObject)) {
      referenceCounts.set(stylesheetAsObject, referenceCounts.get(stylesheetAsObject)! + 1);
    } else {
      referenceCounts.set(stylesheetAsObject, 1);
      adoptedStyleSheets.push(stylesheetAsObject);
    }

    return () => {
      referenceCounts.set(stylesheetAsObject, referenceCounts.get(stylesheetAsObject)! - 1);
      if (referenceCounts.get(stylesheetAsObject) === 0) {
        referenceCounts.delete(stylesheetAsObject);
        adoptedStyleSheets.splice(adoptedStyleSheets.indexOf(stylesheetAsObject), 1);
      }
    };
  }, [stylesheetAsObject, referenceCounts, adoptedStyleSheets]);
}

interface CSSContextProviderProps {
  value: CSSContextType;
  children: ReactNode;
}

export function CSSContextProvider({ value, children }: CSSContextProviderProps) {
  const { additionalThemeComponents } = value;

  const theme = useMemo(
    () => createTheme({ ...corpulsTheme, components: _.merge(corpulsTheme.components!, additionalThemeComponents) }),
    [additionalThemeComponents],
  );

  return (
    <CSSContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CSSContext.Provider>
  );
}
