import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { TextFieldValue } from '../../backendModels/generic-element.model';
import { MultiSelectFieldValueTyped } from '../generic';

export enum RefusalOfMeasuresKeys {
  massnahmenVerweigerung = 'BEMERKUNGEN_VERWEIGERUNG_MASSNAHMEN',
}

const refusalOfMeasuresLabels: Record<RefusalOfMeasuresKeys, string> = {
  [RefusalOfMeasuresKeys.massnahmenVerweigerung]: 'Maßnahmenverweigerung (ggf. detailliert angeben)',
};

export const REMARKS_RECORD_ELEMENT_KEY = 'bemerkungen';

export type RemarksRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof REMARKS_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          bemerkungenVerweigerung?: MultiSelectFieldValueTyped<RefusalOfMeasuresKeys>;
          bemerkungenText?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export function getRefusalOfMeasuresTuple(key: RefusalOfMeasuresKeys): [RefusalOfMeasuresKeys, string] {
  return [key, refusalOfMeasuresLabels[key]];
}

export function getRefusalOfMeasuresTupleFor(keys: RefusalOfMeasuresKeys[]): [RefusalOfMeasuresKeys, string][] {
  return keys.map((k) => {
    return getRefusalOfMeasuresTuple(k);
  });
}
