import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { SingleSelectFieldValueTyped } from '../../generic';

export enum AccessRouteDifficultyKeys {
  zugangerschwert = 'ZUGANG_ERSCHWERT',
  zugangerschwertmehrzweiversuche = 'ZUGANG_ERSCHWERT_MEHR_ZWEI_VERSUCHE',
  zugangerschwertunmoeglich = 'ZUGANG_ERSCHWERT_UNMOEGLICH',
}

export const ACCESS_ROUTE_DIFFICULTY_RECORD_ELEMENT_KEY = 'zugangErschwert';

export type AccessRouteDifficultyRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof ACCESS_ROUTE_DIFFICULTY_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          zugangErschwertBeschreibung?: SingleSelectFieldValueTyped<AccessRouteDifficultyKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export const accessRouteDifficultyLabels: Record<AccessRouteDifficultyKeys, string> = {
  [AccessRouteDifficultyKeys.zugangerschwert]: 'Zugang erschwert',
  [AccessRouteDifficultyKeys.zugangerschwertmehrzweiversuche]: '> 2 Versuche',
  [AccessRouteDifficultyKeys.zugangerschwertunmoeglich]: 'Zugang unmöglich > Verfahrenswechsel',
};

export function getAccessRouteDifficultyTuple(key: AccessRouteDifficultyKeys): [AccessRouteDifficultyKeys, string] {
  return [key, accessRouteDifficultyLabels[key]];
}

export function getAccessRouteDifficultyTuplesFor(
  keys: AccessRouteDifficultyKeys[],
): [AccessRouteDifficultyKeys, string][] {
  return keys.map((k) => {
    return getAccessRouteDifficultyTuple(k);
  });
}
