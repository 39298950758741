import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { TextFieldValue } from '../../backendModels/generic-element.model';
import { MultiSelectFieldValueTyped } from '../generic';

export enum FirstResponderKeys {
  firstrespondervorort = 'FIRST_RESPONDER_VOR_ORT',
  firstrespondertime = 'firstResponderTime',
}

export const FIRST_RESPONDER_RECORD_ELEMENT_KEY = 'firstResponder';

export type FirstResponderRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof FIRST_RESPONDER_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          firstResponder?: MultiSelectFieldValueTyped<FirstResponderKeys>;
          firstResponderTime?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export const handoverToLabels: Record<FirstResponderKeys, string> = {
  [FirstResponderKeys.firstrespondervorort]: 'vor Ort',
  [FirstResponderKeys.firstrespondertime]: 'ggf. Uhrzeit des Eintreffens',
};

export function getFirstResponderTuple(key: FirstResponderKeys): [FirstResponderKeys, string] {
  return [key, handoverToLabels[key]];
}
