import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped, SingleSelectFieldValueTyped } from '../../generic';
import { NumericFieldValue, TextFieldValue } from '../../../backendModels/generic-element.model';

export enum InjuriesAdditionKeys {
  verbrennung = 'VERBRENNUNG',
  veraetzung = 'VERAETZUNG',
  verschuettung = 'VERSCHUETTUNG',
  einklemmung = 'EINKLEMMUNG',
  inhalationstrauma = 'INHALATIONSTRAUMA',
  electrounfall = 'ELECTROUNFALL',
  beinahmeertrinken = 'BEINAHMEERTRINKEN',
  tauchunfall = 'TAUCHUNFALL',
  haemorrhagischerschock = 'HAEMORRHAGISCHER_SCHOCK',
  traumastumpf = 'TRAUMA_STUMPF',
  traumapenetrierend = 'TRAUMA_PENETRIEREND',
  sturzebenerdig = 'STURZ_EBENERDIG',
  sturzunter3M = 'STURZ_UNTER_3_M',
  sturzueber3M = 'STURZ_UEBER_3_M',
  fussgaenger = 'FUSSGAENGER',
  escooter = 'E_SCOOTER',
  fahrrad = 'FAHRRAD',
  ebike = 'EBIKE',
  motorrad = 'MOTORRAD',
  pkw = 'PKW',
  lkw = 'LKW',
  bus = 'BUS',
  schlag = 'SCHLAG',
  stich = 'STICH',
  schuss = 'SCHUSS',
  gewaltverbrechen = 'GEWALTVERBRECHEN',
  gewaltanwendungsonstige = 'GEWALTANWENDUNG_SONSTIGE',
  verbrennungengradeins = 'verbrennungenGradEins',
  verbrennungengradzweia = 'verbrennungenGradZweiA',
  verbrennungengradzweib = 'verbrennungenGradZweiB',
  verbrennungengraddrei = 'verbrennungenGradDrei',
  verbrennungengradfour = 'verbrennungenGradVier',
}

export const burnGradeLabels = [];

export const INJURIES_ADDITION_RECORD_ELEMENT_KEY = 'verletzungenErgaenzend';

export type InjuriesAdditionRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof INJURIES_ADDITION_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          verletzungAndere?: MultiSelectFieldValueTyped<InjuriesAdditionKeys>;
          verletzungenSonstige?: TextFieldValue;
          verbrennungenGradEins?: NumericFieldValue;
          verbrennungenGradZweiA?: NumericFieldValue;
          verbrennungenGradZweiB?: NumericFieldValue;
          verbrennungenGradDrei?: NumericFieldValue;
          verbrennungenGradVier?: NumericFieldValue;
          unfallmechanismus?: SingleSelectFieldValueTyped<InjuriesAdditionKeys>;
          sturz?: SingleSelectFieldValueTyped<InjuriesAdditionKeys>;
          verkehrsteilnehmer?: SingleSelectFieldValueTyped<InjuriesAdditionKeys>;
          gewaltanwendung?: MultiSelectFieldValueTyped<InjuriesAdditionKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
    | {
        readonly inputState: InputState.NORMAL;
        readonly values: null;
      }
  );

export const injuriesAdditionLabels: Record<InjuriesAdditionKeys, string> = {
  [InjuriesAdditionKeys.verbrennung]: 'Verbrennung, Verbrühung',
  [InjuriesAdditionKeys.veraetzung]: 'Verätzung',
  [InjuriesAdditionKeys.verschuettung]: 'Verschüttung',
  [InjuriesAdditionKeys.einklemmung]: 'Einklemmung',
  [InjuriesAdditionKeys.inhalationstrauma]: 'Inhalationstrauma',
  [InjuriesAdditionKeys.electrounfall]: 'Elektrounfall',
  [InjuriesAdditionKeys.beinahmeertrinken]: 'Beinahe Ertrinken',
  [InjuriesAdditionKeys.tauchunfall]: 'Tauchunfall',
  [InjuriesAdditionKeys.haemorrhagischerschock]: 'haemorrhagischer Schock',
  [InjuriesAdditionKeys.traumastumpf]: 'stumpf',
  [InjuriesAdditionKeys.traumapenetrierend]: 'penetrierend',
  [InjuriesAdditionKeys.sturzebenerdig]: 'ebenerdig',
  [InjuriesAdditionKeys.sturzunter3M]: '<3m',
  [InjuriesAdditionKeys.sturzueber3M]: '>3m',
  [InjuriesAdditionKeys.fussgaenger]: 'Fußgänger',
  [InjuriesAdditionKeys.escooter]: 'E-Scooter',
  [InjuriesAdditionKeys.fahrrad]: 'Fahrrad',
  [InjuriesAdditionKeys.ebike]: 'E-Bike o.ä.',
  [InjuriesAdditionKeys.motorrad]: 'Motorrad/Sozius',
  [InjuriesAdditionKeys.pkw]: 'PKW-Insasse',
  [InjuriesAdditionKeys.lkw]: 'LKW-Insasse',
  [InjuriesAdditionKeys.bus]: 'Bus-Insasse',
  [InjuriesAdditionKeys.schlag]: 'Schlag',
  [InjuriesAdditionKeys.stich]: 'Stich',
  [InjuriesAdditionKeys.schuss]: 'Schuss',
  [InjuriesAdditionKeys.gewaltverbrechen]: 'Gewaltverbrechen',
  [InjuriesAdditionKeys.gewaltanwendungsonstige]: 'Sonstige',
  [InjuriesAdditionKeys.verbrennungengradeins]: 'I',
  [InjuriesAdditionKeys.verbrennungengradzweia]: 'IIa',
  [InjuriesAdditionKeys.verbrennungengradzweib]: 'IIb',
  [InjuriesAdditionKeys.verbrennungengraddrei]: 'III',
  [InjuriesAdditionKeys.verbrennungengradfour]: 'IV',
};

export function getInjuriesAdditionTuple(key: InjuriesAdditionKeys): [InjuriesAdditionKeys, string] {
  return [key, injuriesAdditionLabels[key]];
}

export function getInjuriesAdditionTuplesFor(keys: InjuriesAdditionKeys[]): [InjuriesAdditionKeys, string][] {
  return keys.map((k) => {
    return getInjuriesAdditionTuple(k);
  });
}
