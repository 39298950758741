import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useContext, useEffect, useState } from 'react';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './AddOxygenDialog.scss?inline';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CheckCircleOutline } from '@mui/icons-material';
import { defaultRecords } from '../../../../DefaultRecords';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../../backendModels/report.model';
import { Tooltip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

export interface AddOxygenDialogResponse {
  timestamp: Dayjs;
  oxygenFlowValue: number;
}

interface AddOxygenDialogProps {
  isDialogOpen: boolean;
  onOpenChanged: (open: boolean) => void;
  onConfirm: (oxygen: AddOxygenDialogResponse) => void;
}

export function AddOxygenDialog(props: AddOxygenDialogProps) {
  useCSS(style);

  const reportAPI = useContext(ReportsAPIContext);
  const nodes = reportAPI.findNodeWithRecord(NodeType.GENERIC, defaultRecords.oxygen.type);

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [oxygenValue, setOxygenValue] = useState<number | undefined>(undefined);
  const onOxygenValueChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const txtValue = event.target.value;
    setOxygenValue(txtValue !== '' ? Number(txtValue) : undefined);
  };

  const [oxygenTimestamp, setOxygenTimestamp] = useState<Dayjs | null>(null);

  const onChangeOxygenTime = (newTime: Dayjs | null) => {
    setOxygenTimestamp(newTime);
  };

  function checkForNoDuplicate(timestamp: Dayjs) {
    if (timestamp === null) return false;
    const duplicates = nodes.filter(
      (node) =>
        dayjs(node.effectiveTimestamp).minute() === dayjs(timestamp).minute() &&
        dayjs(node.effectiveTimestamp).hour() === dayjs(timestamp).hour(),
    );
    return duplicates.length === 0;
  }

  function handleClose() {
    props.onOpenChanged(false);
    setTimeout(() => {
      setOxygenValue(undefined);
      setOxygenTimestamp(null);
      setIsConfirmed(false);
    }, 200);
  }

  function handleAdd() {
    if (!isAddable()) {
      return;
    }

    props.onConfirm({ timestamp: oxygenTimestamp!, oxygenFlowValue: oxygenValue! });

    setIsConfirmed(true);
    setTimeout(handleClose, 1000);
  }

  function isAddable() {
    if (checkForNoDuplicate(oxygenTimestamp!)) {
      return oxygenValue !== undefined && oxygenTimestamp !== null && oxygenTimestamp.isValid();
    }
    return false;
  }

  useEffect(() => {
    if (props.isDialogOpen) {
      setOxygenTimestamp(dayjs());
    }
  }, [props.isDialogOpen]);

  return (
    <Dialog open={props.isDialogOpen} className='add-oxygen-dialog' closeAfterTransition={false}>
      <DialogTitle>Sauerstoff hinzufügen</DialogTitle>
      <DialogContent>
        {isConfirmed ? (
          <div className='add-oxygen-dialog-confirmed'>
            <CheckCircleOutline className='check-circle' />
          </div>
        ) : (
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                fullWidth
                name='oxygenTimestamp'
                label='Zeitpunkt'
                format='HH:mm'
                value={oxygenTimestamp}
                onChange={onChangeOxygenTime}
              />
            </LocalizationProvider>
            <DebouncedTextField
              fullWidth
              type='number'
              label='Sauerstoffgabe [l/m]'
              variant='outlined'
              value={oxygenValue?.toString() ?? ''}
              onChange={onOxygenValueChanged}
              inputProps={{ step: 0.1, min: 0.1 }}
            />
          </div>
        )}
      </DialogContent>
      {!isConfirmed && (
        <DialogActions>
          {!isAddable() && oxygenValue && oxygenTimestamp?.isValid() && (
            <Tooltip
              arrow
              title='Für den gewünschten Zeitpunkt ist bereits ein Eintrag vorhanden. Bitte passen Sie den Zeitpunkt an oder löschen Sie den bereits vorhanden Eintrag.'
            >
              <WarningIcon color='warning' />
            </Tooltip>
          )}
          {!oxygenTimestamp?.isValid() && (
            <Tooltip arrow title='Bitte geben Sie einen Zeitpunkt an.'>
              <WarningIcon color='warning' />
            </Tooltip>
          )}
          <Button className='cancel-button' variant='outlined' onClick={handleClose}>
            Abbrechen
          </Button>
          <Button onClick={handleAdd} autoFocus disabled={!isAddable()} variant='contained'>
            Hinzufügen
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
