import { useContext, useState } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId, getCardTitle } from '../../../../models/diviCard';
import { Button, IconButton, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './TrendsHistory.scss?inline';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import DeleteIcon from '../../../../assets/images/icons/delete.svg?react';
import UploadIcon from '../../../../assets/images/icons/upload.svg?react';
import { getFormattedTime, sortByHHmm } from '../../../../utils/util';
import CellTemplate, { ExtendedTrendEntry } from './TrendsTable/CellTemplate';
import HeaderCellTemplate from './TrendsTable/HeaderCellTemplate';
import checked1 from '../../../../assets/images/icons/checkbox-checked-legend.png';
import checked2 from '../../../../assets/images/icons/always-checked-legend.png';
import unchecked from '../../../../assets/images/icons/checkbox-unchecked-legend.png';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { isTrendType, TrendSelection, TrendsRecord } from '../../../../models/trendsRecord';
import { castDraft, Draft } from 'immer';
import _ from 'lodash';
import { TrendInfoMap } from '../../../../models/trends';
import { AddVitalParamDialog } from './AddVitalParamDialog';
import { defaultRecords } from '../../../../DefaultRecords';
import { Dayjs } from 'dayjs';
import { NodeType } from '../../../../backendModels/report.model';
import { VitalParameterCode } from '../../../../backendModels/trends.model';
import { VitalParameterUnitKeys } from '../../../../models/vitalParameters';

interface TrendsProps {
  nodeType: NodeType;
}

export interface AddVitalParamDialogResponse {
  timestamp: Dayjs;
  puls?: number | null;
  nibpSys?: number | null;
  nibpDia?: number | null;
  spo2?: number | null;
  etco2?: number | null;
}

interface VitalParam {
  type: VitalParameterCode;
  value: number;
}

export default function TrendsHistory({ nodeType }: TrendsProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord, trends } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('trends', nodeType);

  const [addVitalParamDialogOpen, setAddVitalParamDialogOpen] = useState<boolean>(false);

  const reportAPI = useContext(ReportsAPIContext);
  const manualVitalParamNodes = reportAPI.findNodeWithRecord(nodeType, defaultRecords.vitalParameters.type);

  const manualData: TrendInfoMap = new Map();

  manualVitalParamNodes.forEach((node) => {
    const timestamp = getFormattedTime(node.effectiveTimestamp);
    const parameters: { type: VitalParameterCode; value: number }[] = [];

    node.records.forEach((record) => {
      if (record.type === 'vitalParameters' && Array.isArray(record.vitalParameters)) {
        record.vitalParameters.forEach((vitalParam: VitalParam) => {
          parameters.push({
            type: vitalParam.type,
            value: vitalParam.value,
          });
        });
      }
    });
    manualData.set(timestamp!, { parameters });
  });

  function openDialog() {
    setAddVitalParamDialogOpen(true);
  }

  const updateData = () => {
    adaptRecord('trends', nodeType, (draft: Draft<TrendsRecord>, deleteRecord) => {
      const trendsMap = _.groupBy(draft.trends, 'timestamp');

      for (const datasource of trends?.trends ?? []) {
        for (const datapoint of datasource.timeSeries) {
          const timestampHHmm = datapoint.timestamp;
          if (trendsMap[timestampHHmm] == null) {
            trendsMap[timestampHHmm] = [];
          }

          const trendEntry = {
            timestamp: timestampHHmm,
            parameters: datapoint.values
              .filter((oneParameter) => isTrendType(oneParameter.code))
              .map((oneParameter) =>
                castDraft({
                  type: oneParameter.code,
                  value: oneParameter.value,
                  unit: oneParameter.unit,
                  selection: TrendSelection.parameterIsSelected,
                }),
              ),
          };

          // Add the entry to the map if there is no existing entry which matches exactly ignoring selection.
          if (
            !trendsMap[timestampHHmm].some(
              (oneTrend) =>
                trendEntry.parameters.length === oneTrend.parameters.length &&
                _.zip(trendEntry.parameters, oneTrend.parameters).every(([parameter1, parameter2]) =>
                  _.isEqual(_.omit(parameter1, 'selection'), _.omit(parameter2, 'selection')),
                ),
            )
          ) {
            trendsMap[timestampHHmm].push(trendEntry);
          }
        }
      }

      draft.trends = Object.values(trendsMap).flat();

      if (draft.trends.length === 0) {
        deleteRecord();
      }
    });
  };

  function updateParameterChecked(originalIndex: number, trendTypes: VitalParameterCode[], checked: boolean) {
    adaptRecord('trends', nodeType, (draft: Draft<TrendsRecord>) => {
      const trendParameters = draft.trends[originalIndex]?.parameters?.filter((oneParameter) =>
        trendTypes.includes(oneParameter.type),
      );
      if (trendParameters != null) {
        for (const oneParameter of trendParameters) {
          oneParameter.selection = checked ? TrendSelection.parameterIsSelected : TrendSelection.valueNotAvailable;
        }
      } else {
        console.error('Vital Parameter was removed while it was interacted with.');
      }
    });
  }

  function checkForDuplicates(dialogResponse: AddVitalParamDialogResponse) {
    //check for duplicates
    const duplicates = manualVitalParamNodes.filter(
      (node) => node.effectiveTimestamp === dialogResponse.timestamp.valueOf(),
    );
    if (duplicates.length == 0) addManualTrendValue(dialogResponse);
  }

  function addManualTrendValue(dialogResponse: AddVitalParamDialogResponse) {
    const timestamp = dialogResponse.timestamp.valueOf();
    const puls = dialogResponse.puls;
    const nibpSys = dialogResponse.nibpSys;
    const nibpDia = dialogResponse.nibpDia;
    const spo2 = dialogResponse.spo2;
    const etco2 = dialogResponse.etco2;

    const trendParameters: { type: VitalParameterCode; value: number; unit: VitalParameterUnitKeys }[] = [];

    if (puls) {
      trendParameters.push({
        type: 'HR',
        value: puls,
        unit: VitalParameterUnitKeys.perMin,
      });
    }
    if (nibpSys) {
      trendParameters.push({
        type: 'NIBP_SYS',
        value: nibpSys,
        unit: VitalParameterUnitKeys.millimeterOfMercury,
      });
    }
    if (nibpDia) {
      trendParameters.push({
        type: 'NIBP_DIA',
        value: nibpDia,
        unit: VitalParameterUnitKeys.millimeterOfMercury,
      });
    }
    if (spo2) {
      trendParameters.push({
        type: 'SPO2',
        value: spo2,
        unit: VitalParameterUnitKeys.percent,
      });
    }
    if (etco2) {
      trendParameters.push({
        type: 'CO2',
        value: etco2,
        unit: VitalParameterUnitKeys.millimeterOfMercury,
      });
    }

    reportAPI.createNodeWithRecord(
      {
        type: defaultRecords.vitalParameters.type,
        vitalParameters: trendParameters,
      },
      nodeType,
      timestamp,
    );
  }

  const sortedTrends = sortByHHmm(
    _.concat<ExtendedTrendEntry>(
      record.trends.map((trend, originalIndex) => ({
        ...trend,
        timestamp: getFormattedTime(trend.timestamp),
        originalIndex,
        isManuallyAdded: false,
      })),
      [...manualData.entries()].map(([timestamp, trend]) => ({ ...trend, timestamp, isManuallyAdded: true })),
    ),
    'timestamp',
  );

  function deleteManualEntries(timestamp: string) {
    const node = manualVitalParamNodes.find((node) => getFormattedTime(node.effectiveTimestamp) === timestamp);
    if (node) reportAPI.deleteNode(node.id);
  }

  const titleContent = (
    <div className='card-title-content'>
      <div>
        <Typography align='left' variant='h2'>
          {getCardTitle(CardId.Trends)}
        </Typography>
        <div className='legend row'>
          <div className='row'>
            <img src={checked1} alt={'checked legend'} /> <img src={checked2} alt={'checked legend'} />
            <Typography variant='subtitle1'>Wird auf dem Protokoll gedruckt</Typography>
          </div>
          <div className='row'>
            <img src={unchecked} alt={'unchecked legend'} />{' '}
            <Typography variant='subtitle1'>Nur online einsehbar</Typography>
          </div>
        </div>
      </div>
      {sortedTrends.length !== 0 && (
        <Button onClick={updateData} variant='outlined' color='inherit' endIcon={<SyncIcon />}>
          Daten aktualisieren
        </Button>
      )}
    </div>
  );

  return (
    <DiviFieldCard cardType={CardId.Trends} customTitleContent={titleContent}>
      <AddVitalParamDialog
        isDialogOpen={addVitalParamDialogOpen}
        onOpenChanged={setAddVitalParamDialogOpen}
        onConfirm={checkForDuplicates}
        manualData={manualData}
      />
      <div className='trends-buttons'>
        <Button onClick={openDialog} variant='outlined' endIcon={<AddCircleIcon />}>
          Werte manuell hinzufügen
        </Button>
      </div>
      <Table className={'trends-table'}>
        <TableHead>
          <TableRow>
            <TableCell>Zeitpunkt</TableCell>
            <HeaderCellTemplate title='Puls' unit='1/min' />
            <HeaderCellTemplate title='NIBP' unit='mmHg' />
            <HeaderCellTemplate
              title={
                <>
                  SpO<sub>2</sub>
                </>
              }
              unit='%'
            />
            <HeaderCellTemplate
              title={
                <>
                  etCO<sub>2</sub>
                </>
              }
              unit='mmHg'
            />
            <TableCell align='right'>Löschen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTrends.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} align='center'>
                <Button
                  className='import-button'
                  onClick={updateData}
                  variant='contained'
                  color='primary'
                  endIcon={<UploadIcon />}
                >
                  Daten importieren
                </Button>
              </TableCell>
            </TableRow>
          ) : (
            sortedTrends.map((trendInfo, index) => (
              <TableRow key={index}>
                <TableCell>{trendInfo.timestamp}</TableCell>
                <CellTemplate
                  trendInfo={trendInfo}
                  manualTrendInfo={manualData.get(trendInfo.timestamp)}
                  trendTypes={['HR']}
                  updateChecked={updateParameterChecked}
                />
                <CellTemplate
                  trendInfo={trendInfo}
                  manualTrendInfo={manualData.get(trendInfo.timestamp)}
                  trendTypes={['NIBP_SYS', 'NIBP_DIA']}
                  updateChecked={updateParameterChecked}
                />
                <CellTemplate
                  trendInfo={trendInfo}
                  manualTrendInfo={manualData.get(trendInfo.timestamp)}
                  trendTypes={['SPO2']}
                  updateChecked={updateParameterChecked}
                />
                <CellTemplate
                  trendInfo={trendInfo}
                  manualTrendInfo={manualData.get(trendInfo.timestamp)}
                  trendTypes={['CO2']}
                  updateChecked={updateParameterChecked}
                />
                <TableCell align='right'>
                  {trendInfo.isManuallyAdded && (
                    <IconButton aria-label='delete' onClick={() => deleteManualEntries(trendInfo.timestamp)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </DiviFieldCard>
  );
}
