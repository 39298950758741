import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import { NodeType } from '../../../backendModels/report.model';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { useCSS } from '../../../provider/CSSProvider';
import style from './SAMPLERPlusS.scss?inline';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import GeneralCondition from '../Anamnese/GeneralCondition/GeneralCondition';
import FirstAider from '../Anamnese/FirstAider/FirstAider';
import FirstResponder from '../Anamnese/FirstResponder/FirstResponder';
import Multiresistant from '../Anamnese/Multiresistant/Multiresistant';
import TreatmentSituation from '../Anamnese/TreatmentSituation/TreatmentSituation';
import Anticoagulants from '../Anamnese/Anticoagulants/Anticoagulants';
import SAMPLERPlusSNote from './SAMPLER+SNote/SAMPLERPlusSNote';
import { SAMPLER_PLUS_S_RECORD_ELEMENT_KEY } from '../../../models/genericElements/samplerPlusS';
import { FIRST_RESPONDER_RECORD_ELEMENT_KEY } from '../../../models/genericElements/firstResponder';

export function SAMPLERPlusS() {
  useCSS(style);

  const recordTypesOfPage = ['situationAndSymptoms', 'patientMedication'] as (keyof BaseNodeRecordValueTypeMap)[];
  const elementKeysOfPage = [SAMPLER_PLUS_S_RECORD_ELEMENT_KEY, FIRST_RESPONDER_RECORD_ELEMENT_KEY];
  const nodeTypeOfPage = NodeType.INITIAL_SURVEY;

  const pageHeader = <PageHeader heading={getPageTitle(PageType.SAMPLERPlusS)} />;

  const pageFooter = (
    <PageFooter
      pageType={PageType.SAMPLERPlusS}
      diviId={DiviNavigationIds.Anamnese}
      nodeType={nodeTypeOfPage}
      recordTypes={recordTypesOfPage}
      elementKeys={elementKeysOfPage}
      noDoneButton
      backInHistoryOnBack
      noNextButton
    />
  );

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      elementKeys={elementKeysOfPage}
      nodeType={nodeTypeOfPage}
      diviId={DiviNavigationIds.Anamnese}
    >
      <div className='wireframe-container-sampler-plus-s'>
        <div className='sampler-plus-s'>
          <SAMPLERPlusSNote nodeType={nodeTypeOfPage} />
        </div>
        <GeneralCondition nodeType={nodeTypeOfPage} />
        <TreatmentSituation nodeType={nodeTypeOfPage} />
        <Anticoagulants nodeType={nodeTypeOfPage} />
        <FirstAider nodeType={nodeTypeOfPage} />
        <FirstResponder nodeType={nodeTypeOfPage} />
        <Multiresistant nodeType={nodeTypeOfPage} />
      </div>
    </DiviPage>
  );
}
