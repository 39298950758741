import { useContext } from 'react';
import DiviFieldCard from '../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../models/diviCard';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import CheckboxList from '../../customs/checkboxList/CheckboxList';
import {
  getSpecificsTuplesFor,
  SPECIFICS_RECORD_ELEMENT_KEY,
  SpecificsKeys,
  SpecificsRecord,
} from '../../../models/genericElements/specifics';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../backendModels/report.model';
import { Draft } from 'immer';
import { InputState } from '../../../backendModels/general.model';
import RadioList from '../../customs/radioList/RadioList';
import { isGenericRecordDeletable } from '../../../models/generic';
import _ from 'lodash';

const situationItems: [SpecificsKeys, string][] = getSpecificsTuplesFor([
  SpecificsKeys.besonderheitensituationhygiene,
  SpecificsKeys.besonderheitensituationpatzugang,
  SpecificsKeys.besonderheitensituationtechrettung,
  SpecificsKeys.besonderheitensituationschwertransport,
]);

const majorLossItems: [SpecificsKeys, string][] = getSpecificsTuplesFor([
  SpecificsKeys.besonderheitengrossschadenslagelna,
  SpecificsKeys.besonderheitengrossschadenslagemanv,
  SpecificsKeys.besonderheitengrossschadenslagemehrerepatienten,
]);

const handoverItems: [SpecificsKeys, string][] = getSpecificsTuplesFor([
  SpecificsKeys.besonderheitenuebernahmevonarzt,
  SpecificsKeys.besonderheitenuebergabeanarzt,
]);

const transportItems: [SpecificsKeys, string][] = getSpecificsTuplesFor([
  SpecificsKeys.besonderheitentransportmarzt,
  SpecificsKeys.besonderheitentransportmrth,
  SpecificsKeys.besonderheitentransportmsignal,
  SpecificsKeys.besonderheitentransportkliniknimmtnichtauf,
  SpecificsKeys.besonderheitentransportzwangsbelegung,
  SpecificsKeys.besonderheitentransportzwangsunterbringung,
]);

const treatmentItems: [SpecificsKeys, string][] = getSpecificsTuplesFor([
  SpecificsKeys.besonderheitennurbehandlungvereigerung,
  SpecificsKeys.besonderheitennurbehandlungpatnichttransportfaehig,
  SpecificsKeys.besonderheitennurbehandlungtherapieverzicht,
]);

interface SpecificsProps {
  nodeType: NodeType;
}
export default function Specifics({ nodeType }: SpecificsProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, SPECIFICS_RECORD_ELEMENT_KEY) as SpecificsRecord;

  const transportValue =
    record.inputState === InputState.ENTERED ? (record.values.besonderheitenTransport?.options ?? []) : [];
  const treatmentValue =
    record.inputState === InputState.ENTERED ? (record.values.besonderheitenNurBehandlung?.option ?? null) : null;
  const majorLossValue =
    record.inputState === InputState.ENTERED ? (record.values.besonderheitenGrossschadenslage?.options ?? []) : [];
  const situationValue =
    record.inputState === InputState.ENTERED ? (record.values.besonderheitenSituation?.options ?? []) : [];

  const changeHandover = (event: React.ChangeEvent<HTMLInputElement>) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<SpecificsRecord>) => {
        draft.inputState = InputState.ENTERED;
        draft.values = {
          ...draft.values,
          besonderheitenUebergabe: {
            fieldType: 'singleSelect',
            option: event.target.value as SpecificsKeys,
          },
        };
      },
      SPECIFICS_RECORD_ELEMENT_KEY,
    );
  };

  const changeTransport = (values: SpecificsKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<SpecificsRecord>) => {
        draft.inputState = InputState.ENTERED;
        delete draft.values?.besonderheitenNurBehandlung;
        draft.values = {
          ...draft.values,
          besonderheitenTransport: {
            fieldType: 'multiSelect',
            options: _.union(values, [SpecificsKeys.besonderheitentransportinkh]),
          },
        };
      },
      SPECIFICS_RECORD_ELEMENT_KEY,
    );
  };

  const changeTreatment = (event: React.ChangeEvent<HTMLInputElement>) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<SpecificsRecord>) => {
        delete draft.values?.besonderheitenTransport;
        draft.inputState = InputState.ENTERED;
        draft.values = {
          ...draft.values,
          besonderheitenNurBehandlung: {
            fieldType: 'singleSelect',
            option: event.target.value as SpecificsKeys,
          },
        };
      },
      SPECIFICS_RECORD_ELEMENT_KEY,
    );
  };

  const handleSituationCheckbox = (values: SpecificsKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<SpecificsRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (values.length == 0) {
          delete draft.values?.besonderheitenSituation;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        } else {
          draft.values = {
            ...draft.values,
            besonderheitenSituation: {
              fieldType: 'multiSelect',
              options: values,
            },
          };
        }
      },
      SPECIFICS_RECORD_ELEMENT_KEY,
    );
  };

  const handleGrossschadenCheckbox = (values: SpecificsKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<SpecificsRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (values.length == 0) {
          delete draft.values?.besonderheitenGrossschadenslage;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        } else {
          draft.values = {
            ...draft.values,
            besonderheitenGrossschadenslage: {
              fieldType: 'multiSelect',
              options: values,
            },
          };
        }
      },
      SPECIFICS_RECORD_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard cardType={CardId.Specifics}>
      <div className='row two-row symptoms-list'>
        <div>
          <FormControl>
            <RadioGroup
              onChange={changeHandover}
              value={
                record.inputState === InputState.ENTERED
                  ? (record.values.besonderheitenUebergabe?.option ?? null)
                  : null
              }
            >
              {handoverItems.map(([value, label]) => (
                <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
              ))}
            </RadioGroup>
          </FormControl>
          <FormControl className='add-margin-top'>
            <RadioGroup>
              <FormControlLabel
                key='BESONDERHEITEN_TRANSPORT'
                value={[SpecificsKeys.besonderheitentransportinkh]}
                control={<Radio onChange={() => changeTransport([])} />}
                label='Transport ins Krankenhaus'
                checked={transportValue.includes(SpecificsKeys.besonderheitentransportinkh)}
              />
              <CheckboxList
                className='indented'
                items={transportItems}
                selectedValues={transportValue}
                onValuesChange={changeTransport}
              ></CheckboxList>
              <FormControlLabel
                key='BESONDERHEITEN_NUR_BEHANDLUNG'
                value={SpecificsKeys.besonderheitennurbehandlung}
                control={<Radio onChange={changeTreatment} />}
                label='nur Untersuchung und Behandlung'
                checked={treatmentValue != null}
              />
              <RadioList
                className='indented'
                items={treatmentItems}
                value={treatmentValue}
                onChange={changeTreatment}
              ></RadioList>
            </RadioGroup>
          </FormControl>
        </div>
        <div>
          <CheckboxList
            items={situationItems}
            selectedValues={situationValue}
            onValuesChange={handleSituationCheckbox}
          ></CheckboxList>
          <CheckboxList
            items={majorLossItems}
            selectedValues={majorLossValue}
            onValuesChange={handleGrossschadenCheckbox}
          ></CheckboxList>
        </div>
      </div>
    </DiviFieldCard>
  );
}
