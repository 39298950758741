import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { TextFieldValue } from '../../../backendModels/generic-element.model';
import { MANUAL_EVENT_RECORD_ELEMENT_KEY, ManualEventRecord } from './manualEvent';

export const END_EVENT_RECORD_ELEMENT_KEY = 'diviEndEvent';

export type EndEventRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof END_EVENT_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          endTimestamp?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export function endEventsToVirtualManualEvents(records: EndEventRecord[]) {
  const resultingEvents: ManualEventRecord[] = [];

  for (const record of records) {
    if (record.inputState === InputState.ENTERED && record.values.endTimestamp?.text != null) {
      resultingEvents.push({
        type: 'generic',
        elementKey: MANUAL_EVENT_RECORD_ELEMENT_KEY,
        inputState: InputState.ENTERED,
        values: {
          eventName: {
            fieldType: 'text',
            text: 'Einsatzende',
          },
          eventTimestamp: {
            fieldType: 'text',
            text: record.values.endTimestamp.text,
          },
        },
      });
    }
  }

  return resultingEvents;
}
