import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import SmileyRating from '../../../customs/smileyRating/SmileyRating';
import Divider from '@mui/material/Divider';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './InjuriesCategory.scss?inline';
import { NodeType } from '../../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import {
  getInjuriesConnectionTuplesFor,
  INJURIES_CONNECTION_RECORD_ELEMENT_KEY,
  InjuriesConnectionKeys,
  InjuriesConnectionRecord,
} from '../../../../models/genericElements/injuries/injuriesConnection';

import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import { isGenericRecordDeletable, setGenericRecordToNormal } from '../../../../models/generic';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import {
  getInjuriesCategoryTuplesFor,
  INJURIES_CATEGORY_RECORD_ELEMENT_KEY,
  InjuriesCategoryKeys,
  InjuriesCategoryRecord,
  injuryLocationLabels,
} from '../../../../models/genericElements/injuries/InjuriesCategory';
import { defaultRecords } from '../../../../DefaultRecords';
import { InjuriesBodyLocation, Severity } from '../../../../backendModels/injuries.model';
import { useFreshCallback } from '../../../../utils/hooks';

const polytraumaItem = getInjuriesConnectionTuplesFor([InjuriesConnectionKeys.verletzungzusammenhangpolytrauma]);

const headBackItems = getInjuriesCategoryTuplesFor([
  InjuriesCategoryKeys.headbackgeschlossen,
  InjuriesCategoryKeys.headbackoffen,
]);
const headItems = getInjuriesCategoryTuplesFor([InjuriesCategoryKeys.headgeschlossen, InjuriesCategoryKeys.headoffen]);
const neckItems = getInjuriesCategoryTuplesFor([InjuriesCategoryKeys.neckgeschlossen, InjuriesCategoryKeys.neckoffen]);
const thoraxItems = getInjuriesCategoryTuplesFor([
  InjuriesCategoryKeys.thoraxgeschlossen,
  InjuriesCategoryKeys.thoraxoffen,
]);
const abdomenItems = getInjuriesCategoryTuplesFor([
  InjuriesCategoryKeys.abdomengeschlossen,
  InjuriesCategoryKeys.abdomenoffen,
]);
const cervicalSpineItems = getInjuriesCategoryTuplesFor([
  InjuriesCategoryKeys.cervicalspinegeschlossen,
  InjuriesCategoryKeys.cervicalspineoffen,
]);
const sacrumCoccyxItems = getInjuriesCategoryTuplesFor([
  InjuriesCategoryKeys.sacrumcoccyxgeschlossen,
  InjuriesCategoryKeys.sacrumcoccyxoffen,
]);
const shoulderItems = getInjuriesCategoryTuplesFor([
  InjuriesCategoryKeys.shouldergeschlossen,
  InjuriesCategoryKeys.shoulderoffen,
]);
const femurItems = getInjuriesCategoryTuplesFor([
  InjuriesCategoryKeys.femurgeschlossen,
  InjuriesCategoryKeys.femuroffen,
]);
const genitalsItems = getInjuriesCategoryTuplesFor([
  InjuriesCategoryKeys.genitalsgeschlossen,
  InjuriesCategoryKeys.genitalsoffen,
]);

interface InjuryLocationProps {
  nodeType: NodeType;
}

export default function InjuriesCategory({ nodeType }: InjuryLocationProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const recordConnection = findRecordOrDefault(
    'generic',
    nodeType,
    INJURIES_CONNECTION_RECORD_ELEMENT_KEY,
  ) as InjuriesConnectionRecord;
  const recordCategory = findRecordOrDefault(
    'generic',
    nodeType,
    INJURIES_CATEGORY_RECORD_ELEMENT_KEY,
  ) as InjuriesCategoryRecord;

  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.injuries.type, nodeType);

  const headBackValueState = recordCategory.values?.headBackOffenGeschlossen?.option ?? null;
  const headValueState = recordCategory.values?.headOffenGeschlossen?.option ?? null;
  const neckValueState = recordCategory.values?.neckOffenGeschlossen?.option ?? null;
  const thoraxValueState = recordCategory.values?.thoraxOffenGeschlossen?.option ?? null;
  const abdomenValueState = recordCategory.values?.abdomenOffenGeschlossen?.option ?? null;
  const cervicalSpineValueState = recordCategory.values?.cervicalSpineOffenGeschlossen?.option ?? null;
  const sacrumCoccyxValueState = recordCategory.values?.sacrumCoccyxOffenGeschlossen?.option ?? null;
  const shoulderValueState = recordCategory.values?.shoulderOffenGeschlossen?.option ?? null;
  const femurValueState = recordCategory.values?.femurOffenGeschlossen?.option ?? null;
  const genitalsValueState = recordCategory.values?.genitalsOffenGeschlossen?.option ?? null;

  function getSevertiy(location: string[]) {
    const index =
      record.inputState === InputState.ENTERED
        ? record.injuries.findIndex((item) => location.includes(item.location))
        : -1;
    return record.inputState === InputState.ENTERED && index !== -1 ? record.injuries[index].severity : null;
  }

  const tableData = [
    {
      fieldKey: 'headBackOffenGeschlossen',
      radioItems: headBackItems,
      radioValue: headBackValueState,
      locationKeys: ['HEAD_BACK'],
    },
    {
      fieldKey: 'headOffenGeschlossen',
      radioItems: headItems,
      radioValue: headValueState,
      locationKeys: ['HEAD'],
    },
    {
      fieldKey: 'neckOffenGeschlossen',
      radioItems: neckItems,
      radioValue: neckValueState,
      locationKeys: ['NECK'],
    },
    {
      fieldKey: 'thoraxOffenGeschlossen',
      radioItems: thoraxItems,
      radioValue: thoraxValueState,
      locationKeys: ['THORAX_RIGHT', 'THORAX_LEFT', 'THORAX_LEFT_BACK', 'THORAX_RIGHT_BACK'],
    },
    {
      fieldKey: 'abdomenOffenGeschlossen',
      radioItems: abdomenItems,
      radioValue: abdomenValueState,
      locationKeys: [
        'UPPER_QUADRANT_RIGHT',
        'UPPER_QUADRANT_LEFT',
        'LOWER_QUADRANT_RIGHT',
        'LOWER_QUADRANT_LEFT',
        'ABDOMEN_LEFT_BACK',
        'ABDOMEN_RIGHT_BACK',
      ],
    },
    {
      fieldKey: 'cervicalSpineOffenGeschlossen',
      radioItems: cervicalSpineItems,
      radioValue: cervicalSpineValueState,
      locationKeys: ['CERVICAL_SPINE', 'THORACIC_SPINE', 'LUMBAR_SPINE'],
    },
    {
      fieldKey: 'sacrumCoccyxOffenGeschlossen',
      radioItems: sacrumCoccyxItems,
      radioValue: sacrumCoccyxValueState,
      locationKeys: ['SACRUM_COCCYX', 'PELVIS_RIGHT', 'PELVIS_LEFT'],
    },
    {
      fieldKey: 'shoulderOffenGeschlossen',
      radioItems: shoulderItems,
      radioValue: shoulderValueState,
      locationKeys: [
        'SHOULDER_RIGHT',
        'SHOULDER_LEFT',
        'SHOULDER_LEFT_BACK',
        'SHOULDER_RIGHT_BACK',
        'HUMERUS_RIGHT',
        'HUMERUS_LEFT',
        'HUMERUS_LEFT_BACK',
        'HUMERUS_RIGHT_BACK',
        'ELBOW_RIGHT',
        'ELBOW_LEFT',
        'ELBOW_LEFT_BACK',
        'ELBOW_RIGHT_BACK',
        'FOREARM_RIGHT',
        'FOREARM_LEFT',
        'FOREARM_LEFT_BACK',
        'FOREARM_RIGHT_BACK',
        'WRIST_RIGHT',
        'WRIST_LEFT',
        'WRIST_LEFT_BACK',
        'WRIST_RIGHT_BACK',
        'HAND_RIGHT',
        'HAND_LEFT',
        'HAND_LEFT_BACK',
        'HAND_RIGHT_BACK',
      ],
    },
    {
      fieldKey: 'femurOffenGeschlossen',
      radioItems: femurItems,
      radioValue: femurValueState,
      locationKeys: [
        'FEMUR_RIGHT',
        'FEMUR_LEFT',
        'FEMUR_LEFT_BACK',
        'FEMUR_RIGHT_BACK',
        'KNEE_RIGHT',
        'KNEE_LEFT',
        'KNEE_LEFT_BACK',
        'KNEE_RIGHT_BACK',
        'TIBIA_RIGHT',
        'TIBIA_LEFT',
        'TIBIA_LEFT_BACK',
        'TIBIA_RIGHT_BACK',
        'ANKLE_RIGHT',
        'ANKLE_LEFT',
        'ANKLE_LEFT_BACK',
        'ANKLE_RIGHT_BACK',
        'FOOT_RIGHT',
        'FOOT_LEFT',
        'FOOT_LEFT_BACK',
        'FOOT_RIGHT_BACK',
      ],
    },
    {
      fieldKey: 'genitalsOffenGeschlossen',
      radioItems: genitalsItems,
      radioValue: genitalsValueState,
      locationKeys: ['GENITALS'],
    },
  ];

  function changeSeverity(value: Severity, index: number) {
    reportAPI.adaptRecord(defaultRecords.injuries.type, nodeType, (draft) => {
      const recordToSend =
        draft.inputState === InputState.ENTERED
          ? draft.injuries.filter((item) => !tableData[index].locationKeys.includes(item.location))
          : [];
      tableData[index].locationKeys.map((key) => {
        recordToSend.push({
          severity: value,
          location: key as InjuriesBodyLocation,
        });
      });
      return {
        ...draft,
        injuries: recordToSend,
        inputState: InputState.ENTERED,
      };
    });
  }

  function setOpenClosedRadios(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<InjuriesCategoryRecord>) => {
        draft.inputState = InputState.ENTERED;
        draft.values = {
          ...draft.values,
          [tableData[index].fieldKey]: {
            fieldType: 'singleSelect',
            option: event.target.value as InjuriesCategoryKeys,
          },
        };
      },
      INJURIES_CATEGORY_RECORD_ELEMENT_KEY,
    );
  }

  function changePolytrauma(value: InjuriesConnectionKeys[]) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<InjuriesConnectionRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value.length !== 0) {
          draft.values = {
            ...draft.values,
            verletzungZusammenhangPolytrauma: {
              fieldType: 'multiSelect',
              options: value,
            },
          };
        } else {
          delete draft.values?.verletzungZusammenhangPolytrauma;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      INJURIES_CONNECTION_RECORD_ELEMENT_KEY,
    );
  }

  const setToNormal = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.injuries.type, nodeType, (draft) => ({
      id: draft.id,
      type: draft.type,
      inputState: InputState.NORMAL,
    }));
    setGenericRecordToNormal(adaptRecord, nodeType, INJURIES_CATEGORY_RECORD_ELEMENT_KEY);
  });

  return (
    <DiviFieldCard
      cardType={CardId.InjuryCategory}
      onNormalStateButtonClicked={setToNormal}
      normalStateButtonVisible={false}
    >
      <div className='row full-width'>
        <div className='table symptoms-list'>
          <div className='table-header'>
            <div className='col-location' />
            <div className='col-severity table-injury-serverity-header'>
              <Typography textAlign='center' variant='subtitle1'>
                Leicht
              </Typography>
              <Typography textAlign='center' variant='subtitle1'>
                Mittel
              </Typography>
              <Typography textAlign='center' variant='subtitle1'>
                Schwer
              </Typography>
            </div>
            <div className='col-state'>
              <Typography textAlign='center' variant='subtitle1'>
                geschlossen
              </Typography>
              <Typography textAlign='center' variant='subtitle1'>
                offen
              </Typography>
            </div>
          </div>
          <div className='table-body'>
            {injuryLocationLabels.map((label, index) => (
              <div key={label} className='row'>
                <div className='col-location'>
                  <Typography variant='subtitle2'>{label}</Typography>
                </div>
                <div className='col-severity'>
                  <SmileyRating
                    onChange={(value: Severity) => changeSeverity(value, index)}
                    value={getSevertiy(tableData[index].locationKeys)}
                  ></SmileyRating>
                </div>
                <div className='col-state'>
                  <RadioGroup
                    row
                    onChange={(event) => setOpenClosedRadios(event, index)}
                    value={tableData[index].radioValue}
                  >
                    <FormControlLabel value={tableData[index].radioItems[0][0]} control={<Radio />} label='' />
                    <FormControlLabel value={tableData[index].radioItems[1][0]} control={<Radio />} label='' />
                  </RadioGroup>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Divider orientation='vertical' flexItem />
        <div className='col-polytrauma'>
          <CheckboxList
            className='polytrauma'
            items={polytraumaItem}
            selectedValues={
              recordConnection.inputState === InputState.ENTERED
                ? (recordConnection.values?.verletzungZusammenhangPolytrauma?.options ?? [])
                : []
            }
            onValuesChange={changePolytrauma}
          />
        </div>
      </div>
    </DiviFieldCard>
  );
}
