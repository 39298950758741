import { useContext, useEffect } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { useFreshCallback } from '../../../../utils/hooks';
import { NodeType } from '../../../../backendModels/report.model';
import {
  CONTROL_CENTER_RECORD_ELEMENT_KEY,
  ControlCenterPropertyKeys,
  ControlCenterRecord,
} from '../../../../models/genericElements/controlCenter';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import { isGenericRecordDeletable } from '../../../../models/generic';
import {
  changePropertyAndMarkTouched,
  getTouchedClassName,
  handleUntouchedTextFieldUpdates,
} from '../../../../utils/recordWithTouched';
import {
  PATIENT_DATA_RECORD_ELEMENT_KEY,
  PatientDataRecord,
  PatientPropertyKeys,
} from '../../../../models/genericElements/patientData';

export interface MissionProps {
  nodeType: NodeType;
}

export default function Mission({ nodeType }: MissionProps) {
  const { findRecordOrDefault, findNodes, adaptRecord, missionData } = useContext(ReportsAPIContext);

  const patientRecord = findRecordOrDefault('generic', nodeType, PATIENT_DATA_RECORD_ELEMENT_KEY) as PatientDataRecord;
  const record = findRecordOrDefault('generic', nodeType, CONTROL_CENTER_RECORD_ELEMENT_KEY) as ControlCenterRecord;

  useEffect(() => {
    if (missionData?.controlCenter != null && findNodes(nodeType).length > 0) {
      adaptRecord(
        'generic',
        nodeType,
        (draft: Draft<ControlCenterRecord>, deleteRecord) => {
          draft.inputState = InputState.ENTERED;

          // PatientId is done in PatientMasterData.tsx
          handleUntouchedTextFieldUpdates(
            draft,
            ControlCenterPropertyKeys.location,
            missionData.controlCenter?.location,
          );
          handleUntouchedTextFieldUpdates(
            draft,
            ControlCenterPropertyKeys.personnelNumber,
            missionData?.controlCenter?.personnelNumber,
          );
          handleUntouchedTextFieldUpdates(
            draft,
            ControlCenterPropertyKeys.controlCenter,
            missionData.controlCenter?.controlCenter,
          );

          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        },
        CONTROL_CENTER_RECORD_ELEMENT_KEY,
      );
    }
  }, [missionData, adaptRecord, findNodes, nodeType]);

  const handleChangedControlCenterProperty = useFreshCallback((property, value) => {
    changePropertyAndMarkTouched(adaptRecord, nodeType, CONTROL_CENTER_RECORD_ELEMENT_KEY, property, value);
  });

  return (
    <DiviFieldCard cardType={CardId.Mission}>
      <div className='row'>
        <DebouncedTextField
          className={getTouchedClassName(patientRecord, PatientPropertyKeys.patientId)}
          label='Patienten Nr.'
          value={patientRecord.values?.patientId?.text ?? ''}
          onDebounceChange={(value) =>
            changePropertyAndMarkTouched(
              adaptRecord,
              NodeType.METADATA,
              PATIENT_DATA_RECORD_ELEMENT_KEY,
              PatientPropertyKeys.patientId,
              value !== null ? { fieldType: 'text', text: value } : undefined,
            )
          }
        />
        <DebouncedTextField
          className={getTouchedClassName(record, ControlCenterPropertyKeys.location)}
          label='Standort'
          value={record.values?.location?.text ?? ''}
          onDebounceChange={(value) =>
            handleChangedControlCenterProperty(
              ControlCenterPropertyKeys.location,
              value !== '' ? { text: value, fieldType: 'text' } : undefined,
            )
          }
        />
        <DebouncedTextField
          className={getTouchedClassName(record, ControlCenterPropertyKeys.personnelNumber)}
          label='Pers. Nr.'
          value={record.values?.personnelNumber?.text ?? ''}
          onDebounceChange={(value) =>
            handleChangedControlCenterProperty(
              ControlCenterPropertyKeys.personnelNumber,
              value !== '' ? { text: value, fieldType: 'text' } : undefined,
            )
          }
        />
        <DebouncedTextField
          className={getTouchedClassName(record, ControlCenterPropertyKeys.controlCenter)}
          label='Leitstelle (KFZ-Kennz.)'
          value={record.values?.controlCenter?.text ?? ''}
          onDebounceChange={(value) =>
            handleChangedControlCenterProperty(
              ControlCenterPropertyKeys.controlCenter,
              value !== '' ? { text: value, fieldType: 'text' } : undefined,
            )
          }
        />
      </div>
    </DiviFieldCard>
  );
}
