import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { InputState } from '../../../../backendModels/general.model';
import { useContext } from 'react';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { PsycheKeys, getPsycheTuplesFor } from '../../../../models/psyche';
import style from './Psyche.scss?inline';
import { useCSS } from '../../../../provider/CSSProvider';
import { CardId } from '../../../../models/diviCard';
import { defaultRecords } from '../../../../DefaultRecords';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../../backendModels/report.model';
import { useFreshCallback } from '../../../../utils/hooks';

interface PsycheProps {
  nodeType: NodeType;
}

const psycheItems = getPsycheTuplesFor([
  PsycheKeys.notExamined,
  PsycheKeys.delusional,
  PsycheKeys.excited,
  PsycheKeys.depressed,
  PsycheKeys.confused,
  PsycheKeys.aggressive,
  PsycheKeys.anxious,
  PsycheKeys.suicidal,
  PsycheKeys.slowedDown,
  PsycheKeys.euphoric,
  PsycheKeys.motoricallyRestless,
]);

export default function Psyche({ nodeType }: PsycheProps) {
  useCSS(style);

  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.psyche.type, nodeType);

  const selectedPsycheItems = record.inputState === InputState.ENTERED ? ((record.symptoms ?? []) as PsycheKeys[]) : [];

  const setToNormal = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.psyche.type, nodeType, (draft) => ({
      id: draft.id,
      type: draft.type,
      inputState: InputState.NORMAL,
    }));
  });

  const setToUnknown = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.psyche.type, nodeType, (draft) => ({
      id: draft.id,
      type: draft.type,
      inputState: InputState.UNKNOWN,
    }));
  });

  const changeSymptoms = (values: PsycheKeys[]) => {
    reportAPI.adaptRecord(defaultRecords.psyche.type, nodeType, (draft, deleteRecord) => {
      if (values.length === 0) {
        deleteRecord();
      } else {
        return { ...draft, inputState: InputState.ENTERED, symptoms: values };
      }
    });
  };

  return (
    <div className='psyche-card'>
      <DiviFieldCard
        cardType={CardId.Psyche}
        recordType={defaultRecords.psyche.type}
        nodeType={nodeType}
        onNormalStateButtonClicked={setToNormal}
        normalStateButtonLabel={'unauffällig'}
        onUnknownStateButtonClicked={setToUnknown}
        unknownStateButtonLabel={'nicht beurteilbar'}
      >
        <CheckboxList
          className='symptoms-list'
          items={psycheItems}
          selectedValues={selectedPsycheItems}
          onValuesChange={changeSymptoms}
        />
      </DiviFieldCard>
    </div>
  );
}
