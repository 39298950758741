import { useCSS } from '../../../provider/CSSProvider';
import style from './PatientRecordFullEntry.scss?inline';
import { IconButton, ListItem, Stack, Typography } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

interface PatientRecordFullEntryProps {
  text: string;
  time: string;
  edit: () => void;
}

export default function PatientRecordFullEntry({ text, time, edit }: PatientRecordFullEntryProps) {
  useCSS(style);

  return (
    <>
      <ListItem className='PatientRecordFullEntry' disablePadding>
        <Stack direction='column' className='text-block'>
          <div className='entry-header'>
            <div className='time'>{time}</div>
            <IconButton onClick={() => edit()}>
              <EditIcon />
            </IconButton>
          </div>
          <Typography fontWeight='fontWeightBold' fontSize='20px'>
            <span className='entry-title'>{text}</span>
          </Typography>
          <span className='list-item-text'>{text}</span>
        </Stack>
      </ListItem>
    </>
  );
}
