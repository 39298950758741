import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { SingleSelectFieldValueTyped } from '../generic';
import { TextFieldValue } from '../../backendModels/generic-element.model';

export enum LyseTherapyKeys {
  lysetherapieestartvorstillstand = 'LYSE_THERAPIEE_START_VOR_STILLSTAND',
  lysetherapiestartwaehrendstillstand = 'LYSE_THERAPIE_START_WAEHREND_STILLSTAND',
  lysetherapiestartnachrosc = 'LYSE_THERAPIE_START_NACH_ROSC',
}

export const LYSE_THERAPY_RECORD_ELEMENT_KEY = 'lyseTherapie';

export type LyseTherapyRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof LYSE_THERAPY_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          lyseTherapieStart?: SingleSelectFieldValueTyped<LyseTherapyKeys>;
          lyseTherapieStartZeit?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export const lyseTherapyLabels: Record<LyseTherapyKeys, string> = {
  [LyseTherapyKeys.lysetherapieestartvorstillstand]: 'vor Kreislaufstillstand',
  [LyseTherapyKeys.lysetherapiestartwaehrendstillstand]: 'während Kreislaufstillstand',
  [LyseTherapyKeys.lysetherapiestartnachrosc]: 'nach ROSC',
};

export function getLyseTherapyTuple(key: LyseTherapyKeys): [LyseTherapyKeys, string] {
  return [key, lyseTherapyLabels[key]];
}

export function getLyseTherapyTuplesFor(keys: LyseTherapyKeys[]): [LyseTherapyKeys, string][] {
  return keys.map((k) => {
    return getLyseTherapyTuple(k);
  });
}
