import { useCallback, useContext } from 'react';
import { NodeType } from '../../../../backendModels/report.model';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import {
  AirwayManagementRecord,
  AirwayTreatmentKeys,
  getAirwayTreatmentTuplesFor,
} from '../../../../models/airwayManagement';
import {
  getIntubationApplicationMethodTuplesFor,
  getIntubationSizeTupelsFor,
  getIntubationTypeTuplesFor,
  IntubationApplicationMethodKeys,
  IntubationDifficultyKeys,
  IntubationRecord,
  IntubationSizeKeys,
  IntubationTypeKeys,
  IntubationVideoKeys,
  isIntubationRecordDeletable,
} from '../../../../models/intubation';
import style from './Airway.scss?inline';
import { useCSS } from '../../../../provider/CSSProvider';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import { OxygenApplicationMethodKeys } from '../../../../models/oxygen';
import _ from 'lodash';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { updateSet } from '../../../../utils/util';
import RadioList from '../../../customs/radioList/RadioList';
import { useFreshCallback } from '../../../../utils/hooks';
import { Draft } from 'immer';
import {
  OXYGEN_MEASSURES_RECORD_ELEMENT_KEY,
  OxygenMeassuresRecord,
} from '../../../../models/genericElements/oxygenMeassures';
import { InputState } from '../../../../backendModels/general.model';
import { isGenericRecordDeletable } from '../../../../models/generic';

export interface AirwayProps {
  nodeType: NodeType;
}

const maskenBeutelKeys = [
  AirwayTreatmentKeys.maskenBeatmungUnterstuetzend,
  AirwayTreatmentKeys.maskenBeatmungKontrolliert,
  AirwayTreatmentKeys.maskenBeatmungNichtMoeglich,
];
const maskenBeutelRadioItems = getAirwayTreatmentTuplesFor(maskenBeutelKeys);

const freeAirwayKeys = [AirwayTreatmentKeys.absaugen, AirwayTreatmentKeys.absaugpumpe];
const freeAirwayCheckboxItems = getAirwayTreatmentTuplesFor(freeAirwayKeys);

const supraglottischeAtemwegshilfeKeys = [
  IntubationTypeKeys.laryngealMask,
  IntubationTypeKeys.laryngealTube,
  IntubationTypeKeys.other,
];
const supraglottischeAtemwegshilfeRadioItems = getIntubationTypeTuplesFor(supraglottischeAtemwegshilfeKeys);

const applicationMethodKeys = [IntubationApplicationMethodKeys.oral, IntubationApplicationMethodKeys.nasal];
const applicationMethodRadioItems = getIntubationApplicationMethodTuplesFor(applicationMethodKeys);
const intubationSizeItems = getIntubationSizeTupelsFor([
  IntubationSizeKeys.size3,
  IntubationSizeKeys.size3_5,
  IntubationSizeKeys.size4,
  IntubationSizeKeys.size4_5,
  IntubationSizeKeys.size5,
  IntubationSizeKeys.size5_5,
  IntubationSizeKeys.size6,
  IntubationSizeKeys.size6_5,
  IntubationSizeKeys.size7,
  IntubationSizeKeys.size7_5,
  IntubationSizeKeys.size8,
  IntubationSizeKeys.size8_5,
  IntubationSizeKeys.size9,
  IntubationSizeKeys.size9_5,
]);

export default function Airway({ nodeType }: AirwayProps) {
  useCSS(style);

  const reportAPI = useContext(ReportsAPIContext);
  const airwayManagementRecord = reportAPI.findRecordOrDefault(defaultRecords.airwayManagement.type, nodeType);
  const oxygenRecord = reportAPI.findRecordOrDefault(
    'generic',
    nodeType,
    OXYGEN_MEASSURES_RECORD_ELEMENT_KEY,
  ) as OxygenMeassuresRecord;

  const supraglotticIntubationRecord = reportAPI.findRecordOrDefault(
    defaultRecords.intubation.type,
    nodeType,
    (record) =>
      record.intubation === IntubationTypeKeys.laryngealMask ||
      record.intubation === IntubationTypeKeys.laryngealTube ||
      record.intubation === IntubationTypeKeys.other,
  );
  const endotrachealIntubationRecord = reportAPI.findRecordOrDefault(
    defaultRecords.intubation.type,
    nodeType,
    (record) => record.intubation === IntubationTypeKeys.endotracheal,
  );

  const setAirwayManagement = useCallback(
    (generateRecord: (draft: Draft<AirwayManagementRecord>) => Draft<AirwayManagementRecord>) => {
      reportAPI.adaptRecord(defaultRecords.airwayManagement.type, nodeType, (draft, deleteRecord) => {
        const newAirwayManagementRecord = generateRecord(draft);
        if (newAirwayManagementRecord.treatments.length > 0) {
          return newAirwayManagementRecord;
        } else {
          deleteRecord();
        }
      });
    },
    [reportAPI, nodeType],
  );

  const setSupraglotticIntubation = useCallback(
    (generateRecord: (draft: Draft<IntubationRecord>) => Draft<IntubationRecord>) => {
      reportAPI.adaptRecord(
        defaultRecords.intubation.type,
        nodeType,
        (draft, deleteRecord) => {
          const newIntubationRecord = generateRecord(draft);
          if (isIntubationRecordDeletable(newIntubationRecord)) {
            deleteRecord();
          } else {
            return newIntubationRecord;
          }
        },
        (record) =>
          record.intubation === IntubationTypeKeys.laryngealMask ||
          record.intubation === IntubationTypeKeys.laryngealTube ||
          record.intubation === IntubationTypeKeys.other,
      );
    },
    [reportAPI, nodeType],
  );

  const setEndotrachealIntubation = useCallback(
    (generateRecord: (draft: Draft<IntubationRecord>) => Draft<IntubationRecord>) => {
      reportAPI.adaptRecord(
        defaultRecords.intubation.type,
        nodeType,
        (draft, deleteRecord) => {
          const newIntubationRecord = generateRecord(draft);
          if (isIntubationRecordDeletable(newIntubationRecord)) {
            deleteRecord();
          } else {
            return newIntubationRecord;
          }
        },
        (record) => record.intubation === IntubationTypeKeys.endotracheal,
      );
    },
    [reportAPI, nodeType],
  );

  const handleOxygenFlowChange = useFreshCallback((value: number | null) => {
    reportAPI.adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<OxygenMeassuresRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value != null) {
          draft.values = {
            ...draft.values,
            oxygenFlow: {
              fieldType: 'numeric',
              number: value,
            },
          };
        } else {
          delete draft.values?.oxygenFlow;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      OXYGEN_MEASSURES_RECORD_ELEMENT_KEY,
    );
  });

  const handleOxygenApplicationMethodChange = useFreshCallback((value: boolean) => {
    reportAPI.adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<OxygenMeassuresRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value) {
          draft.values = {
            ...draft.values,
            preoxygenation: {
              fieldType: 'multiSelect',
              options: [OxygenApplicationMethodKeys.praeoxygenierung],
            },
          };
        } else {
          delete draft.values?.preoxygenation;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      OXYGEN_MEASSURES_RECORD_ELEMENT_KEY,
    );
  });

  return (
    <div className='airway-card'>
      <DiviFieldCard cardType={CardId.Airway}>
        <div className='row two-row symptoms-list'>
          <div>
            <div className='row oxygen-flow-row'>
              <Typography variant='body1'>Sauerstoffgabe</Typography>

              <OutlinedInput
                className='oxygen-flow'
                value={oxygenRecord?.values?.oxygenFlow?.number.toString() ?? ''}
                onChange={(event) => {
                  const value = event.target.value !== '' ? Number(event.target.value) : null;
                  handleOxygenFlowChange(value);
                }}
                endAdornment={
                  <InputAdornment className='airway-card-unit' position='end'>
                    l/m
                  </InputAdornment>
                }
                type='number'
                inputProps={{
                  min: 0,
                  step: 1,
                }}
              />
            </div>
            <div className='symptoms-list indented'>
              <FormControlLabel
                label='als Präoxygenierung'
                checked={
                  oxygenRecord?.values?.preoxygenation?.options.includes(
                    OxygenApplicationMethodKeys.praeoxygenierung,
                  ) ?? false
                }
                control={
                  <Checkbox
                    onChange={(event) => {
                      handleOxygenApplicationMethodChange(event.target.checked);
                    }}
                  />
                }
              />
            </div>

            <FormControlLabel
              label='Freimachen der Atemwege'
              checked={airwayManagementRecord.treatments.includes(AirwayTreatmentKeys.freimachenDerAtemwege)}
              control={
                <Checkbox
                  onChange={(event) => {
                    const checked = event.target.checked;
                    setAirwayManagement((draft) => {
                      let newTreatments = updateSet(
                        draft.treatments,
                        AirwayTreatmentKeys.freimachenDerAtemwege,
                        checked,
                      );
                      if (!checked) {
                        newTreatments = _.difference(newTreatments, freeAirwayKeys);
                      }
                      return { ...draft, treatments: newTreatments };
                    });
                  }}
                />
              }
            />

            <CheckboxList
              className='indented'
              items={freeAirwayCheckboxItems}
              selectedValues={airwayManagementRecord.treatments}
              onValuesChange={(values) => {
                setAirwayManagement((draft) => ({
                  ...draft,
                  treatments: _.union(values, [AirwayTreatmentKeys.freimachenDerAtemwege]),
                }));
              }}
            />

            <FormControlLabel
              label='Masken-/Beutel-Beatmung'
              checked={airwayManagementRecord.treatments.includes(AirwayTreatmentKeys.maskenBeatmung)}
              control={
                <Checkbox
                  onChange={(event) => {
                    const checked = event.target.checked;
                    setAirwayManagement((draft) => {
                      let newTreatments = updateSet(draft.treatments, AirwayTreatmentKeys.maskenBeatmung, checked);
                      if (!checked) {
                        newTreatments = _.difference(newTreatments, maskenBeutelKeys);
                      }
                      return { ...draft, treatments: newTreatments };
                    });
                  }}
                />
              }
            />

            <div className='symptoms-list indented'>
              <RadioList
                items={maskenBeutelRadioItems}
                name='mask-group'
                value={_.intersection(airwayManagementRecord.treatments, maskenBeutelKeys)[0] ?? null}
                onChange={(event) => {
                  setAirwayManagement((draft) => ({
                    ...draft,
                    treatments: [
                      ..._.union(_.difference(draft.treatments, maskenBeutelKeys), [
                        AirwayTreatmentKeys.maskenBeatmung,
                      ]),
                      event.target.value,
                    ],
                  }));
                }}
              />
            </div>
          </div>
          <div>
            <FormControlLabel
              label='supraglottische Atemwegshilfe'
              checked={airwayManagementRecord.treatments.includes(AirwayTreatmentKeys.supraglottischeAtemwegshilfe)}
              control={
                <Checkbox
                  onChange={(event) => {
                    const checked = event.target.checked;
                    setAirwayManagement((draft) => ({
                      ...draft,
                      treatments: updateSet(
                        draft.treatments,
                        AirwayTreatmentKeys.supraglottischeAtemwegshilfe,
                        checked,
                      ),
                    }));
                    if (
                      !checked &&
                      supraglottischeAtemwegshilfeKeys.includes(
                        supraglotticIntubationRecord.intubation as IntubationTypeKeys,
                      )
                    ) {
                      setSupraglotticIntubation((draft) => ({ ...draft, ...defaultRecords.intubation }));
                    }
                  }}
                />
              }
            />

            <div className='symptoms-list indented'>
              <RadioList
                items={supraglottischeAtemwegshilfeRadioItems}
                name='supraglottic-group'
                value={supraglotticIntubationRecord.intubation ?? null}
                onChange={(event) => {
                  setAirwayManagement((draft) => ({
                    ...draft,
                    treatments: _.union(draft.treatments, [AirwayTreatmentKeys.supraglottischeAtemwegshilfe]),
                  }));
                  setSupraglotticIntubation((draft) => ({
                    ...draft,
                    intubation: event.target.value,
                  }));
                }}
              />
            </div>
            <div className='row intubation-row'>
              <FormControlLabel
                label='endotracheale Intubation'
                checked={endotrachealIntubationRecord.intubation === IntubationTypeKeys.endotracheal}
                control={
                  <Checkbox
                    onChange={(event) => {
                      if (event.target.checked) {
                        setEndotrachealIntubation((draft) => ({
                          ...draft,
                          intubation: IntubationTypeKeys.endotracheal,
                        }));
                      } else {
                        setEndotrachealIntubation((draft) => ({
                          ...draft,
                          ...defaultRecords.intubation,
                        }));
                      }
                    }}
                  />
                }
              />
              <FormControl>
                <InputLabel id='intubation-size-label'>mm</InputLabel>
                <Select
                  className='intubation-size'
                  value={endotrachealIntubationRecord.intubationSize ?? ''}
                  labelId='intubation-size-label'
                  label='mm'
                  onChange={(event) => {
                    setEndotrachealIntubation((draft) => ({
                      ...draft,
                      intubation: IntubationTypeKeys.endotracheal,
                      intubationSize: event.target.value ?? undefined,
                    }));
                  }}
                >
                  {intubationSizeItems.map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}&nbsp;<span className='airway-card-unit'>mm</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className='symptoms-list indented'>
              <RadioList
                items={applicationMethodRadioItems}
                name='application-method-group'
                value={endotrachealIntubationRecord.applicationMethod ?? null}
                onChange={(event) => {
                  setEndotrachealIntubation((draft) => ({
                    ...draft,
                    intubation: IntubationTypeKeys.endotracheal,
                    applicationMethod: event.target.value as IntubationApplicationMethodKeys,
                  }));
                }}
              />
              <DebouncedTextField
                className='number-of-attempts'
                type='number'
                label='Anzahl Versuche'
                value={endotrachealIntubationRecord.intubationAttempts?.toString() ?? ''}
                onDebounceChange={useFreshCallback((value) => {
                  if (value !== '') {
                    setAirwayManagement((draft) => ({
                      ...draft,
                      treatments: _.union(draft.treatments),
                    }));
                    setEndotrachealIntubation((draft) => ({
                      ...draft,
                      intubation: IntubationTypeKeys.endotracheal,
                      intubationAttempts: value !== '' ? Number(value) : undefined,
                    }));
                  }
                })}
                inputProps={{
                  min: 1,
                  step: 1,
                }}
              />
              <FormControlLabel
                label='Intubation erschwert'
                checked={
                  endotrachealIntubationRecord.intubationDifficulty === IntubationDifficultyKeys.difficultIntubation
                }
                control={
                  <Checkbox
                    onChange={(event) => {
                      const checked = event.target.checked;
                      setEndotrachealIntubation((draft) => ({
                        ...draft,
                        intubation: IntubationTypeKeys.endotracheal,
                        intubationDifficulty: checked
                          ? IntubationDifficultyKeys.difficultIntubation
                          : IntubationDifficultyKeys.valueNotAvailable,
                      }));
                    }}
                  />
                }
              />
              <FormControlLabel
                className='no-margin-top'
                label='Videolaryngoskop'
                checked={endotrachealIntubationRecord.videoLaryngoscopy === IntubationVideoKeys.withVideoLaryngoscope}
                control={
                  <Checkbox
                    onChange={(event) => {
                      const checked = event.target.checked;
                      setEndotrachealIntubation((draft) => ({
                        ...draft,
                        intubation: IntubationTypeKeys.endotracheal,
                        videoLaryngoscopy: checked
                          ? IntubationVideoKeys.withVideoLaryngoscope
                          : IntubationVideoKeys.valueNotAvailable,
                      }));
                    }}
                  />
                }
              />
            </div>

            <FormControlLabel
              label='Koniotomie / chirurgischer Atemweg'
              checked={airwayManagementRecord.treatments.includes(AirwayTreatmentKeys.koniotomie)}
              control={
                <Checkbox
                  onChange={(event) => {
                    const checked = event.target.checked;
                    setAirwayManagement((draft) => {
                      let newTreatments = updateSet(draft.treatments, AirwayTreatmentKeys.koniotomie, checked);
                      newTreatments = _.difference(newTreatments, [AirwayTreatmentKeys.trachealkanuelenwechsel]);
                      return { ...draft, treatments: newTreatments };
                    });
                  }}
                />
              }
            />
            <div className='symptoms-list indented'>
              <FormControlLabel
                label='Trachealkanülenwechsel'
                checked={airwayManagementRecord.treatments.includes(AirwayTreatmentKeys.trachealkanuelenwechsel)}
                control={
                  <Checkbox
                    onChange={(event) => {
                      const checked = event.target.checked;
                      setAirwayManagement((draft) => {
                        let newTreatments = updateSet(
                          draft.treatments,
                          AirwayTreatmentKeys.trachealkanuelenwechsel,
                          checked,
                        );
                        newTreatments = _.union(newTreatments, [AirwayTreatmentKeys.koniotomie]);
                        return { ...draft, treatments: newTreatments };
                      });
                    }}
                  />
                }
              />
            </div>

            <FormControlLabel
              label='sonstiger Atemwegszugang'
              checked={airwayManagementRecord.treatments.includes(AirwayTreatmentKeys.sonstigerAtemswgszugang)}
              control={
                <Checkbox
                  onChange={(event) => {
                    const checked = event.target.checked;
                    setAirwayManagement((draft) => ({
                      ...draft,
                      treatments: updateSet(draft.treatments, AirwayTreatmentKeys.sonstigerAtemswgszugang, checked),
                    }));
                  }}
                />
              }
            />
          </div>
        </div>
      </DiviFieldCard>
    </div>
  );
}
