import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import AnamneseNote from './AnamneseNote/AnamneseNote';
import { NodeType } from '../../../backendModels/report.model';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { useCSS } from '../../../provider/CSSProvider';
import style from './Anamnese.scss?inline';
import GeneralCondition from './GeneralCondition/GeneralCondition';
import TreatmentSituation from './TreatmentSituation/TreatmentSituation';
import FirstAider from './FirstAider/FirstAider';
import Multiresistant from './Multiresistant/Multiresistant';
import Anticoagulants from './Anticoagulants/Anticoagulants';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import FirstResponder from './FirstResponder/FirstResponder';
import { FIRST_RESPONDER_RECORD_ELEMENT_KEY } from '../../../models/genericElements/firstResponder';

export function Anamnese() {
  useCSS(style);

  const recordTypesOfPage = ['situationAndSymptoms', 'patientMedication'] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.INITIAL_SURVEY;
  const elementKeysOfPage = [FIRST_RESPONDER_RECORD_ELEMENT_KEY];

  const pageHeader = <PageHeader heading={getPageTitle(PageType.Anamnese)} />;

  const pageFooter = <PageFooter pageType={PageType.Anamnese} diviId={DiviNavigationIds.Anamnese} />;

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      elementKeys={elementKeysOfPage}
      nodeType={nodeTypeOfPage}
      diviId={DiviNavigationIds.Anamnese}
    >
      <div className='wireframe-container-anamnese'>
        <div className='anamnese'>
          <AnamneseNote nodeType={nodeTypeOfPage} />
        </div>
        <GeneralCondition nodeType={nodeTypeOfPage} />
        <TreatmentSituation nodeType={nodeTypeOfPage} />
        <Anticoagulants nodeType={nodeTypeOfPage} />
        <FirstAider nodeType={nodeTypeOfPage} />
        <FirstResponder nodeType={nodeTypeOfPage} />
        <Multiresistant nodeType={nodeTypeOfPage} />
      </div>
    </DiviPage>
  );
}
