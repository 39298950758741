import { useContext, useEffect } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import { FormGroup } from '@mui/material';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './Participants.scss?inline';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import _ from 'lodash';
import {
  getParticipantsTuplesFor,
  mapStringToParticpantKey,
  OPERATIONAL_DATA_PARTICIPANTS_RECORD_ELEMENT_KEY,
  OperationalDataParticipantsRecord,
  ParticipantsKeys,
  ParticipantsPropertyKeys,
} from '../../../../models/genericElements/operationalData/participants';
import { NodeType } from '../../../../backendModels/report.model';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import {
  changePropertyAndMarkTouched,
  handleUntouchedMultiSelectFieldUpdates,
} from '../../../../utils/recordWithTouched';

const participantsItems: [string, string][] = getParticipantsTuplesFor([
  ParticipantsKeys.nef,
  ParticipantsKeys.selbstfahrer,
  ParticipantsKeys.naw,
  ParticipantsKeys.rtw,
  ParticipantsKeys.ktw,
  ParticipantsKeys.rth,
  ParticipantsKeys.ith,
  ParticipantsKeys.sonstige,
  ParticipantsKeys.bergrettung,
  ParticipantsKeys.wasserrettung,
  ParticipantsKeys.teleNA,
]);

interface ParticipantsProps {
  nodeType: NodeType;
}

export default function Participants({ nodeType }: ParticipantsProps) {
  useCSS(style);

  const { missionData, findRecordOrDefault, adaptRecord, findNodes } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault(
    'generic',
    nodeType,
    OPERATIONAL_DATA_PARTICIPANTS_RECORD_ELEMENT_KEY,
  ) as OperationalDataParticipantsRecord;

  useEffect(() => {
    if (findNodes(nodeType).length > 0) {
      adaptRecord(
        'generic',
        nodeType,
        (draft: Draft<OperationalDataParticipantsRecord>) => {
          draft.inputState = InputState.ENTERED;

          handleUntouchedMultiSelectFieldUpdates(
            draft,
            ParticipantsPropertyKeys.participants,
            _.union(
              missionData?.participants
                ?.map((participant) => mapStringToParticpantKey(participant))
                .filter((participant): participant is ParticipantsKeys => participant != null) ?? [],
              [ParticipantsKeys.teleNA, ParticipantsKeys.rtw],
            ),
          );
        },
        OPERATIONAL_DATA_PARTICIPANTS_RECORD_ELEMENT_KEY,
      );
    }
  }, [missionData, findNodes, nodeType, adaptRecord]);

  const participantsValues = _.union(record.values?.participants?.options ?? [], [
    ParticipantsKeys.teleNA,
    ParticipantsKeys.rtw,
  ]);

  const changeParticipants = (values: string[]) => {
    changePropertyAndMarkTouched(
      adaptRecord,
      nodeType,
      OPERATIONAL_DATA_PARTICIPANTS_RECORD_ELEMENT_KEY,
      ParticipantsPropertyKeys.participants,
      { fieldType: 'multiSelect', options: values },
    );
  };

  return (
    <DiviFieldCard cardType={CardId.Participants}>
      <FormGroup className={`symptoms-list`}>
        <CheckboxList
          items={participantsItems}
          onValuesChange={changeParticipants}
          selectedValues={participantsValues}
          disabledItems={[ParticipantsKeys.rtw, ParticipantsKeys.teleNA]}
        />
      </FormGroup>
    </DiviFieldCard>
  );
}
