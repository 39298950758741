import { Alert, AlertProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { getPageRoute, getPageTitle, PageType } from '../../../models/diviPage';

import style from './RecordInputInfo.scss?inline';
import { useCSS } from '../../../provider/CSSProvider';

interface RecordInputInfoProps extends Omit<AlertProps, 'severity' | 'variant' | 'className'> {
  /**
   * The page the info alert should point to.
   */
  page: PageType;
  /**
   * The info alert is only shown when show is true.
   */
  show?: boolean;
  className?: string;
}
export default function RecordInputInfo(props: RecordInputInfoProps) {
  useCSS(style);
  const { show, page, className, ...alertProps } = props;

  return (
    <>
      {show && (
        <Alert {...alertProps} className={`record-input-info ${className}`} severity='info' variant='outlined'>
          Daten wurden bereits auf der Seite&nbsp;
          <Link className='record-input-info-link' to={getPageRoute(page)}>
            {getPageTitle(page)}
          </Link>
          &nbsp;hinzugefügt.
        </Alert>
      )}
    </>
  );
}
