export enum SymptomStartKeys {
  ueber24H = 'OVER_24_HOURS_AGO',
  beobachtet = 'COLLAPSE_OBSERVED',
  unbekannt = 'UNKNOWN',
}

const SymptomStartNameTuples: Record<SymptomStartKeys, string> = {
  [SymptomStartKeys.ueber24H]: 'vor über 24h',
  [SymptomStartKeys.beobachtet]: 'Kollaps beobachtet',
  [SymptomStartKeys.unbekannt]: 'unbekannt',
};

export function getSymptomStartNameTuple(key: SymptomStartKeys): [SymptomStartKeys, string] {
  return [key, SymptomStartNameTuples[key]];
}

export function getSymptomStartNameTuplesFor(keys: SymptomStartKeys[]): [SymptomStartKeys, string][] {
  return keys.map((k) => {
    return getSymptomStartNameTuple(k);
  });
}
