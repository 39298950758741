import { Draft } from 'immer';
import { InputState, UnknownInputValue } from '../backendModels/general.model';
import {
  GenericElementEnteredValue,
  GenericFieldValueTypes,
  MultiSelectFieldValue,
} from '../backendModels/generic-element.model';
import _ from 'lodash';
import { isGenericRecordDeletable } from '../models/generic';
import { NodeType } from '../backendModels/report.model';
import { AdaptRecordFunction } from '../provider/ReportsAPIProvider';
import { NodeRecordTypeMap } from '../backendModels/records.model';

type RecordWithTouched = (
  | (GenericElementEnteredValue & { values?: { touched?: MultiSelectFieldValue } })
  | (UnknownInputValue & { values?: null })
) &
  NodeRecordTypeMap['generic'];

// Update a text field in the given draft record with value if it is currently untouched.
export function handleUntouchedTextFieldUpdates<
  RECORD_TYPE extends Draft<RecordWithTouched>,
  FIELD_KEY extends string & keyof (RECORD_TYPE & GenericElementEnteredValue)['values'],
>(draft: RECORD_TYPE, property: FIELD_KEY, value: string | null | undefined): void {
  if (!draft.values?.touched?.options.includes(property)) {
    if (value != null && value !== '') {
      draft.values = {
        ...draft.values,
        [property]: {
          text: value,
          fieldType: 'text',
        },
      };
    } else {
      delete draft.values?.[property];
    }
  }
}

// Update a numeric field in the given draft record with value if it is currently untouched.
export function handleUntouchedNumericFieldUpdates<
  RECORD_TYPE extends Draft<RecordWithTouched>,
  FIELD_KEY extends string & keyof (RECORD_TYPE & GenericElementEnteredValue)['values'],
>(draft: RECORD_TYPE, property: FIELD_KEY, value: number | null | undefined): void {
  if (!draft.values?.touched?.options.includes(property)) {
    if (value != null) {
      draft.values = {
        ...draft.values,
        [property]: {
          number: value,
          fieldType: 'numeric',
        },
      };
    } else {
      delete draft.values?.[property];
    }
  }
}

// Update a singleSelect field in the given draft record with value if it is currently untouched.
export function handleUntouchedSingleSelectFieldUpdates<
  RECORD_TYPE extends Draft<RecordWithTouched>,
  FIELD_KEY extends string & keyof (RECORD_TYPE & GenericElementEnteredValue)['values'],
>(draft: RECORD_TYPE, property: FIELD_KEY, value: string | null | undefined): void {
  if (!draft.values?.touched?.options.includes(property)) {
    if (value != null && value !== '') {
      draft.values = {
        ...draft.values,
        [property]: {
          option: value,
          fieldType: 'singleSelect',
        },
      };
    } else {
      delete draft.values?.[property];
    }
  }
}

// Update a multiSelect field in the given draft record with value if it is currently untouched.
export function handleUntouchedMultiSelectFieldUpdates<
  RECORD_TYPE extends Draft<RecordWithTouched>,
  FIELD_KEY extends string & keyof (RECORD_TYPE & GenericElementEnteredValue)['values'],
>(draft: RECORD_TYPE, property: FIELD_KEY, values: readonly string[] | null | undefined): void {
  if (!draft.values?.touched?.options.includes(property)) {
    if (values != null) {
      draft.values = {
        ...draft.values,
        [property]: {
          options: [...values],
          fieldType: 'multiSelect',
        },
      };
    } else {
      delete draft.values?.[property];
    }
  }
}

// Set the given property to the given value and mark it as touched.
// Will also delete the record if isGenericRecordDeletable.
export function changePropertyAndMarkTouched(
  adaptRecord: AdaptRecordFunction,
  nodeType: NodeType,
  elementKey: string,
  property: string,
  value?: GenericFieldValueTypes,
) {
  adaptRecord(
    'generic',
    nodeType,
    (draft: Draft<RecordWithTouched>, deleteRecord) => {
      draft.inputState = InputState.ENTERED;

      if (!_.isEqual(value, draft.values?.[property])) {
        draft.values = {
          ...draft.values,
          touched: { options: _.union([property], draft.values?.touched?.options ?? []), fieldType: 'multiSelect' },
        };
      }

      if (value != null) {
        draft.values = {
          ...draft.values,
          [property]: value,
        };
      } else {
        delete draft.values?.[property];
        if (isGenericRecordDeletable(draft)) {
          deleteRecord();
        }
      }
    },
    elementKey,
  );
}

// Returns 'prefilled' if the given property is not null and untouched.
export function getTouchedClassName<
  RECORD_TYPE extends RecordWithTouched,
  FIELD_KEY extends string & keyof (RECORD_TYPE & GenericElementEnteredValue)['values'],
>(record: RECORD_TYPE, property: FIELD_KEY) {
  return !record.values?.touched?.options.includes(property) && record.values?.[property] != null ? 'prefilled' : '';
}
