import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { MultiSelectFieldValueTyped, SingleSelectFieldValueTyped } from '../generic';
import { TextFieldValue } from '../../backendModels/generic-element.model';

export enum ReanimationYesKeys {
  reanimation = 'REANIMATION_ZUSAETZE_REA',
}

export enum ReanimationDescriptionKeys {
  roscimverlauf = 'REANIMATION_ZUSAETZE_ROSC',
  niemalsrosc = 'REANIMATION_ZUSAETZE_KEIN_ROSC',
  erfolglos = 'REANIMATION_ZUSAETZE_ERFOLGLOS',
}

export enum ReanimationByKeys {
  ersthelfer = 'REANIMATION_ZUSAETZE_PERSONAL_BEGINN_ERSTE_HILFE',
  firstresponder = 'REANIMATION_ZUSAETZE_PERSONAL_BEGINN_FIRST_RESPONDER',
  rettungsdienst = 'REANIMATION_ZUSAETZE_PERSONAL_BEGINN_RETTUNGSDIENST',
  notarzt = 'REANIMATION_ZUSAETZE_PERSONAL_BEGINN_ARZT',
}

export enum ReanimationNoKeys {
  keinereanimation = 'REANIMATION_ZUSAETZE_KEINE_REA',
}

export enum ReanimationNoReasonKeys {
  nichtgewuenscht = 'REANIMATION_ZUSAETZE_KEINE_REA_GRUND_NICHT_GEWUENSCHT',
  zuspaet = 'REANIMATION_ZUSAETZE_KEINE_REA_GRUND_ZU_SPAET',
  aussichtslosegrunderkrankung = 'REANIMATION_ZUSAETZE_KEINE_REA_GRUND_HOFFNUNGSLOS',
}

export enum ReanimationHospitalKeys {
  khaufnahme = 'REANIMATION_ZUSAETZE_KH_AUFNAHME',
}

export enum ReanimationHospitalConditionKeys {
  mitrosc = 'REANIMATION_ZUSAETZE_KH_AUFNAHME_ZUSTAND_ROSC',
  laufendereanimation = 'REANIMATION_ZUSAETZE_KH_AUFNAHME_ZUSTAND_REA',
}

type AllReanimationKeys =
  | ReanimationYesKeys
  | ReanimationDescriptionKeys
  | ReanimationByKeys
  | ReanimationNoKeys
  | ReanimationNoReasonKeys
  | ReanimationHospitalKeys
  | ReanimationHospitalConditionKeys;

export enum DeathYesKeys {
  todesfeststellung = 'REANIMATION_ZUSAETZE_TODESFESTSTELLUNG',
}

export enum DeathKindKeys {
  todesfeststellungnatuerlich = 'REANIMATION_ZUSAETZE_TOD_ART_NATUERLICH',
  todesfeststellungunklar = 'REANIMATION_ZUSAETZE_TOD_ART_UNKLAR',
  todesfeststellungnichtnatuerlich = 'REANIMATION_ZUSAETZE_TOD_ART_NICHT_NATUERLICH',
}

export enum DeathUnknownTimeKeys {
  todeszeitpunktnichtbestimmbar = 'REANIMATION_ZUSAETZE_TODESZEITPUNKT_UNBESTIMMBAR',
}

type AllDeathKeys = DeathYesKeys | DeathKindKeys | DeathUnknownTimeKeys;

export const REANIMATION_DEATH_RECORD_ELEMENT_KEY = 'reanimationZusaetze';

export type ReanimationDeathRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof REANIMATION_DEATH_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          reanimationZusaetzeRea?: MultiSelectFieldValueTyped<ReanimationYesKeys>;
          reanimationZusaetzeBeschreibung?: SingleSelectFieldValueTyped<ReanimationDescriptionKeys>;
          reanimationZusaetzePersonalBeginn?: SingleSelectFieldValueTyped<ReanimationByKeys>;
          reanimationZusaetzeKeineRea?: MultiSelectFieldValueTyped<ReanimationNoKeys>;
          reanimationZusaetzeKeineReaGrund?: SingleSelectFieldValueTyped<ReanimationNoReasonKeys>;
          reanimationZusaetzeKeineReaGrundSonstiges?: TextFieldValue;
          reanimationZusaetzeKhAufnahme?: MultiSelectFieldValueTyped<ReanimationHospitalKeys>;
          reanimationZusaetzeKhAufnahmeZustand?: SingleSelectFieldValueTyped<ReanimationHospitalConditionKeys>;
          reanimationZusaetzeTod?: MultiSelectFieldValueTyped<DeathYesKeys>;
          reanimationZusaetzeTodArt?: SingleSelectFieldValueTyped<DeathKindKeys>;
          reanimationZusaetzeTodeszeitpunkt?: TextFieldValue;
          reanimationZusaetzeTodeszeitpunktUnklar?: MultiSelectFieldValueTyped<DeathUnknownTimeKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

const DeathLabels: Record<AllDeathKeys, string> = {
  [DeathYesKeys.todesfeststellung]: 'Todesfeststellung',
  [DeathKindKeys.todesfeststellungnatuerlich]: 'natürlich',
  [DeathKindKeys.todesfeststellungunklar]: 'unklar',
  [DeathKindKeys.todesfeststellungnichtnatuerlich]: 'nicht natürlich',
  [DeathUnknownTimeKeys.todeszeitpunktnichtbestimmbar]: 'Todeszeitpunkt nicht bestimmbar',
};

const ReanimationLabels: Record<AllReanimationKeys, string> = {
  [ReanimationYesKeys.reanimation]: 'Reanimation',
  [ReanimationDescriptionKeys.roscimverlauf]: 'ROSC im Verlauf',
  [ReanimationDescriptionKeys.niemalsrosc]: 'niemals ROSC',
  [ReanimationDescriptionKeys.erfolglos]: 'erfolglos',
  [ReanimationByKeys.ersthelfer]: 'Ersthelfer',
  [ReanimationByKeys.firstresponder]: 'First Responder',
  [ReanimationByKeys.rettungsdienst]: 'Rettungsdienst',
  [ReanimationByKeys.notarzt]: 'Notarzt',
  [ReanimationNoKeys.keinereanimation]: 'keine Reanimation',
  [ReanimationNoReasonKeys.nichtgewuenscht]: 'nicht gewünscht / Patientenverfügung',
  [ReanimationNoReasonKeys.zuspaet]: 'zu spät',
  [ReanimationNoReasonKeys.aussichtslosegrunderkrankung]: 'aussichtslose Grunderkrankung',
  [ReanimationHospitalKeys.khaufnahme]: 'KH-Aufnahme',
  [ReanimationHospitalConditionKeys.mitrosc]: 'mit ROSC',
  [ReanimationHospitalConditionKeys.laufendereanimation]: 'laufende Reanimation',
};

export function getDeathTuple<ENUM_TYPE extends AllDeathKeys>(key: ENUM_TYPE): [ENUM_TYPE, string] {
  return [key, DeathLabels[key]];
}

export function getDeathTuplesFor<ENUM_TYPE extends AllDeathKeys>(keys: ENUM_TYPE[]): [ENUM_TYPE, string][] {
  return keys.map((k) => {
    return getDeathTuple(k);
  });
}

export function getReanimationTuple<ENUM_TYPE extends AllReanimationKeys>(key: ENUM_TYPE): [ENUM_TYPE, string] {
  return [key, ReanimationLabels[key]];
}

export function getReanimationTuplesFor<ENUM_TYPE extends AllReanimationKeys>(
  keys: ENUM_TYPE[],
): [ENUM_TYPE, string][] {
  return keys.map((k) => {
    return getReanimationTuple(k);
  });
}
