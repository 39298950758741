import { Autocomplete, createFilterOptions, TextField } from '@mui/material';

export interface OptionValueType<T> {
  /**
   * When not undefined this indicates that the value was created with custom input.
   */
  customInputValue?: string;
  /**
   * The label displayed in the options list.
   */
  label: string;
  /**
   * The value of the option
   */
  value: T;
}

export type CreatableAutocompleteProps<T> = {
  value?: OptionValueType<T>;
  options: OptionValueType<T>[];
  /**
   * Creates the value for the newly input value.
   * @param inputValue The value that is inputted manually.
   */
  newValueCreatorFunction: (inputValue: string) => T;
  onChange?: (value: OptionValueType<T>) => void;
  label?: string;
};

/**
 * Autocomplete that provides functionality to create new values.
 */
export default function CreatableAutocomplete<T>(props: CreatableAutocompleteProps<T>) {
  const { value, options, newValueCreatorFunction, onChange, label } = props;
  const filter = createFilterOptions<OptionValueType<T>>();

  const formatOption = (label: string, customInputValue?: string) => {
    if (customInputValue == null) {
      return <>{label}</>;
    }
    return (
      <span>
        Unbekannten Wirkstoff wählen:
        <span
          style={{
            background: '#fb7324',
            borderRadius: '15px',
            padding: '5px 15px',
            marginLeft: '10px',
            color: '#ffffff',
          }}
        >
          {label}
        </span>
      </span>
    );
  };

  return (
    <Autocomplete
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      value={value}
      onChange={(_, newValue) => {
        if (newValue === null) {
          return;
        }
        if (typeof newValue === 'string') {
          onChange?.({
            label: newValue,
            value: newValueCreatorFunction(newValue),
          });
          return;
        }
        onChange?.(newValue);
      }}
      options={options}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            customInputValue: inputValue,
            label: inputValue,
            value: newValueCreatorFunction(inputValue),
          });
        }

        return filtered;
      }}
      getOptionLabel={(option): string => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.customInputValue !== undefined) {
          return option.customInputValue;
        }
        return option.label;
      }}
      renderOption={({ key, ...otherProps }, option) => (
        <li key={key} {...otherProps}>
          {typeof option === 'string' ? option : formatOption(option.label, option.customInputValue)}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
