import { Alert, Portal, Snackbar, useTheme } from '@mui/material';
import React from 'react';
import { OverridableStringUnion } from '@mui/types';
import { AlertColor, AlertPropsColorOverrides } from '@mui/material/Alert/Alert';
import style from './SnackbarAlert.scss?inline';
import { useCSS } from '../../../provider/CSSProvider';
import { SnackbarCloseReason } from '@mui/material/Snackbar/Snackbar';

interface SnackbarAlertProps {
  open: boolean;
  onClose: (event: React.SyntheticEvent | Event, reason: SnackbarCloseReason) => void;
  message: string;
  autoHideDuration?: number;
  severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>;
  closeOnClickaway?: boolean;
}

export function SnackbarAlert({
  open,
  onClose,
  message,
  autoHideDuration,
  severity,
  closeOnClickaway,
}: SnackbarAlertProps) {
  useCSS(style);
  const theme = useTheme();

  const onCloseWrapper = (event: React.SyntheticEvent | Event, reason: SnackbarCloseReason) => {
    if (closeOnClickaway === true || reason !== 'clickaway') {
      onClose(event, reason);
    }
  };

  return (
    <Portal container={theme.components?.MuiPopper?.defaultProps?.container}>
      <Snackbar
        className='snackbar-alert'
        autoHideDuration={autoHideDuration ?? 10000}
        open={open}
        onClose={onCloseWrapper}
      >
        <Alert severity={severity}> {message}</Alert>
      </Snackbar>
    </Portal>
  );
}
