import { useContext } from 'react';
import { Button, ButtonGroup, Typography } from '@mui/material';
import style from './inAreaNavigation.scss?inline';
import { useCSS } from '../../../provider/CSSProvider';
import { CardId, getCardTitle } from '../../../models/diviCard';
import { DiviPageContext } from '../DiviPage/DiviPage';

interface navProps {
  /**
   * List of Ids to navigate to.
   */
  listOfCardTypes: CardId[];
}

export default function InAreaNavigation(props: navProps) {
  useCSS(style);

  const { divPageContent } = useContext(DiviPageContext);
  const { listOfCardTypes } = props;

  function scrollToElement(id: string) {
    const element = divPageContent?.querySelector(`#card-${id}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const list = listOfCardTypes.map((cardType) => (
    <Button key={'button-' + cardType} onClick={() => scrollToElement(cardType.toString())}>
      <Typography noWrap>{getCardTitle(cardType)}</Typography>
    </Button>
  ));

  return (
    <div className='area-navigation-container'>
      <ButtonGroup variant='contained' className='area-navigation' size='small'>
        {list}
      </ButtonGroup>
    </div>
  );
}
