import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { NodeType } from '../../../backendModels/report.model';
import DiagnosisNote from './DiagnosisNote/DiagnosisNote';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import { useCSS } from '../../../provider/CSSProvider';
import style from './InitialDiagnosis.scss?inline';
import { getPageTitle, PageType } from '../../../models/diviPage';
import PalliativeSituation from './PalliativeSituation/PalliativeSituation';
import NacaScore from './NacaScore/NacaScore';
import { DIAGNOSIS_INITIAL_RECORD_ELEMENT_KEY } from '../../../models/genericElements/diagnosisInitial';

export function InitialDiagnosis() {
  useCSS(style);

  const nodeTypeOfPage = NodeType.INITIAL_SURVEY;
  const elementKeysOfPage = [DIAGNOSIS_INITIAL_RECORD_ELEMENT_KEY];

  const pageHeader = <PageHeader heading={getPageTitle(PageType.DiagnosisInitial)} />;

  const pageFooter = <PageFooter pageType={PageType.DiagnosisInitial} diviId={DiviNavigationIds.InitialDiagnosis} />;

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={[]}
      nodeType={nodeTypeOfPage}
      elementKeys={elementKeysOfPage}
      diviId={DiviNavigationIds.InitialDiagnosis}
    >
      <div className='wireframe-container-diagnosis-initial'>
        <DiagnosisNote nodeType={nodeTypeOfPage} />
        <NacaScore nodeType={nodeTypeOfPage} />
        <div className='palliative-situation'>
          <PalliativeSituation nodeType={nodeTypeOfPage} />
        </div>
      </div>
    </DiviPage>
  );
}
