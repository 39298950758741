import { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { NodeType } from '../../../../backendModels/report.model';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { CardId } from '../../../../models/diviCard';
import {
  SAMPLER_PLUS_S_RECORD_ELEMENT_KEY,
  SAMPLERPlusSKeys,
  SAMPLERPlusSRecord,
} from '../../../../models/genericElements/samplerPlusS';
import { useFreshCallback } from '../../../../utils/hooks';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import { isGenericRecordDeletable } from '../../../../models/generic';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import RecordInputInfo from '../../../customs/recordInputInfo/RecordInputInfo';
import { PageType } from '../../../../models/diviPage';
import { defaultRecords } from '../../../../DefaultRecords';

interface SAMPLERPlusSNoteProps {
  nodeType: NodeType;
}

export default function SAMPLERPlusSNote({ nodeType }: SAMPLERPlusSNoteProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, SAMPLER_PLUS_S_RECORD_ELEMENT_KEY) as SAMPLERPlusSRecord;
  const anamneseRecordNote = findRecordOrDefault(defaultRecords.situationAndSymptoms.type, nodeType).note;

  const handleChange = useFreshCallback((value: string, type: SAMPLERPlusSKeys) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<SAMPLERPlusSRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value !== '') {
          draft.values = {
            ...draft.values,
            [type]: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.[type];
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      SAMPLER_PLUS_S_RECORD_ELEMENT_KEY,
    );
  });

  return (
    <DiviFieldCard cardType={CardId.SamplerPlusSNote}>
      <RecordInputInfo className='add-margin-bottom' show={anamneseRecordNote !== undefined} page={PageType.Anamnese} />
      <DebouncedTextField
        className='no-margin-top'
        label='S - Symptome'
        fullWidth
        value={record.inputState === InputState.ENTERED ? (record.values?.samplersS?.text ?? '') : ''}
        onDebounceChange={(value) => handleChange(value, SAMPLERPlusSKeys.S)}
      />
      <DebouncedTextField
        label='A - Allergien'
        fullWidth
        value={record.inputState === InputState.ENTERED ? (record.values?.samplersA?.text ?? '') : ''}
        onDebounceChange={(value) => handleChange(value, SAMPLERPlusSKeys.A)}
      />
      <DebouncedTextField
        label='M - Medikation'
        fullWidth
        value={record.inputState === InputState.ENTERED ? (record.values?.samplersM?.text ?? '') : ''}
        onDebounceChange={(value) => handleChange(value, SAMPLERPlusSKeys.M)}
      />
      <DebouncedTextField
        label='P - Medizinische Vorgeschichte'
        fullWidth
        value={record.inputState === InputState.ENTERED ? (record.values?.samplersP?.text ?? '') : ''}
        onDebounceChange={(value) => handleChange(value, SAMPLERPlusSKeys.P)}
      />
      <DebouncedTextField
        label='L - Letzte'
        fullWidth
        value={record.inputState === InputState.ENTERED ? (record.values?.samplersL?.text ?? '') : ''}
        onDebounceChange={(value) => handleChange(value, SAMPLERPlusSKeys.L)}
      />
      <DebouncedTextField
        label='E - Ereignisse'
        fullWidth
        value={record.inputState === InputState.ENTERED ? (record.values?.samplersE?.text ?? '') : ''}
        onDebounceChange={(value) => handleChange(value, SAMPLERPlusSKeys.E)}
      />
      <DebouncedTextField
        label='R - Risikofaktoren'
        fullWidth
        value={record.inputState === InputState.ENTERED ? (record.values?.samplersR?.text ?? '') : ''}
        onDebounceChange={(value) => handleChange(value, SAMPLERPlusSKeys.R)}
      />
      <DebouncedTextField
        label='+S - Schwangerschaft'
        fullWidth
        value={record.inputState === InputState.ENTERED ? (record.values?.samplersPS?.text ?? '') : ''}
        onDebounceChange={(value) => handleChange(value, SAMPLERPlusSKeys.plusS)}
      />
    </DiviFieldCard>
  );
}
