import React, { ReactNode, useEffect, useState } from 'react';
import { Button, ButtonProps } from '@mui/material';
import style from './ToggleButton.scss?inline';
import { useCSS } from '../../../provider/CSSProvider';

export type ToggleButtonProps = Omit<ButtonProps, 'startIcon' | 'onChange'> & {
  /**
   * Icon that is displayed when the button is selected.
   */
  selectedIcon?: ReactNode;
  /**
   * Icon that is displayed when the button is not selected.
   */
  unselectedIcon?: ReactNode;
  /**
   * When true the button is selected.
   */
  selected?: boolean;
  /**
   * Is triggered when the selected state of the button changes
   * @param value The state of the button.
   */
  onChange?: (value: boolean) => void;
};

export default function ToggleButton(props: ToggleButtonProps) {
  useCSS(style);

  const { selectedIcon, unselectedIcon, selected, onClick, onChange, className, ...buttonProps } = props;

  const [selectedState, setSelectedState] = useState<boolean>(selected ?? false);

  useEffect(() => {
    if (selected !== undefined) {
      setSelectedState(selected);
    }
  }, [selected]);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (onClick) {
      onClick(event);
    }
    if (onChange) {
      onChange(selectedState);
    }
  }

  return (
    <Button
      {...buttonProps}
      className={`${className ? className : ''} divi-toggle-button ${selectedState ? 'selected' : ''}`}
      startIcon={selectedState ? selectedIcon : unselectedIcon}
      onClick={handleClick}
    />
  );
}
