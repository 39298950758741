import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../../generic';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export enum MedicalConditionZNSKeys {
  stroke = 'STROKE_TIA_ICB',
  strokeInLyse = 'STROKE_IN_LYSE',
  krampfanfall = 'KRAMPFANFALL',
  statusEpilepticus = 'STATUS_EPILEPTICUS',
  meningismusEncephalitis = 'MENINGISMUS_ENCEPHALITIS',
  syncope = 'SYNKOPE',
}

export const MEDICAL_CONDITION_ZNS_RECORD_ELEMENT_KEY = 'erkrankungenZNS';

export type MedicalConditionZNSRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof MEDICAL_CONDITION_ZNS_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          erkrankungenZns?: MultiSelectFieldValueTyped<MedicalConditionZNSKeys>;
          erkrankungenZnsSonstige?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
    | {
        readonly inputState: InputState.NORMAL;
        readonly values: null;
      }
  );

const znsLabels: Record<MedicalConditionZNSKeys, string> = {
  [MedicalConditionZNSKeys.stroke]: 'Schlaganfall, TIA, intrakranielle Blutung',
  [MedicalConditionZNSKeys.strokeInLyse]: 'im Lysefenster',
  [MedicalConditionZNSKeys.krampfanfall]: 'Krampfanfall',
  [MedicalConditionZNSKeys.statusEpilepticus]: 'Status Epilepticus',
  [MedicalConditionZNSKeys.meningismusEncephalitis]: 'Meningitis / Encephalitis',
  [MedicalConditionZNSKeys.syncope]: 'Synkope',
};

export function getZNSTupel(key: MedicalConditionZNSKeys): [MedicalConditionZNSKeys, string] {
  return [key, znsLabels[key]];
}

export function getZNSTupelsFor(keys: MedicalConditionZNSKeys[]): [MedicalConditionZNSKeys, string][] {
  return keys.map((k) => {
    return getZNSTupel(k);
  });
}
