import { Box, MenuItem, Select } from '@mui/material';
import DebouncedTextField, { DebouncedTextFieldProps } from '../debouncedTextField/DebouncedTextField';
import { useCSS } from '../../../provider/CSSProvider';
import style from './TextFieldSelectGroup.scss?inline';

export type TextFieldSelectGroupProps<OptionType extends string> = {
  options: [OptionType, string][];
  selectedOption: OptionType;
  onSelectedOptionChange?: (value: OptionType) => void;
} & DebouncedTextFieldProps;

export default function TextFieldSelectGroup<OptionType extends string>(props: TextFieldSelectGroupProps<OptionType>) {
  useCSS(style);

  const { options, selectedOption, onSelectedOptionChange, ...textFieldProps } = props;

  return (
    <Box className='textfield-select-group' display='flex'>
      <DebouncedTextField className='textfield-select-group-tf' {...textFieldProps} />
      <Select
        className='textfield-select-group-select'
        value={selectedOption}
        onChange={(event) => onSelectedOptionChange?.(event.target.value as OptionType)}
      >
        {options?.map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
