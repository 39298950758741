import React, { useContext } from 'react';
import globalStyle from '../../../../global.scss?inline';
import style from './Pupils.scss?inline';
import { Checkbox, FormControl, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { InputState } from '../../../../backendModels/general.model';
import { useCSS } from '../../../../provider/CSSProvider';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import {
  PupilReactionToLightKeys,
  PupilShapeKeys,
  PupilSizeKeys,
  pupilTransitionInputState,
} from '../../../../models/pupils';
import { NodeType } from '../../../../backendModels/report.model';
import { cloneAndMerge } from '../../../../utils/util';
import { CardId } from '../../../../models/diviCard';
import { useFreshCallback } from '../../../../utils/hooks';

interface PupilsProps {
  nodeType: NodeType;
}

function Pupils({ nodeType }: PupilsProps) {
  useCSS(style);
  useCSS(globalStyle);

  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.pupils.type, nodeType);

  const changeRightEyeSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    reportAPI.adaptRecord(defaultRecords.pupils.type, nodeType, (draft) =>
      cloneAndMerge(pupilTransitionInputState(draft, InputState.ENTERED), { size: { right: event.target.value } }),
    );
  };
  const changeLeftEyeSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    reportAPI.adaptRecord(defaultRecords.pupils.type, nodeType, (draft) =>
      cloneAndMerge(pupilTransitionInputState(draft, InputState.ENTERED), { size: { left: event.target.value } }),
    );
  };

  const changeLeftEyeDistorted = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    reportAPI.adaptRecord(defaultRecords.pupils.type, nodeType, (draft) =>
      cloneAndMerge(pupilTransitionInputState(draft, InputState.ENTERED), {
        shape: { left: checked ? PupilShapeKeys.distorted : PupilShapeKeys.normal },
      }),
    );
  };

  const changeRightEyeDistorted = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    reportAPI.adaptRecord(defaultRecords.pupils.type, nodeType, (draft) =>
      cloneAndMerge(pupilTransitionInputState(draft, InputState.ENTERED), {
        shape: { right: checked ? PupilShapeKeys.distorted : PupilShapeKeys.normal },
      }),
    );
  };

  const changeRightEyeReactionToLight = (event: React.ChangeEvent<HTMLInputElement>) => {
    reportAPI.adaptRecord(defaultRecords.pupils.type, nodeType, (draft) =>
      cloneAndMerge(pupilTransitionInputState(draft, InputState.ENTERED), {
        reactionToLight: { right: event.target.value },
      }),
    );
  };

  const changeLeftEyeReactionToLight = (event: React.ChangeEvent<HTMLInputElement>) => {
    reportAPI.adaptRecord(defaultRecords.pupils.type, nodeType, (draft) =>
      cloneAndMerge(pupilTransitionInputState(draft, InputState.ENTERED), {
        reactionToLight: { left: event.target.value },
      }),
    );
  };

  const handleNoPathologicalFindings = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.pupils.type, nodeType, (draft) =>
      pupilTransitionInputState(draft, InputState.NORMAL),
    );
  });

  return (
    <DiviFieldCard
      cardType={CardId.Pupils}
      onNormalStateButtonClicked={handleNoPathologicalFindings}
      recordType={defaultRecords.pupils.type}
      nodeType={nodeType}
    >
      <div id='pupils-area' className='row'>
        <div id='pupils-size-area'>
          <Typography align='left' className='title'>
            Pupillenweite
          </Typography>
          <div className='row'>
            <FormControl>
              <FormLabel>rechts</FormLabel>
              <RadioGroup
                name='right-group-pupil'
                value={record.inputState === InputState.ENTERED ? (record.size?.right ?? null) : null}
                onChange={changeRightEyeSize}
              >
                <Radio value={PupilSizeKeys.narrowed} />
                <Radio value={PupilSizeKeys.normal} />
                <Radio value={PupilSizeKeys.dilated} />
              </RadioGroup>
              <Checkbox
                checked={record.inputState === InputState.ENTERED && record.shape?.right === PupilShapeKeys.distorted}
                onChange={changeRightEyeDistorted}
              />
            </FormControl>
            <div className='label-list'>
              <div>eng</div>
              <div>mittel</div>
              <div>weit</div>
              <div>entrundet</div>
            </div>
            <FormControl>
              <FormLabel>links</FormLabel>
              <RadioGroup
                name='left-group-pupil'
                value={record.inputState === InputState.ENTERED ? (record.size?.left ?? null) : null}
                onChange={changeLeftEyeSize}
              >
                <Radio value={PupilSizeKeys.narrowed} />
                <Radio value={PupilSizeKeys.normal} />
                <Radio value={PupilSizeKeys.dilated} />
              </RadioGroup>
              <Checkbox
                checked={record.inputState === InputState.ENTERED && record.shape?.left === PupilShapeKeys.distorted}
                onChange={changeLeftEyeDistorted}
              />
            </FormControl>
          </div>
        </div>

        <div id='pupils-light-reflex-area'>
          <Typography align='left' className='title'>
            Lichtreaktion
          </Typography>
          <div className='row'>
            <FormControl>
              <FormLabel>rechts</FormLabel>
              <RadioGroup
                name='right-group-light-reaction'
                value={record.inputState === InputState.ENTERED ? (record.reactionToLight?.right ?? null) : null}
                onChange={changeRightEyeReactionToLight}
              >
                <Radio value={PupilReactionToLightKeys.normal} />
                <Radio value={PupilReactionToLightKeys.slow} />
                <Radio value={PupilReactionToLightKeys.noReaction} />
              </RadioGroup>
            </FormControl>
            <div className='label-list'>
              <div>prompt</div>
              <div>träge</div>
              <div>keine</div>
            </div>
            <FormControl>
              <FormLabel>links</FormLabel>
              <RadioGroup
                name='left-group-light-reaction'
                value={record.inputState === InputState.ENTERED ? (record.reactionToLight?.left ?? null) : null}
                onChange={changeLeftEyeReactionToLight}
              >
                <Radio value={PupilReactionToLightKeys.normal} />
                <Radio value={PupilReactionToLightKeys.slow} />
                <Radio value={PupilReactionToLightKeys.noReaction} />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </DiviFieldCard>
  );
}

export default Pupils;
