import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../generic';
import { TextFieldValue } from '../../backendModels/generic-element.model';

export const CONTROL_CENTER_RECORD_ELEMENT_KEY = 'diviControlCenter';

export enum ControlCenterPropertyKeys {
  location = 'location',
  personnelNumber = 'personnelNumber',
  controlCenter = 'controlCenter',
}

export type ControlCenterRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof CONTROL_CENTER_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          location?: TextFieldValue;
          personnelNumber?: TextFieldValue;
          controlCenter?: TextFieldValue;
          touched?: MultiSelectFieldValueTyped<ControlCenterPropertyKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );
