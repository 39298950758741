import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { MultiSelectFieldValueTyped, SingleSelectFieldValueTyped } from '../generic';

export enum SpecificsKeys {
  besonderheitenuebernahmevonarzt = 'BESONDERHEITEN_UEBERNAHME_VON_ARZT',
  besonderheitenuebergabeanarzt = 'BESONDERHEITEN_UEBERGABE_AN_ARZT',
  besonderheitentransportinkh = 'BESONDERHEITEN_TRANSPORT_IN_KH',
  besonderheitentransportmarzt = 'BESONDERHEITEN_TRANSPORT_M_ARZT',
  besonderheitentransportmrth = 'BESONDERHEITEN_TRANSPORT_M_RTH',
  besonderheitentransportmsignal = 'BESONDERHEITEN_TRANSPORT_M_SIGNAL',
  besonderheitentransportkliniknimmtnichtauf = 'BESONDERHEITEN_TRANSPORT_KLINIK_NIMMT_NICHT_AUF',
  besonderheitentransportzwangsbelegung = 'BESONDERHEITEN_TRANSPORT_ZWANGSBELEGUNG',
  besonderheitentransportzwangsunterbringung = 'BESONDERHEITEN_TRANSPORT_ZWANGSUNTERBRINGUNG',
  besonderheitennurbehandlung = 'BESONDERHEITEN_NUR_BEHANDLUNG',
  besonderheitennurbehandlungvereigerung = 'BESONDERHEITEN_NUR_BEHANDLUNG_VEREIGERUNG',
  besonderheitennurbehandlungpatnichttransportfaehig = 'BESONDERHEITEN_NUR_BEHANDLUNG_PAT_NICHT_TRANSPORTFAEHIG',
  besonderheitennurbehandlungtherapieverzicht = 'BESONDERHEITEN_NUR_BEHANDLUNG_THERAPIEVERZICHT',
  besonderheitensituationhygiene = 'BESONDERHEITEN_SITUATION_HYGIENE',
  besonderheitensituationpatzugang = 'BESONDERHEITEN_SITUATION_PAT_ZUGANG',
  besonderheitensituationtechrettung = 'BESONDERHEITEN_SITUATION_TECH_RETTUNG',
  besonderheitensituationschwertransport = 'BESONDERHEITEN_SITUATION_SCHWERTRANSPORT',
  besonderheitengrossschadenslagelna = 'BESONDERHEITEN_GROSSSCHADENSLAGE_LNA',
  besonderheitengrossschadenslagemanv = 'BESONDERHEITEN_GROSSSCHADENSLAGE_MANV',
  besonderheitengrossschadenslagemehrerepatienten = 'BESONDERHEITEN_GROSSSCHADENSLAGE_MEHRERE_PATIENTEN',
}

const specificsLabels: Record<SpecificsKeys, string> = {
  [SpecificsKeys.besonderheitenuebernahmevonarzt]: 'Übernahme aus arztbesetztem Rettungsmittel',
  [SpecificsKeys.besonderheitenuebergabeanarzt]: 'Übernahme an arztbesetztes Rettungsmittel',
  [SpecificsKeys.besonderheitentransportinkh]: 'Transport ins Krankenhaus',
  [SpecificsKeys.besonderheitentransportmarzt]: 'mit Arzt',
  [SpecificsKeys.besonderheitentransportmrth]: 'mit RTH',
  [SpecificsKeys.besonderheitentransportmsignal]: 'Transport mit Sondersignal',
  [SpecificsKeys.besonderheitentransportkliniknimmtnichtauf]: 'mindestens eine Klinik nimmt nicht auf',
  [SpecificsKeys.besonderheitentransportzwangsbelegung]: 'Zwangsbelegung',
  [SpecificsKeys.besonderheitentransportzwangsunterbringung]: 'Zwangsunterbringung',
  [SpecificsKeys.besonderheitennurbehandlung]: 'nur Untersuchung und Behandlung',
  [SpecificsKeys.besonderheitennurbehandlungvereigerung]: 'Transportverweigerung',
  [SpecificsKeys.besonderheitennurbehandlungpatnichttransportfaehig]: 'Patient nicht transportfähig',
  [SpecificsKeys.besonderheitennurbehandlungtherapieverzicht]: 'Therapieverzicht / -beschränkung bewusst',
  [SpecificsKeys.besonderheitensituationhygiene]: 'Erhöhter Hygieneaufwand',
  [SpecificsKeys.besonderheitensituationpatzugang]: 'erschwerter Pat-Zugang',
  [SpecificsKeys.besonderheitensituationtechrettung]: 'techn. Rettung',
  [SpecificsKeys.besonderheitensituationschwertransport]: 'Schwertransport erforderlich',
  [SpecificsKeys.besonderheitengrossschadenslagelna]: 'LNA am Einsatz',
  [SpecificsKeys.besonderheitengrossschadenslagemanv]: 'MANV',
  [SpecificsKeys.besonderheitengrossschadenslagemehrerepatienten]: 'Behandlung mehrerer Patienten',
};

export const SPECIFICS_RECORD_ELEMENT_KEY = 'besonderheiten';

export type SpecificsRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof SPECIFICS_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          besonderheitenUebergabe?: SingleSelectFieldValueTyped<SpecificsKeys>;
          besonderheitenTransport?: MultiSelectFieldValueTyped<SpecificsKeys>;
          besonderheitenNurBehandlung?: SingleSelectFieldValueTyped<SpecificsKeys>;
          besonderheitenSituation?: MultiSelectFieldValueTyped<SpecificsKeys>;
          besonderheitenGrossschadenslage?: MultiSelectFieldValueTyped<SpecificsKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export function getSpecificsTuple(key: SpecificsKeys): [SpecificsKeys, string] {
  return [key, specificsLabels[key]];
}

export function getSpecificsTuplesFor(keys: SpecificsKeys[]): [SpecificsKeys, string][] {
  return keys.map((k) => {
    return getSpecificsTuple(k);
  });
}
