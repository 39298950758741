import { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import { FormControl } from '@mui/material';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './Other.scss?inline';
import { NodeType } from '../../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import {
  getStorageTuplesFor,
  STORAGE_RECORD_ELEMENT_KEY,
  StorageKeys,
  StorageRecord,
} from '../../../../models/genericElements/treatments/storage';
import {
  EQUIPMENT_RECORD_ELEMENT_KEY,
  EquipmentKeys,
  EquipmentRecord,
  getEquipmentTuplesFor,
} from '../../../../models/genericElements/treatments/equipment';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { InputState } from '../../../../backendModels/general.model';
import { Draft } from 'immer';
import { isGenericRecordDeletable } from '../../../../models/generic';
import _ from 'lodash';

interface OtherProps {
  nodeType: NodeType;
}

const storageParentItems = getStorageTuplesFor([StorageKeys.spezielleLagerung]);
const storageChildItems = getStorageTuplesFor([
  StorageKeys.zervikalstuetze,
  StorageKeys.vakuumschiene,
  StorageKeys.vakuummarratze,
  StorageKeys.schaufeltrage,
  StorageKeys.spineboard,
  StorageKeys.stabileSeitenlage,
  StorageKeys.schockLage,
  StorageKeys.oberkoerperHochlage,
  StorageKeys.flachlagerung,
  StorageKeys.sitzend,
  StorageKeys.waermeerhalt,
  StorageKeys.inkubator,
]);

const equipmentItems = getEquipmentTuplesFor([
  EquipmentKeys.zwoelfKanalEKG,
  EquipmentKeys.spritzenpumpe,
  EquipmentKeys.verband,
  EquipmentKeys.thoraxDrainageLi,
  EquipmentKeys.thoraxDrainageRe,
  EquipmentKeys.thorakotomie,
  EquipmentKeys.ultraschall,
  EquipmentKeys.entbindung,
  EquipmentKeys.tourniquet,
  EquipmentKeys.funkuebermittlung,
  EquipmentKeys.invasiverBlutdruck,
  EquipmentKeys.reposition,
  EquipmentKeys.nadeldekompression,
  EquipmentKeys.magensonde,
  EquipmentKeys.krisenintervention,
  EquipmentKeys.haemostyptikum,
  EquipmentKeys.externerSchrittmacher,
  EquipmentKeys.kardioversion,
  EquipmentKeys.beckenschlinge,
  EquipmentKeys.blasenkathether,
  EquipmentKeys.besondereHygiene,
  EquipmentKeys.thoraxverschlusspflaster,
]);

export default function Other({ nodeType }: OtherProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const storageRecord = findRecordOrDefault('generic', nodeType, STORAGE_RECORD_ELEMENT_KEY) as StorageRecord;

  const equipmentRecord = findRecordOrDefault('generic', nodeType, EQUIPMENT_RECORD_ELEMENT_KEY) as EquipmentRecord;

  const storageParentOptionsChange = (newOptions: StorageKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<StorageRecord>, deleteRecord) => {
        if (newOptions.length > 0 && newOptions.includes(StorageKeys.spezielleLagerung)) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            lagerung: {
              fieldType: 'multiSelect',
              options: newOptions,
            },
          };
        } else {
          deleteRecord();
        }
      },
      STORAGE_RECORD_ELEMENT_KEY,
    );
  };

  const storageChildOptionsChange = (newOptions: StorageKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<StorageRecord>) => {
        draft.inputState = InputState.ENTERED;
        draft.values = {
          ...draft.values,
          lagerung: {
            fieldType: 'multiSelect',
            options: _.union(newOptions, [StorageKeys.spezielleLagerung]),
          },
        };
      },
      STORAGE_RECORD_ELEMENT_KEY,
    );
  };

  const storageOtherChange = (value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<StorageRecord>, deleteRecord) => {
        if (value !== '') {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            lagerung: {
              fieldType: 'multiSelect',
              options: _.union(draft.values?.lagerung?.options ?? [], [StorageKeys.spezielleLagerung]),
            },
            geraeteSonstiges: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.geraeteSonstiges;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      STORAGE_RECORD_ELEMENT_KEY,
    );
  };

  const equipmentOptionsChange = (newOptions: EquipmentKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<EquipmentRecord>, deleteRecord) => {
        if (newOptions.length > 0) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            equipment: {
              fieldType: 'multiSelect',
              options: newOptions,
            },
          };
        } else {
          delete draft.values?.equipment;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      EQUIPMENT_RECORD_ELEMENT_KEY,
    );
  };

  const equipmentOtherChange = (value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<EquipmentRecord>, deleteRecord) => {
        if (value !== '') {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            equipmentSonstiges: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.equipmentSonstiges;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      EQUIPMENT_RECORD_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard cardType={CardId.TreatmentsOther}>
      <div className='measures-other'>
        <div className='row'>
          <div>
            <FormControl className='symptoms-list' fullWidth>
              <CheckboxList
                items={storageParentItems}
                selectedValues={
                  storageRecord.inputState === InputState.ENTERED ? (storageRecord.values.lagerung?.options ?? []) : []
                }
                onValuesChange={storageParentOptionsChange}
              />
              <CheckboxList
                className='indented'
                items={storageChildItems}
                selectedValues={
                  storageRecord.inputState === InputState.ENTERED ? (storageRecord.values.lagerung?.options ?? []) : []
                }
                onValuesChange={storageChildOptionsChange}
              />
            </FormControl>
            <DebouncedTextField
              fullWidth
              type='text'
              label='Sonstige...'
              variant='outlined'
              onDebounceChange={storageOtherChange}
              value={
                storageRecord.inputState === InputState.ENTERED
                  ? (storageRecord.values.geraeteSonstiges?.text ?? '')
                  : ''
              }
              inputProps={{ maxLength: 17 }}
            />
          </div>
          <div>
            <FormControl className='symptoms-list'>
              <CheckboxList
                items={equipmentItems}
                selectedValues={
                  equipmentRecord.inputState === InputState.ENTERED
                    ? (equipmentRecord.values.equipment?.options ?? [])
                    : []
                }
                onValuesChange={equipmentOptionsChange}
              />
            </FormControl>
            <DebouncedTextField
              fullWidth
              type='text'
              label='Sonstiges...'
              variant='outlined'
              onDebounceChange={equipmentOtherChange}
              value={
                equipmentRecord.inputState === InputState.ENTERED
                  ? (equipmentRecord.values.equipmentSonstiges?.text ?? '')
                  : ''
              }
              inputProps={{ maxLength: 30 }}
            />
          </div>
        </div>
      </div>
    </DiviFieldCard>
  );
}
