import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { TextFieldValue } from '../../../backendModels/generic-element.model';
import { MANUAL_EVENT_RECORD_ELEMENT_KEY, ManualEventRecord } from './manualEvent';

export const ARRIVAL_AT_LOCATION_EVENT_RECORD_ELEMENT_KEY = 'diviArrivalAtLocationEvent';

export type ArrivalAtLocationEventRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof ARRIVAL_AT_LOCATION_EVENT_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          arrivalAtLocationTimestamp?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export function arrivalAtLocationEventsToVirtualManualEvents(records: ArrivalAtLocationEventRecord[]) {
  const resultingEvents: ManualEventRecord[] = [];

  for (const record of records) {
    if (record.inputState === InputState.ENTERED && record.values.arrivalAtLocationTimestamp?.text != null) {
      resultingEvents.push({
        type: 'generic',
        elementKey: MANUAL_EVENT_RECORD_ELEMENT_KEY,
        inputState: InputState.ENTERED,
        values: {
          eventName: {
            fieldType: 'text',
            text: 'Ankunft am Einsatzort',
          },
          eventTimestamp: {
            fieldType: 'text',
            text: record.values.arrivalAtLocationTimestamp.text,
          },
        },
      });
    }
  }

  return resultingEvents;
}
