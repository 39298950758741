import { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { NodeType } from '../../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { InputState } from '../../../../backendModels/general.model';
import { Draft } from 'immer';
import {
  MEDICAL_CONDITION_METABOLISM_ELEMENT_KEY,
  MedicalConditionMetabolismRecord,
  getMetabolismTupelsFor,
  MedicalConditionMetabolismKeys,
} from '../../../../models/genericElements/medicalConditions/metabolism';
import { checkParent, uncheckChildren } from '../../../../utils/util';
import { isGenericRecordDeletable, setGenericRecordToNormal } from '../../../../models/generic';

const checkboxes1: [MedicalConditionMetabolismKeys, string][] = getMetabolismTupelsFor([
  MedicalConditionMetabolismKeys.hypoglykaemie,
  MedicalConditionMetabolismKeys.hyperglykaemie,
  MedicalConditionMetabolismKeys.giExsiccose,
  MedicalConditionMetabolismKeys.uraemie,
]);

const checkbox2: [MedicalConditionMetabolismKeys, string][] = getMetabolismTupelsFor([
  MedicalConditionMetabolismKeys.dialyse,
]);

interface MedicalConditionMetabolismProps {
  nodeType: NodeType;
}

export default function MedicalConditionMetabolism({ nodeType }: MedicalConditionMetabolismProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault(
    'generic',
    nodeType,
    MEDICAL_CONDITION_METABOLISM_ELEMENT_KEY,
  ) as MedicalConditionMetabolismRecord;
  const conditions =
    record.inputState === InputState.ENTERED ? (record.values.erkrankungenStoffwechsel?.options ?? []) : [];

  const conditionsChange = (newConditions: MedicalConditionMetabolismKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<MedicalConditionMetabolismRecord>, deleteRecord) => {
        if (newConditions.length > 0) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            erkrankungenStoffwechsel: {
              fieldType: 'multiSelect',
              options: newConditions,
            },
          };
        } else {
          delete draft.values?.erkrankungenStoffwechsel;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      MEDICAL_CONDITION_METABOLISM_ELEMENT_KEY,
    );
  };
  const otherChange = (value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<MedicalConditionMetabolismRecord>, deleteRecord) => {
        if (value !== '') {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            erkrankungenStoffwechselSonstige: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.erkrankungenStoffwechselSonstige;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      MEDICAL_CONDITION_METABOLISM_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard
      cardType={CardId.MedicalConditionMetabolism}
      nodeType={nodeType}
      recordType='generic'
      elementKey={MEDICAL_CONDITION_METABOLISM_ELEMENT_KEY}
      normalStateButtonVisible={false}
      onNormalStateButtonClicked={() =>
        setGenericRecordToNormal(adaptRecord, nodeType, MEDICAL_CONDITION_METABOLISM_ELEMENT_KEY)
      }
    >
      <div className='symptoms-list'>
        <CheckboxList
          items={checkboxes1}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              uncheckChildren(newConditions, MedicalConditionMetabolismKeys.uraemie, [
                MedicalConditionMetabolismKeys.dialyse,
              ]),
            )
          }
        />
        <CheckboxList
          className='indented'
          items={checkbox2}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              checkParent(newConditions, MedicalConditionMetabolismKeys.uraemie, [
                MedicalConditionMetabolismKeys.dialyse,
              ]),
            )
          }
        />
      </div>
      <DebouncedTextField
        fullWidth
        label='Sonstige...'
        value={
          record.inputState === InputState.ENTERED ? (record.values.erkrankungenStoffwechselSonstige?.text ?? '') : ''
        }
        onDebounceChange={otherChange}
        inputProps={{ maxLength: 18 }}
      />
    </DiviFieldCard>
  );
}
