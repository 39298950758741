import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../../generic';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export enum StorageKeys {
  spezielleLagerung = 'SPEZIELLE_LAGERUNG',
  zervikalstuetze = 'ZERVIKALSTUETZE',
  vakuumschiene = 'VAKUUMSCHIENE',
  vakuummarratze = 'VAKUUMMATRATZE',
  schaufeltrage = 'SCHAUFELTRAGE',
  spineboard = 'SPINEBOARD',
  stabileSeitenlage = 'STABILE_SEITENLAGE',
  schockLage = 'SCHOCK_LAGE',
  oberkoerperHochlage = 'OBERKÖRPERHOCHLAGE',
  flachlagerung = 'FLACHLAGERUNG',
  sitzend = 'SITZEND',
  waermeerhalt = 'WAERMEERHALT',
  inkubator = 'INKUBATOR',
}

const storageLabels: Record<StorageKeys, string> = {
  [StorageKeys.spezielleLagerung]: 'Spezielle Lagerung',
  [StorageKeys.zervikalstuetze]: 'Zervikalstütze',
  [StorageKeys.vakuumschiene]: 'Vakuumschiene',
  [StorageKeys.vakuummarratze]: 'Vakuummatratze',
  [StorageKeys.schaufeltrage]: 'Schaufeltrage',
  [StorageKeys.spineboard]: 'Spineboard',
  [StorageKeys.stabileSeitenlage]: 'stabile Seitenlage',
  [StorageKeys.schockLage]: 'Schocklagerung',
  [StorageKeys.oberkoerperHochlage]: 'Oberkörper Hochlagerung',
  [StorageKeys.flachlagerung]: 'Flachlagerung',
  [StorageKeys.sitzend]: 'Sitzend',
  [StorageKeys.waermeerhalt]: 'Wärmeerhalt',
  [StorageKeys.inkubator]: 'Inkubator',
};

export const STORAGE_RECORD_ELEMENT_KEY = 'lagerung';

export type StorageRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof STORAGE_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          lagerung?: MultiSelectFieldValueTyped<StorageKeys>;
          geraeteSonstiges?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export function getStorageTuple(key: StorageKeys): [StorageKeys, string] {
  return [key, storageLabels[key]];
}

export function getStorageTuplesFor(keys: StorageKeys[]): [StorageKeys, string][] {
  return keys.map((k) => {
    return getStorageTuple(k);
  });
}
