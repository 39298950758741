import { useCSS } from '../../provider/CSSProvider';
import style from './SideBar.scss?inline';

import PatientRecord from './PatientRecord/PatientRecord';
import { Events } from './Events/Events';
import { Divider } from '@mui/material';

function SideBar() {
  useCSS(style);

  return (
    <div id='SideBar'>
      <PatientRecord />
      <Divider variant='fullWidth' />
      <Events />
    </div>
  );
}

export default SideBar;
