import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../../generic';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export enum MedicalConditionBreathingKeys {
  asthma = 'ASTHMA',
  statusAsthmaticus = 'STATUS_ASTHMATICUS',
  copd = 'COPD',
  copdHeimbeatmung = 'COPD_HEIMBEATMUNG',
  copdHeimsauerstoff = 'COPD_HEIMSAUERSTOFF',
  pneumonie = 'PNEUMONIE',
  hyperventilation = 'HYPERVENTILATION',
  aspiration = 'ASPIRATION',
  haemoptysis = 'HAEMOPTYSIS',
}

export const MEDICAL_CONDITION_BREATHING_ELEMENT_KEY = 'erkrankungenAtmung';

export type MedicalConditionBreathingRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof MEDICAL_CONDITION_BREATHING_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          erkrankungenAtmung?: MultiSelectFieldValueTyped<MedicalConditionBreathingKeys>;
          erkrankungenAtmungSonstige?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
    | {
        readonly inputState: InputState.NORMAL;
        readonly values: null;
      }
  );

const breathingLabels: Record<MedicalConditionBreathingKeys, string> = {
  [MedicalConditionBreathingKeys.asthma]: 'Asthma (Anfall)',
  [MedicalConditionBreathingKeys.statusAsthmaticus]: 'Status asthmaticus',
  [MedicalConditionBreathingKeys.copd]: 'COPD (ggf. Exazerbation)',
  [MedicalConditionBreathingKeys.copdHeimbeatmung]: 'Heimbeatmung',
  [MedicalConditionBreathingKeys.copdHeimsauerstoff]: 'Heimsauerstoff',
  [MedicalConditionBreathingKeys.pneumonie]: 'Pneumonie, Bronchitis',
  [MedicalConditionBreathingKeys.hyperventilation]: 'Hyperventilationssyndrom',
  [MedicalConditionBreathingKeys.aspiration]: 'Aspiration',
  [MedicalConditionBreathingKeys.haemoptysis]: 'Haemoptysen',
};

export function getBreathingTupel(key: MedicalConditionBreathingKeys): [MedicalConditionBreathingKeys, string] {
  return [key, breathingLabels[key]];
}

export function getBreathingTupelsFor(
  keys: MedicalConditionBreathingKeys[],
): [MedicalConditionBreathingKeys, string][] {
  return keys.map((k) => {
    return getBreathingTupel(k);
  });
}
