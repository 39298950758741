import { ChangeEvent } from 'react';
import { Checkbox, FormControlLabel, FormControlLabelProps, FormGroup, FormGroupProps } from '@mui/material';

export type CheckboxLabel = string;

interface CheckboxListProps<T> extends FormGroupProps {
  /**
   * Items consisting of tuples: (value, CheckboxLabel).
   */
  items: [T, CheckboxLabel][];
  /**
   * Items that are disabled.
   */
  disabledItems?: T[];
  /**
   * Invoked when a checkbox is clicked.
   * @param value List of selected checkbox values.
   */
  onValuesChange?: (values: T[]) => void;
  /**
   * Selected values.
   */
  selectedValues: T[];
  /**
   * Props for the form control label.
   */
  formControlLabelProps?: Omit<FormControlLabelProps, 'key' | 'checked' | 'control' | 'label'>;
}

export default function CheckboxList<T>(props: CheckboxListProps<T>) {
  const { items, onValuesChange, disabledItems, selectedValues, formControlLabelProps, ...formGroupProps } = props;

  const checkBoxChange = (event: ChangeEvent<HTMLInputElement>, item: T) => {
    let value: T[];
    if (event.target.checked) {
      value = [...selectedValues, item];
    } else {
      value = selectedValues.filter((i) => i !== item);
    }
    if (onValuesChange) {
      onValuesChange(value);
    }
  };

  return (
    <FormGroup {...formGroupProps}>
      {items.map(([value, label]) => (
        <FormControlLabel
          {...formControlLabelProps}
          key={label}
          label={label}
          checked={selectedValues.includes(value)}
          control={<Checkbox onChange={(event) => checkBoxChange(event, value)} />}
          disabled={disabledItems?.includes(value)}
        />
      ))}
    </FormGroup>
  );
}
