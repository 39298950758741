import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { MultiSelectFieldValueTyped } from '../../generic';
import { InputState } from '../../../backendModels/general.model';

export enum ParticipantsPropertyKeys {
  participants = 'participants',
}

export const OPERATIONAL_DATA_PARTICIPANTS_RECORD_ELEMENT_KEY = 'diviParticipants';

export type OperationalDataParticipantsRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof OPERATIONAL_DATA_PARTICIPANTS_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          participants?: MultiSelectFieldValueTyped<ParticipantsKeys>;
          touched?: MultiSelectFieldValueTyped<ParticipantsPropertyKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export enum ParticipantsKeys {
  nef = 'NEF',
  selbstfahrer = 'Selbstfahrer',
  naw = 'NAW',
  rtw = 'RTW',
  ktw = 'KTW',
  rth = 'RTH',
  ith = 'ITH',
  sonstige = 'Sonstige',
  bergrettung = 'Bergrettung',
  wasserrettung = 'Wasserrettung',
  teleNA = 'TNA',
}

const ParticipantsNameTuples: Record<string, string> = {
  [ParticipantsKeys.nef]: 'NEF',
  [ParticipantsKeys.selbstfahrer]: 'Selbstfahrer',
  [ParticipantsKeys.naw]: 'NAW',
  [ParticipantsKeys.rtw]: 'RTW',
  [ParticipantsKeys.ktw]: 'KTW',
  [ParticipantsKeys.rth]: 'RTH',
  [ParticipantsKeys.ith]: 'ITH',
  [ParticipantsKeys.sonstige]: 'Sonstige',
  [ParticipantsKeys.bergrettung]: 'Bergrettung',
  [ParticipantsKeys.wasserrettung]: 'Wasserrettung',
  [ParticipantsKeys.teleNA]: 'TeleNA',
};

export function getParticipantsTuple(key: ParticipantsKeys): [string, string] {
  return [key, ParticipantsNameTuples[key]];
}

export function getParticipantsTuplesFor(keys: ParticipantsKeys[]): [string, string][] {
  return keys.map((k) => {
    return getParticipantsTuple(k);
  });
}

const participantKeysTranslation = new Map<string, ParticipantsKeys>([
  [ParticipantsKeys.nef.toLowerCase(), ParticipantsKeys.nef],
  [ParticipantsKeys.selbstfahrer.toLowerCase(), ParticipantsKeys.selbstfahrer],
  [ParticipantsKeys.naw.toLowerCase(), ParticipantsKeys.naw],
  [ParticipantsKeys.rtw.toLowerCase(), ParticipantsKeys.rtw],
  [ParticipantsKeys.ktw.toLowerCase(), ParticipantsKeys.ktw],
  [ParticipantsKeys.rth.toLowerCase(), ParticipantsKeys.rth],
  [ParticipantsKeys.ith.toLowerCase(), ParticipantsKeys.ith],
  [ParticipantsKeys.sonstige.toLowerCase(), ParticipantsKeys.sonstige],
  [ParticipantsKeys.bergrettung.toLowerCase(), ParticipantsKeys.bergrettung],
  [ParticipantsKeys.wasserrettung.toLowerCase(), ParticipantsKeys.wasserrettung],
  [ParticipantsKeys.teleNA.toLowerCase(), ParticipantsKeys.teleNA],
]);

export function mapStringToParticpantKey(value: string | undefined | null) {
  if (value == null) {
    return undefined;
  }
  return participantKeysTranslation.get(value.toLowerCase());
}
