import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { TextFieldValue } from '../../backendModels/generic-element.model';

export const DISABILITY_EXTENDED_ELEMENT_KEY = 'disabilityErweitert';

export type DisabilityExtendedRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof DISABILITY_EXTENDED_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          disabilitySonstige?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
    | {
        readonly inputState: InputState.NORMAL;
        readonly values: null;
      }
  );
