import { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import ScoreButtonList from '../../../customs/scoreButtonList/ScoreButtonList';
import {
  getLimbMovementTuplesFor,
  LIMB_MOVEMENTS_ELEMENT_KEY,
  LimbMovementsRecord,
} from '../../../../models/genericElements/limbMovements';
import { CardId } from '../../../../models/diviCard';
import { NodeType } from '../../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { InputState } from '../../../../backendModels/general.model';
import { Draft } from 'immer';

interface LimbMovementsProps {
  nodeType: NodeType;
}

const limbMovementsItems = getLimbMovementTuplesFor([0, 1, 2]);

export default function LimbMovements({ nodeType }: LimbMovementsProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, LIMB_MOVEMENTS_ELEMENT_KEY) as LimbMovementsRecord;

  const selectedLimbMovement =
    record.inputState === InputState.ENTERED && record.values?.extremitaetenbewegungReArm?.number
      ? record.values?.extremitaetenbewegungReArm?.number - 1
      : null;
  const handleLimbMovementsChange = (index: number) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<LimbMovementsRecord>) => {
        draft.inputState = InputState.ENTERED;
        draft.values = {
          ...draft.values,
          extremitaetenbewegungReArm: {
            fieldType: 'numeric',
            number: index + 1,
          },
        };
      },
      LIMB_MOVEMENTS_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard
      cardType={CardId.LimbMovements}
      onNormalStateButtonClicked={() => handleLimbMovementsChange(0)}
      nodeType={nodeType}
      recordType='generic'
      elementKey={LIMB_MOVEMENTS_ELEMENT_KEY}
    >
      <div className='row'>
        <ScoreButtonList
          reverse={false}
          listOfEntries={limbMovementsItems}
          selectedValue={selectedLimbMovement}
          onChange={handleLimbMovementsChange}
        />
      </div>
    </DiviFieldCard>
  );
}
