import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { DiviNavigationIds } from '../../../models/diviProgress';
import { NodeType } from '../../../backendModels/report.model';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import { getPageTitle, PageType } from '../../../models/diviPage';
import HandoverTo from './HandoverToDetails/HandoverTo';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { HANDOVER_TO_RECORD_ELEMENT_KEY } from '../../../models/genericElements/handoverTo';

export function HandoverToArea() {
  const recordTypesOfPage = [] as (keyof BaseNodeRecordValueTypeMap)[];
  const elementKeysOfPage = [HANDOVER_TO_RECORD_ELEMENT_KEY];
  const nodeTypeOfPage = NodeType.HANDOVER;

  const pageHeader = <PageHeader heading={getPageTitle(PageType.HandoverTo)} />;

  const pageFooter = <PageFooter pageType={PageType.HandoverTo} diviId={DiviNavigationIds.HandoverTo} />;

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      elementKeys={elementKeysOfPage}
      nodeType={nodeTypeOfPage}
      diviId={DiviNavigationIds.HandoverTo}
    >
      <div className='wireframe-container-handover-to'>
        <HandoverTo nodeType={nodeTypeOfPage} />
      </div>
    </DiviPage>
  );
}
