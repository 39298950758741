import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export const MANUAL_EVENT_RECORD_ELEMENT_KEY = 'diviManualEvent';

export type ManualEventRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof MANUAL_EVENT_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          eventName?: TextFieldValue;
          eventTimestamp?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );
