import { Route, Routes } from 'react-router-dom';
import { Neurology } from './Neurology/Neurology';

import { PageType, getPageRoute } from '../../models/diviPage';
import { InitialFindings } from './InitialFindings/InitialFindings';
import { PatientMasterData } from './PatientMasterData/PatientMasterData';
import { Anamnese } from './Anamnese/Anamnese';
import { OperationalData } from './OperationalData/OperationalData';
import { MedicalConditions } from './MedicalConditions/MedicalConditions';
import { Injuries } from './Injuries/Injuries';
import { InitialDiagnosis } from './InitialDiagnosis/InitialDiagnosis';
import { MedicationArea } from './Medication/MedicationArea';
import { Remarks } from './Remarks/Remarks';
import { Handover } from './Handover/Handover';
import { HandoverToArea } from './HandoverTo/HandoverToArea';
import { ReanimationDeath } from './ReanimationDeath/ReanimationDeath';
import { Treatments } from './Treatments/Treatments';
import { SpecificsArea } from './Specifics/SpecificsArea';
import { Trends } from './Trends/Trends';
import { Acronym } from './Acronym/Acronym';
import { SAMPLERPlusS } from './SAMPLER+S/SAMPLERPlusS';

export function PageRouter() {
  return (
    <Routes>
      <Route path={getPageRoute(PageType.PatientMasterData)} element={<PatientMasterData />} />
      <Route path={getPageRoute(PageType.OperationalData)} element={<OperationalData />} />
      <Route path={getPageRoute(PageType.Anamnese)} element={<Anamnese />} />
      <Route path={getPageRoute(PageType.Neurology)} element={<Neurology />} />
      <Route path={getPageRoute(PageType.DataInitial)} element={<InitialFindings />} />
      <Route path={getPageRoute(PageType.MedicalConditions)} element={<MedicalConditions />} />
      <Route path={getPageRoute(PageType.Injuries)} element={<Injuries />} />
      <Route path={getPageRoute(PageType.DiagnosisInitial)} element={<InitialDiagnosis />} />
      <Route path={getPageRoute(PageType.Course)} element={<Trends />} />
      <Route path={getPageRoute(PageType.Medication)} element={<MedicationArea />} />
      <Route path={getPageRoute(PageType.Measures)} element={<Treatments />} />
      <Route path={getPageRoute(PageType.HandoverTo)} element={<HandoverToArea />} />
      <Route path={getPageRoute(PageType.Remarks)} element={<Remarks />} />
      <Route path={getPageRoute(PageType.Handover)} element={<Handover />} />
      <Route path={getPageRoute(PageType.Specifics)} element={<SpecificsArea />} />
      <Route path={getPageRoute(PageType.ReanimationDeath)} element={<ReanimationDeath />} />
      <Route path={getPageRoute(PageType.Acronym) + '/*'} element={<Acronym />} />
      <Route path={getPageRoute(PageType.SAMPLERPlusS)} element={<SAMPLERPlusS />} />
    </Routes>
  );
}
