import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { MultiSelectFieldValueTyped, SingleSelectFieldValueTyped } from '../../generic';
import { InputState } from '../../../backendModels/general.model';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export enum TransportLocationPropertyKeys {
  transportLocation = 'transportLocation',
  transportLocationPreregistration = 'transportLocationPreregistration',
  transportLocationType = 'transportLocationType',
}

export const OPERATIONAL_DATA_TRANSPORT_RECORD_ELEMENT_KEY = 'diviTransportDestination';

export type OperationalDataTransportLocationRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof OPERATIONAL_DATA_TRANSPORT_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          transportLocation?: TextFieldValue;
          transportLocationPreregistration?: MultiSelectFieldValueTyped<PreregistrationKeys>;
          transportLocationType?: SingleSelectFieldValueTyped<TransportLocationTypeKeys>;
          touched?: MultiSelectFieldValueTyped<TransportLocationPropertyKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export enum PreregistrationKeys {
  preregistration = 'PREREGISTRATION',
}

export enum TransportLocationTypeKeys {
  strokeunit = 'STROKE_UNIT',
  cardiaccatheter = 'CARDIAC_CATHETER',
  traumacenter = 'TRAUMA_CENTER',
}

const preregistrationLabels: Record<PreregistrationKeys, string> = {
  [PreregistrationKeys.preregistration]: 'Voranmeldung',
};

export function getPreregistrationTupel(key: PreregistrationKeys): [PreregistrationKeys, string] {
  return [key, preregistrationLabels[key]];
}

const transportLocationTypeLabels: Record<TransportLocationTypeKeys, string> = {
  [TransportLocationTypeKeys.strokeunit]: 'Stroke Unit',
  [TransportLocationTypeKeys.cardiaccatheter]: 'Herzkatheter',
  [TransportLocationTypeKeys.traumacenter]: 'Traumazentrum',
};

export function getTransportLocationTypeTupel(key: TransportLocationTypeKeys): [TransportLocationTypeKeys, string] {
  return [key, transportLocationTypeLabels[key]];
}

export function getTransportLocationTypeTupelsFor(
  keys: TransportLocationTypeKeys[],
): [TransportLocationTypeKeys, string][] {
  return keys.map((k) => {
    return getTransportLocationTypeTupel(k);
  });
}
