import { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import {
  MEDICAL_CONDITION_ZNS_RECORD_ELEMENT_KEY,
  MedicalConditionZNSRecord,
  getZNSTupelsFor,
  MedicalConditionZNSKeys,
} from '../../../../models/genericElements/medicalConditions/zns';
import { NodeType } from '../../../../backendModels/report.model';
import { InputState } from '../../../../backendModels/general.model';
import { Draft } from 'immer';
import { checkParent, uncheckChildren } from '../../../../utils/util';
import { isGenericRecordDeletable, setGenericRecordToNormal } from '../../../../models/generic';

const checkbox1: [MedicalConditionZNSKeys, string][] = getZNSTupelsFor([MedicalConditionZNSKeys.stroke]);

const checkbox2: [MedicalConditionZNSKeys, string][] = getZNSTupelsFor([MedicalConditionZNSKeys.strokeInLyse]);

const checkbox3: [MedicalConditionZNSKeys, string][] = getZNSTupelsFor([MedicalConditionZNSKeys.krampfanfall]);

const checkbox4: [MedicalConditionZNSKeys, string][] = getZNSTupelsFor([MedicalConditionZNSKeys.statusEpilepticus]);

const checkboxes5: [MedicalConditionZNSKeys, string][] = getZNSTupelsFor([
  MedicalConditionZNSKeys.meningismusEncephalitis,
  MedicalConditionZNSKeys.syncope,
]);

interface MedicalConditionsZNSProps {
  nodeType: NodeType;
}

export default function MedicalConditionZNS({ nodeType }: MedicalConditionsZNSProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault(
    'generic',
    nodeType,
    MEDICAL_CONDITION_ZNS_RECORD_ELEMENT_KEY,
  ) as MedicalConditionZNSRecord;
  const conditions = record.inputState === InputState.ENTERED ? (record.values.erkrankungenZns?.options ?? []) : [];

  const conditionsChange = (newConditions: MedicalConditionZNSKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<MedicalConditionZNSRecord>, deleteRecord) => {
        if (newConditions.length > 0) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            erkrankungenZns: {
              fieldType: 'multiSelect',
              options: newConditions,
            },
          };
        } else {
          delete draft.values?.erkrankungenZns;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      MEDICAL_CONDITION_ZNS_RECORD_ELEMENT_KEY,
    );
  };

  const otherChange = (value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<MedicalConditionZNSRecord>, deleteRecord) => {
        if (value !== '') {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            erkrankungenZnsSonstige: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.erkrankungenZnsSonstige;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      MEDICAL_CONDITION_ZNS_RECORD_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard
      cardType={CardId.MedicalConditionZNS}
      nodeType={nodeType}
      recordType='generic'
      elementKey={MEDICAL_CONDITION_ZNS_RECORD_ELEMENT_KEY}
      normalStateButtonVisible={false}
      onNormalStateButtonClicked={() =>
        setGenericRecordToNormal(adaptRecord, nodeType, MEDICAL_CONDITION_ZNS_RECORD_ELEMENT_KEY)
      }
    >
      <div className='symptoms-list'>
        <CheckboxList
          items={checkbox1}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              uncheckChildren(newConditions, MedicalConditionZNSKeys.stroke, [MedicalConditionZNSKeys.strokeInLyse]),
            )
          }
        />
        <CheckboxList
          className='indented'
          items={checkbox2}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              checkParent(newConditions, MedicalConditionZNSKeys.stroke, [MedicalConditionZNSKeys.strokeInLyse]),
            )
          }
        />
        <CheckboxList
          items={checkbox3}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              uncheckChildren(newConditions, MedicalConditionZNSKeys.krampfanfall, [
                MedicalConditionZNSKeys.statusEpilepticus,
              ]),
            )
          }
        />
        <CheckboxList
          className='indented'
          items={checkbox4}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              checkParent(newConditions, MedicalConditionZNSKeys.krampfanfall, [
                MedicalConditionZNSKeys.statusEpilepticus,
              ]),
            )
          }
        />
        <CheckboxList items={checkboxes5} selectedValues={conditions} onValuesChange={conditionsChange} />
      </div>
      <DebouncedTextField
        fullWidth
        label='Sonstige...'
        value={record.inputState === InputState.ENTERED ? (record.values.erkrankungenZnsSonstige?.text ?? '') : ''}
        onDebounceChange={otherChange}
        inputProps={{ maxLength: 18 }}
      />
    </DiviFieldCard>
  );
}
