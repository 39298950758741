import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { NumericFieldValue } from '../../backendModels/generic-element.model';

export const LIMB_MOVEMENTS_ELEMENT_KEY = 'extremitaetenbewegung';

export type LimbMovementsRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof LIMB_MOVEMENTS_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          extremitaetenbewegungReArm?: NumericFieldValue;
          extremitaetenbewegungLiArm?: NumericFieldValue;
          extremitaetenbewegungReBein?: NumericFieldValue;
          extremitaetenbewegungLiBein?: NumericFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
    | {
        readonly inputState: InputState.NORMAL;
        readonly values: null;
      }
  );

export const limbMovementLabels: Record<number, string> = {
  [0]: 'normal',
  [1]: 'leicht vermindert',
  [2]: 'stark vermindert',
};

export function getLimbMovementTuple(key: number): [number, string] {
  return [key, limbMovementLabels[key]];
}

export function getLimbMovementTuplesFor(keys: number[]): [number, string][] {
  return keys.map((k) => {
    return getLimbMovementTuple(k);
  });
}
