import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { NodeType } from '../../../backendModels/report.model';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { useCSS } from '../../../provider/CSSProvider';
import style from '././Trends.scss?inline';
import TrendsHistory from './TrendsHistory/TrendsHistory';
import TrendsOxygenation from './Oxygenation/TrendsOxygenation';

export function Trends() {
  useCSS(style);

  const recordTypesOfPage = ['trends', 'oxygen', 'vitalParameters'] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.GENERIC;

  const pageHeader = <PageHeader heading={getPageTitle(PageType.Course)} noClearAllButton />;

  const pageFooter = <PageFooter pageType={PageType.Course} diviId={DiviNavigationIds.Course} />;

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      diviId={DiviNavigationIds.Course}
    >
      <div className='wireframe-container-course'>
        <TrendsHistory nodeType={nodeTypeOfPage} />
        <TrendsOxygenation nodeType={nodeTypeOfPage} />
      </div>
    </DiviPage>
  );
}
