import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../../generic';
import { SymptomStartKeys } from './symptomStart';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export enum SymptomOnsetPropertyKeys {
  symptomOnsetOptions = 'symptomOnsetOptions',
  symptomOnsetTimestamp = 'symptomOnsetTimestamp',
}

export const SYMPTOM_ONSET_RECORD_ELEMENT_KEY = 'diviSymptomOnset';

export type SymptomOnsetRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof SYMPTOM_ONSET_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          symptomOnsetOptions?: MultiSelectFieldValueTyped<SymptomStartKeys>;
          symptomOnsetTimestamp?: TextFieldValue;
          touched?: MultiSelectFieldValueTyped<SymptomOnsetPropertyKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );
