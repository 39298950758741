import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { TextFieldValue } from '../../backendModels/generic-element.model';
import { MultiSelectFieldValueTyped } from '../generic';

export enum PreInterventionKeys {
  vorabinterventionmassnahmen = 'VORABINTERVENTION_MASSNAHMEN',
  vorabinterventionmedikamente = 'VORABINTERVENTION_MEDIKAMENTE',
}

export const PRE_INTERVENTION_RECORD_ELEMENT_KEY = 'vorabIntervention';

export type PreInterventionRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof PRE_INTERVENTION_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          vorabInterventionTyp?: MultiSelectFieldValueTyped<PreInterventionKeys>;
          vorabinterventionBeschreibung?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export const preInterventionLabels: Record<PreInterventionKeys, string> = {
  [PreInterventionKeys.vorabinterventionmassnahmen]: 'Maßnahmen',
  [PreInterventionKeys.vorabinterventionmedikamente]: 'Medikamente',
};

export function getPreInterventionTuple(key: PreInterventionKeys): [PreInterventionKeys, string] {
  return [key, preInterventionLabels[key]];
}

export function getPreInterventionTuplesFor(keys: PreInterventionKeys[]): [PreInterventionKeys, string][] {
  return keys.map((k) => {
    return getPreInterventionTuple(k);
  });
}
