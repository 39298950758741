import Dialog from '@mui/material/Dialog/Dialog';

import globalStyle from '../../../global.scss?inline';
import style from './ConfirmationDialog.scss?inline';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useCSS } from '../../../provider/CSSProvider';
import WarningIcon from '@mui/icons-material/Warning';

interface ConfirmationProps {
  dialogVisible: { open: boolean; setOpen: (b: boolean) => void };
  onOk: () => void;
  onCancel: () => void;
  headline: string;
  text: string;
}

export default function ConfirmationDialog(props: ConfirmationProps) {
  useCSS(style);
  useCSS(globalStyle);

  const { dialogVisible, onOk, onCancel } = props;

  return (
    <Dialog open={dialogVisible.open} onClose={onCancel} className='confirmation-dialog' closeAfterTransition={false}>
      <DialogTitle>{props.headline}</DialogTitle>
      <DialogContent>
        <div className='content row'>
          <WarningIcon />
          <Typography variant='body2'>{props.text}</Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button className='cancel-button' onClick={onCancel} variant='outlined'>
          Abbrechen
        </Button>
        <Button type='submit' onClick={onOk} variant='contained'>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
