import { useContext, useEffect } from 'react';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { NodeType } from '../../../backendModels/report.model';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import PatientData from './PatientData/PatientData';
import style from './PatientMasterData.scss?inline';
import { useCSS } from '../../../provider/CSSProvider';
import Age from './Age/Age';
import Gender from './Gender/Gender';
import Bmi from './Bmi/Bmi';
import Mission from './Mission/Mission';
import { Typography } from '@mui/material';
import {
  EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ExtendedPatientDataRecord,
} from '../../../models/genericElements/extendedPatientData';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import { Draft } from 'immer';
import { InputState } from '../../../backendModels/general.model';
import { isGenericRecordDeletable } from '../../../models/generic';
import { CONTROL_CENTER_RECORD_ELEMENT_KEY } from '../../../models/genericElements/controlCenter';
import {
  PATIENT_DATA_RECORD_ELEMENT_KEY,
  PatientDataRecord,
  PatientPropertyKeys,
} from '../../../models/genericElements/patientData';
import {
  handleUntouchedNumericFieldUpdates,
  handleUntouchedSingleSelectFieldUpdates,
  handleUntouchedTextFieldUpdates,
} from '../../../utils/recordWithTouched';
import { useTriggerPatientChangeFromRecords } from '../../../models/patientChangeTrigger';

export interface HandleChangedPatientProperty {
  <T extends PatientPropertyKeys>(
    property: T,
    value?: (ExtendedPatientDataRecord & { inputState: InputState.ENTERED })['values'][T],
  ): void;
}

export function PatientMasterData() {
  useCSS(style);
  const recordTypesOfPage = [] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.METADATA;
  const elementKeysOfPage = [
    PATIENT_DATA_RECORD_ELEMENT_KEY,
    EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
    CONTROL_CENTER_RECORD_ELEMENT_KEY,
  ];

  const { findNodes, adaptRecord, patient } = useContext(ReportsAPIContext);

  useEffect(() => {
    if (patient != null && findNodes(nodeTypeOfPage).length > 0) {
      adaptRecord(
        'generic',
        nodeTypeOfPage,
        (draft: Draft<PatientDataRecord>, deleteRecord) => {
          draft.inputState = InputState.ENTERED;

          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.patientId, patient.patientId);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.name, patient.name);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.surname, patient.surname);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.birthday, patient.birthday);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.street, patient.street);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.postalCode, patient.postalCode);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.city, patient.city);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.insuranceNumber, patient.insuranceNumber);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.insuranceName, patient.insuranceName);
          handleUntouchedTextFieldUpdates(draft, PatientPropertyKeys.policyNumber, patient.policyNumber);
          handleUntouchedNumericFieldUpdates(draft, PatientPropertyKeys.age, patient.age);
          handleUntouchedSingleSelectFieldUpdates(draft, PatientPropertyKeys.gender, patient.gender);

          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        },
        PATIENT_DATA_RECORD_ELEMENT_KEY,
      );
    }
  }, [patient, adaptRecord, findNodes, nodeTypeOfPage]);

  const pageHeader = <PageHeader heading={getPageTitle(PageType.PatientMasterData)} noClearAllButton />;

  const pageFooter = (
    <PageFooter
      noBackButton
      pageType={PageType.PatientMasterData}
      diviId={DiviNavigationIds.Pat}
      elementKeys={elementKeysOfPage}
    />
  );

  useTriggerPatientChangeFromRecords();

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      diviId={DiviNavigationIds.Pat}
      elementKeys={elementKeysOfPage}
    >
      <div className='legend row'>
        <div className='indicator'></div>
        <Typography variant='subtitle1'>Automatisch übertragene Daten</Typography>
      </div>
      <div className='patient-master-data-wireframe'>
        <div className='patient-data'>
          <PatientData nodeType={nodeTypeOfPage} />
        </div>
        <Gender nodeType={nodeTypeOfPage} />
        <Bmi nodeType={nodeTypeOfPage} />
        <Age nodeType={nodeTypeOfPage} />
        <div className='mission'>
          <Mission nodeType={nodeTypeOfPage} />
        </div>
      </div>
    </DiviPage>
  );
}
