import { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import style from './PatientData.scss?inline';
import globalstyle from '../../../../global.scss?inline';
import { useCSS } from '../../../../provider/CSSProvider';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { useFreshCallback } from '../../../../utils/hooks';
import { Draft } from 'immer';
import {
  BirthDateBackendFormat,
  BirthDateUiFormat,
  EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ExtendedPatientDataRecord,
  getPseudonymTuplesFor,
  PseudonymKeys,
} from '../../../../models/genericElements/extendedPatientData';
import { InputState } from '../../../../backendModels/general.model';
import { NodeType } from '../../../../backendModels/report.model';
import { isGenericRecordDeletable } from '../../../../models/generic';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {
  PATIENT_DATA_RECORD_ELEMENT_KEY,
  PatientDataRecord,
  PatientPropertyKeys,
} from '../../../../models/genericElements/patientData';
import { changePropertyAndMarkTouched, getTouchedClassName } from '../../../../utils/recordWithTouched';

interface PatientDataProps {
  nodeType: NodeType;
}

export default function PatientData({ nodeType }: PatientDataProps) {
  useCSS(style);
  useCSS(globalstyle);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault('generic', nodeType, PATIENT_DATA_RECORD_ELEMENT_KEY) as PatientDataRecord;

  const extendedRecord = findRecordOrDefault(
    'generic',
    nodeType,
    EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ) as ExtendedPatientDataRecord;

  const birthDate =
    record.values?.birthday?.text != null ? dayjs(record.values?.birthday?.text, BirthDateBackendFormat) : null;

  const handleBirthDateChange = useFreshCallback((value: dayjs.Dayjs | null) => {
    const newValue = value != null && value?.isValid() ? value.format(BirthDateBackendFormat) : '';
    changePropertyAndMarkTouched(
      adaptRecord,
      nodeType,
      PATIENT_DATA_RECORD_ELEMENT_KEY,
      PatientPropertyKeys.birthday,
      newValue !== '' ? { text: newValue, fieldType: 'text' } : undefined,
    );
  });

  const handlePseoudonymChange = useFreshCallback((value: PseudonymKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ExtendedPatientDataRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value.length > 0) {
          draft.values = {
            ...draft.values,
            pseudonym: {
              fieldType: 'multiSelect',
              options: value,
            },
          };
        } else {
          delete draft.values?.pseudonym;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
    );
  });

  function handleChangedPatientProperty(field: PatientPropertyKeys, value: string) {
    changePropertyAndMarkTouched(
      adaptRecord,
      nodeType,
      PATIENT_DATA_RECORD_ELEMENT_KEY,
      field,
      value !== '' ? { fieldType: 'text', text: value } : undefined,
    );
  }

  return (
    <DiviFieldCard cardType={CardId.PatientData}>
      <div className='patient-data-wireframe'>
        <DebouncedTextField
          className={getTouchedClassName(record, PatientPropertyKeys.surname)}
          label='Name'
          autoComplete='off'
          value={record.values?.surname?.text ?? ''}
          onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.surname, value)}
        />
        <DebouncedTextField
          className={getTouchedClassName(record, PatientPropertyKeys.name)}
          label='Vorname'
          autoComplete='off'
          value={record.values?.name?.text ?? ''}
          onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.name, value)}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateField
            fullWidth
            className={`no-margin-top ${getTouchedClassName(record, PatientPropertyKeys.birthday)}`}
            label='Geburtsdatum'
            value={birthDate}
            format={BirthDateUiFormat}
            onChange={handleBirthDateChange}
          />
        </LocalizationProvider>

        <CheckboxList
          className='symptoms-list'
          items={getPseudonymTuplesFor([PseudonymKeys.pseudonym])}
          selectedValues={
            extendedRecord.inputState === InputState.ENTERED
              ? ((extendedRecord.values.pseudonym?.options as PseudonymKeys[]) ?? [])
              : []
          }
          onValuesChange={handlePseoudonymChange}
        />
        <DebouncedTextField
          className={getTouchedClassName(record, PatientPropertyKeys.street)}
          label='Straße'
          value={record.values?.street?.text ?? ''}
          onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.street, value)}
        />
        <div className='row patient-data-location'>
          <DebouncedTextField
            className={getTouchedClassName(record, PatientPropertyKeys.postalCode)}
            fullWidth
            label='PLZ'
            value={record.values?.postalCode?.text ?? ''}
            inputProps={{ min: 1, max: 99999, step: 1, maxLength: 5 }}
            type='number'
            onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.postalCode, value)}
          />
          <DebouncedTextField
            className={`patient-data-city ${getTouchedClassName(record, PatientPropertyKeys.city)}`}
            fullWidth
            label='Ort'
            value={record.values?.city?.text ?? ''}
            onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.city, value)}
          />
        </div>
        <div className='row insurance-data-location'>
          <DebouncedTextField
            className={getTouchedClassName(record, PatientPropertyKeys.insuranceName)}
            label='Kasse'
            value={record.values?.insuranceName?.text ?? ''}
            onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.insuranceName, value)}
          />
          <DebouncedTextField
            className={getTouchedClassName(record, PatientPropertyKeys.insuranceNumber)}
            label='Kasse / Nr.'
            value={record.values?.insuranceNumber?.text ?? ''}
            onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.insuranceNumber, value)}
          />
        </div>

        <DebouncedTextField
          className={getTouchedClassName(record, PatientPropertyKeys.policyNumber)}
          label='Versicherungs Nr.'
          value={record.values?.policyNumber?.text ?? ''}
          onDebounceChange={(value) => handleChangedPatientProperty(PatientPropertyKeys.policyNumber, value)}
        />
      </div>
    </DiviFieldCard>
  );
}
