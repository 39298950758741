import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped, SingleSelectFieldValueTyped } from '../../generic';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export enum ReanimationCompressionKeys {
  cardiacMassage = 'REANIMATION_KOMPRESSIONEN_HDM',
}

export enum ReanimationFeedbackKeys {
  manual = 'REANIMATION_FEEDBACK_MANUELL',
  mechanical = 'REANIMATION_FEEDBACK_MECHANISCH',
}

export enum ReanimationDeviceKeys {
  vvEcmo = 'REANIMATION_SPEZIAL_GERAET_VV_ECMO',
  vaEcmo = 'REANIMATION_SPEZIAL_GERAET_VA_ECMO',
  reboa = 'REANIMATION_SPEZIAL_GERAET_REBOA',
}

export enum ReanimationCoolingKeys {
  activeCooling = 'REANIMATION_KUEHLUNG_AKTIV',
}

export enum ReanimationCoolingByKeys {
  infusion = 'REANIMATION_KUEHLUNG_DURCH_INFUSION',
  coolingPack = 'REANIMATION_KUEHLUNG_DURCH_KUEHLPACK',
  technical = 'REANIMATION_KUEHLUNG_DURCH_TECHNISCH',
}

export enum ReanimationInstructionKeys {
  phoneInstruction = 'REANIMATION_TEL_ANLEITUNG_REA',
}

type AllReanimationKeys =
  | ReanimationCompressionKeys
  | ReanimationFeedbackKeys
  | ReanimationDeviceKeys
  | ReanimationCoolingKeys
  | ReanimationCoolingByKeys
  | ReanimationInstructionKeys;

export const REANIMATION_RECORD_ELEMENT_KEY = 'reanimation';

export type ReanimationRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof REANIMATION_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          reanimationKompressionen?: MultiSelectFieldValueTyped<ReanimationCompressionKeys>;
          reanimationFeedback?: SingleSelectFieldValueTyped<ReanimationFeedbackKeys>;
          reanimationBeginnKompressionen?: TextFieldValue;
          reanimationSpezialGeraet?: MultiSelectFieldValueTyped<ReanimationDeviceKeys>;
          reanimationKuehlung?: MultiSelectFieldValueTyped<ReanimationCoolingKeys>;
          reanimationKuehlungDurch?: SingleSelectFieldValueTyped<ReanimationCoolingByKeys>;
          reanimationTelAnleitung?: MultiSelectFieldValueTyped<ReanimationInstructionKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

const ReanimationLabels: Record<AllReanimationKeys, string> = {
  [ReanimationCompressionKeys.cardiacMassage]: 'Herzdruckmassage',
  [ReanimationFeedbackKeys.manual]: 'Feedbacksystem',
  [ReanimationFeedbackKeys.mechanical]: 'mechanisches Thoraxkompressionssystem',
  [ReanimationDeviceKeys.vvEcmo]: 'VV-ECMO',
  [ReanimationDeviceKeys.vaEcmo]: 'VA-ECMO',
  [ReanimationDeviceKeys.reboa]: 'REBOA',
  [ReanimationCoolingKeys.activeCooling]: 'Aktive Kühlung',
  [ReanimationCoolingByKeys.infusion]: 'Infusion',
  [ReanimationCoolingByKeys.coolingPack]: 'Kühlpackungen',
  [ReanimationCoolingByKeys.technical]: 'technisch',
  [ReanimationInstructionKeys.phoneInstruction]: 'Vorab: Telefonanleitung zur Reanimation',
};

export function getReanimationTuple<ENUM_TYPE extends AllReanimationKeys>(key: ENUM_TYPE): [ENUM_TYPE, string] {
  return [key, ReanimationLabels[key]];
}

export function getReanimationTuplesFor<ENUM_TYPE extends AllReanimationKeys>(
  keys: ENUM_TYPE[],
): [ENUM_TYPE, string][] {
  return keys.map((k) => {
    return getReanimationTuple(k);
  });
}
