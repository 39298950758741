import { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, DialogContent, IconButton, TextField } from '@mui/material';
import style from './AddTimerDialog.scss?inline';
import { useCSS } from '../../../../provider/CSSProvider';
import { Add, CheckCircleOutline, PlayCircle } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs, { Dayjs } from 'dayjs';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';

interface AddTimerDialogProps {
  suggestedName: string;
  correlatedRecordId?: number;
  open: boolean;
  onOpenChanged: (open: boolean) => void;
}

const startingDuration = dayjs(new Date(1990, 0, 1));
const maxDuration = dayjs(new Date(1990, 0, 1, 0, 59, 59));

export function AddTimerDialog({ open, onOpenChanged, suggestedName, correlatedRecordId }: AddTimerDialogProps) {
  useCSS(style);

  const reportsApi = useContext(ReportsAPIContext);

  const [name, setName] = useState<string | null>(null);
  const [duration, setDuration] = useState<Dayjs | null>(startingDuration);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const isValidDuration = duration != null && duration.isValid();

  const add1Min = () => {
    setDuration((oldDuration) => {
      const newDuration = oldDuration?.add(1, 'm') ?? null;
      return newDuration?.isAfter(maxDuration) ? maxDuration : newDuration;
    });
  };

  const close = () => {
    onOpenChanged(false);

    setTimeout(() => {
      setName(null);
      setDuration(startingDuration);
      setIsConfirmed(false);
    }, 200);
  };

  const createTimer = () => {
    if (isValidDuration && !duration.isSame(startingDuration) && reportsApi.startTimer) {
      reportsApi.startTimer(
        name ?? suggestedName,
        dayjs().add(duration!.diff(startingDuration, 's'), 's').valueOf(),
        duration!.diff(startingDuration, 's'),
        {
          recordId: correlatedRecordId,
        },
      );
      setIsConfirmed(true);

      setTimeout(close, 1000);
    }
  };

  return (
    <Dialog open={open} onClose={close} className='add-timer-dialog' closeAfterTransition={false}>
      <DialogTitle>Timer anlegen</DialogTitle>
      <DialogContent>
        {isConfirmed ? (
          <div className='add-timer-dialog-confirmed'>
            <CheckCircleOutline className='check-circle' />
          </div>
        ) : (
          <div className='add-timer-dialog-grid'>
            <TextField
              className='textfield-name'
              label='Name'
              value={name ?? suggestedName}
              onChange={(event) => setName(event.target.value)}
            ></TextField>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                className='textfield-duration'
                label='Dauer'
                format={'mm:ss'}
                value={duration}
                onChange={setDuration}
              />
            </LocalizationProvider>
            <Button
              className='button-add-1min'
              variant='outlined'
              startIcon={<Add />}
              disabled={!isValidDuration || duration.isSame(maxDuration)}
              onClick={add1Min}
            >
              1 min
            </Button>
            <IconButton
              className='button-create'
              color='primary'
              disabled={!isValidDuration || duration.isSame(startingDuration)}
              onClick={createTimer}
            >
              <PlayCircle className='icon' />
            </IconButton>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
