import React, { useContext, useMemo } from 'react';
import globalStyle from '../../../../global.scss?inline';
import style from './Ecg.scss?inline';
import { FormControl } from '@mui/material';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { InputState } from '../../../../backendModels/general.model';
import { useCSS } from '../../../../provider/CSSProvider';
import { EcgKeys, getEcgTuplesFor } from '../../../../models/ecgFindings';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import _ from 'lodash';
import { NodeType } from '../../../../backendModels/report.model';
import { CardId } from '../../../../models/diviCard';
import RadioList from '../../../customs/radioList/RadioList';
import { useFreshCallback } from '../../../../utils/hooks';

interface EcgProps {
  nodeType: NodeType;
}

function Ecg({ nodeType }: EcgProps) {
  useCSS(globalStyle);
  useCSS(style);

  const ekgCheckboxItems: [EcgKeys, string][] = useMemo(
    () =>
      getEcgTuplesFor([
        EcgKeys.stemi,
        EcgKeys.schmaleqrstachykardie,
        EcgKeys.breiteqrstachykardie,
        EcgKeys.svesvesmonomorph,
        EcgKeys.vespolymorph,
      ]),
    [],
  );

  const ekgRadioItems: [EcgKeys, string][] = useMemo(
    () =>
      getEcgTuplesFor([
        EcgKeys.absolutearrhythmie,
        EcgKeys.avblockii,
        EcgKeys.avblockiii,
        EcgKeys.schrittmacherrhythmus,
        EcgKeys.kammerflimmern,
        EcgKeys.peaemd,
        EcgKeys.asystolie,
      ]),
    [],
  );

  const ekgRadioKeys: EcgKeys[] = useMemo(() => ekgRadioItems.map((o) => o[0]), [ekgRadioItems]);

  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.ecgFindings.type, nodeType);

  const symptoms = (record.inputState === InputState.ENTERED ? (record.symptoms ?? []) : []) as EcgKeys[];

  const handleChangeEkgCheckbox = (values: EcgKeys[]) => {
    reportAPI.adaptRecord(defaultRecords.ecgFindings.type, nodeType, (draft, deleteRecord) => {
      if (values.length === 0) {
        deleteRecord();
      } else {
        return { ...draft, inputState: InputState.ENTERED, symptoms: values };
      }
    });
  };

  const handleChangeEkgRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    reportAPI.adaptRecord(defaultRecords.ecgFindings.type, nodeType, (draft) => {
      return {
        ...draft,
        inputState: InputState.ENTERED,
        symptoms: [..._.difference(symptoms, ekgRadioKeys, [EcgKeys.noEkg]), event.target.value],
      };
    });
  };

  const handleNotTested = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.ecgFindings.type, nodeType, (draft) => ({
      id: draft.id,
      type: draft.type,
      inputState: InputState.ENTERED,
      symptoms: [EcgKeys.noEkg],
    }));
  });

  const setToNormal = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.ecgFindings.type, nodeType, (draft) => ({
      id: draft.id,
      type: draft.type,
      inputState: InputState.NORMAL,
    }));
  });

  return (
    <DiviFieldCard
      cardType={CardId.Ecg}
      onUnknownStateButtonClicked={handleNotTested}
      unknownStateButtonLabel={'kein EKG'}
      onNormalStateButtonClicked={setToNormal}
      normalStateButtonLabel={'Sinusrhythmus'}
      recordType={defaultRecords.ecgFindings.type}
      nodeType={nodeType}
    >
      <div id='ekg-area'>
        <div className='row symptoms-list'>
          <FormControl key='ekg-radio-group' className='full-width'>
            <RadioList
              items={ekgRadioItems}
              className='full-width'
              name='ekg-group'
              value={_.intersection(symptoms, ekgRadioKeys)[0] ?? null}
              onChange={handleChangeEkgRadio}
            />
          </FormControl>
          <CheckboxList items={ekgCheckboxItems} selectedValues={symptoms} onValuesChange={handleChangeEkgCheckbox} />
        </div>
      </div>
    </DiviFieldCard>
  );
}

export default Ecg;
