import { useContext, useEffect } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import {
  getSymptomStartNameTuplesFor,
  SymptomStartKeys,
} from '../../../../models/genericElements/operationalData/symptomStart';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';

import { InputState } from '../../../../backendModels/general.model';
import { NodeType } from '../../../../backendModels/report.model';
import { Draft } from 'immer';
import { useFreshCallback } from '../../../../utils/hooks';
import dayjs from 'dayjs';
import { isGenericRecordDeletable } from '../../../../models/generic';
import {
  changePropertyAndMarkTouched,
  getTouchedClassName,
  handleUntouchedTextFieldUpdates,
} from '../../../../utils/recordWithTouched';
import { timeHHmmRegex } from '../../../../utils/util';
import {
  SYMPTOM_ONSET_RECORD_ELEMENT_KEY,
  SymptomOnsetPropertyKeys,
  SymptomOnsetRecord,
} from '../../../../models/genericElements/operationalData/symptomOnset';

interface SymptomStartProps {
  nodeType: NodeType;
}

const symptomStartItems = getSymptomStartNameTuplesFor([
  SymptomStartKeys.ueber24H,
  SymptomStartKeys.beobachtet,
  SymptomStartKeys.unbekannt,
]);

export default function SymptomStart({ nodeType }: SymptomStartProps) {
  const { findRecordOrDefault, adaptRecord, patient, findNodes } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, SYMPTOM_ONSET_RECORD_ELEMENT_KEY) as SymptomOnsetRecord;

  useEffect(() => {
    if (patient != null && findNodes(nodeType).length > 0) {
      adaptRecord(
        'generic',
        nodeType,
        (draft: Draft<SymptomOnsetRecord>, deleteRecord) => {
          draft.inputState = InputState.ENTERED;

          handleUntouchedTextFieldUpdates(
            draft,
            SymptomOnsetPropertyKeys.symptomOnsetTimestamp,
            patient.symptomOnset && timeHHmmRegex.test(patient.symptomOnset) ? patient.symptomOnset : undefined,
          );

          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        },
        SYMPTOM_ONSET_RECORD_ELEMENT_KEY,
      );
    }
  }, [patient, findNodes, nodeType, adaptRecord]);

  const selectedSymptomStartValues =
    record.inputState === InputState.ENTERED ? (record.values?.symptomOnsetOptions?.options ?? []) : [];
  const timeString =
    record.inputState == InputState.ENTERED ? (record.values.symptomOnsetTimestamp?.text ?? null) : null;
  const timeDayjs = timeString != null ? dayjs(timeString, 'HH:mm') : null;

  const changeSymptomStart = (values: SymptomStartKeys[]) => {
    changePropertyAndMarkTouched(
      adaptRecord,
      nodeType,
      SYMPTOM_ONSET_RECORD_ELEMENT_KEY,
      SymptomOnsetPropertyKeys.symptomOnsetOptions,
      values.length !== 0 ? { fieldType: 'multiSelect', options: values } : undefined,
    );
  };

  const handleStartTime = useFreshCallback((value: dayjs.Dayjs | null) => {
    changePropertyAndMarkTouched(
      adaptRecord,
      nodeType,
      SYMPTOM_ONSET_RECORD_ELEMENT_KEY,
      SymptomOnsetPropertyKeys.symptomOnsetTimestamp,
      value != null && value.isValid()
        ? {
            fieldType: 'text',
            text: value.format('HH:mm'),
          }
        : undefined,
    );
  });

  return (
    <DiviFieldCard cardType={CardId.SymptomStart}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimeField
          fullWidth
          className={`time-of-arrival no-margin-top ${getTouchedClassName(record, SymptomOnsetPropertyKeys.symptomOnsetTimestamp)}`}
          label='Symptom Beginn (z.B. Kollaps Unfallzeitpunkt)'
          format='HH:mm'
          value={timeDayjs}
          onChange={handleStartTime}
        />
      </LocalizationProvider>
      <CheckboxList
        className='add-margin-top symptoms-list'
        items={symptomStartItems}
        selectedValues={selectedSymptomStartValues}
        onValuesChange={changeSymptomStart}
      />
    </DiviFieldCard>
  );
}
