import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../../generic';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export enum MedicalConditionMetabolismKeys {
  hypoglykaemie = 'HYPOGLYKAEMIE',
  hyperglykaemie = 'HYPERGLYKAEMIE',
  giExsiccose = 'GI_EXSICCOSE',
  uraemie = 'URAEMIE',
  dialyse = 'DIALYSE',
}

export const MEDICAL_CONDITION_METABOLISM_ELEMENT_KEY = 'erkrankungenStoffwechsel';

export type MedicalConditionMetabolismRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof MEDICAL_CONDITION_METABOLISM_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          erkrankungenStoffwechsel?: MultiSelectFieldValueTyped<MedicalConditionMetabolismKeys>;
          erkrankungenStoffwechselSonstige?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
    | {
        readonly inputState: InputState.NORMAL;
        readonly values: null;
      }
  );

const metabolismLabels: Record<MedicalConditionMetabolismKeys, string> = {
  [MedicalConditionMetabolismKeys.hypoglykaemie]: 'Hypoglykämie',
  [MedicalConditionMetabolismKeys.hyperglykaemie]: 'Hyperglykämie',
  [MedicalConditionMetabolismKeys.giExsiccose]: 'Exsiccose',
  [MedicalConditionMetabolismKeys.uraemie]: 'Urämie/ANV',
  [MedicalConditionMetabolismKeys.dialyse]: 'bek. Dialysepflicht',
};

export function getMetabolismTupel(key: MedicalConditionMetabolismKeys): [MedicalConditionMetabolismKeys, string] {
  return [key, metabolismLabels[key]];
}

export function getMetabolismTupelsFor(
  keys: MedicalConditionMetabolismKeys[],
): [MedicalConditionMetabolismKeys, string][] {
  return keys.map((k) => {
    return getMetabolismTupel(k);
  });
}
