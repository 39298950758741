import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../../generic';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export enum MedicalConditionGynecologyKeys {
  schwangerschaft = 'SCHWANGERSCHAFT',
  drohendeGeburt = 'DROHENDE_GEBURT',
  praeklinischeGeburt = 'PRAEKLINISCHE_GEBURT',
  praeeklampsie = 'PRAEKLAMPSIE',
  vaginaleBlutung = 'VAGINALE_BLUTUNG',
}

export const MEDICAL_CONDITION_GYNECOLOGY_ELEMENT_KEY = 'erkrankungenGyn';

export type MedicalConditionGynecologyRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof MEDICAL_CONDITION_GYNECOLOGY_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          erkrankungenGyn?: MultiSelectFieldValueTyped<MedicalConditionGynecologyKeys>;
          erkrankungenGynSonstige?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
    | {
        readonly inputState: InputState.NORMAL;
        readonly values: null;
      }
  );

const gynecologyLabels: Record<MedicalConditionGynecologyKeys, string> = {
  [MedicalConditionGynecologyKeys.schwangerschaft]: 'Schwangerschaft',
  [MedicalConditionGynecologyKeys.drohendeGeburt]: 'drohende Geburt',
  [MedicalConditionGynecologyKeys.praeklinischeGeburt]: 'präklinische Geburt',
  [MedicalConditionGynecologyKeys.praeeklampsie]: '(Prä-)Eklampsie',
  [MedicalConditionGynecologyKeys.vaginaleBlutung]: 'Vaginale Blutung',
};

export function getGynecologyTupel(key: MedicalConditionGynecologyKeys): [MedicalConditionGynecologyKeys, string] {
  return [key, gynecologyLabels[key]];
}

export function getGynecologyTupelsFor(
  keys: MedicalConditionGynecologyKeys[],
): [MedicalConditionGynecologyKeys, string][] {
  return keys.map((k) => {
    return getGynecologyTupel(k);
  });
}
