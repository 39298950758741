import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { TextFieldValue } from '../../../backendModels/generic-element.model';
import { MultiSelectFieldValueTyped, SingleSelectFieldValueTyped } from '../../generic';

export enum MissionLocationKeys {
  wohnung = 'HOME',
  altenheim = 'NURSING_HOME',
  arbeitsplatz = 'WORKPLACE',
  sportstaette = 'SPORTS_VENUE',
  arztpraxis = 'DOCTORS_OFFICE',
  krankenhaus = 'HOSPITAL',
  geburtshaus = 'BIRTH_HOUSE',
  oeffentlicherraum = 'PUBLIC_SPACE',
  strasse = 'STREET',
  schule = 'SCHOOL',
  bildungseinrichtung = 'EDUCATIONAL_INSTITUTION',
  massenveranstaltung = 'MASS_EVENT',
  sonstige = 'OTHER_LOCATION',
}

export enum LocationPropertyKeys {
  missionStreetGPSLatitude = 'missionStreetGPSLatitude',
  missionCityGPSLongitude = 'missionCityGPSLongitude',
  missionPostcode = 'missionPostcode',
  missionLocationType = 'missionLocationType',
}

export const OPERATIONAL_DATA_LOCATION_RECORD_ELEMENT_KEY = 'diviMissionLocation';
export type OperationalDataLocationRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof OPERATIONAL_DATA_LOCATION_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          missionStreetGPSLatitude?: TextFieldValue;
          missionCityGPSLongitude?: TextFieldValue;
          missionPostcode?: TextFieldValue;
          missionLocationType?: SingleSelectFieldValueTyped<MissionLocationKeys>;
          touched?: MultiSelectFieldValueTyped<LocationPropertyKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

const missionLocationNameTuples: Record<MissionLocationKeys, string> = {
  [MissionLocationKeys.wohnung]: 'Wohnung',
  [MissionLocationKeys.altenheim]: 'Altenheim',
  [MissionLocationKeys.arbeitsplatz]: 'Arbeitsplatz',
  [MissionLocationKeys.sportstaette]: 'Sportstätte',
  [MissionLocationKeys.arztpraxis]: 'Arztpraxis',
  [MissionLocationKeys.krankenhaus]: 'Krankenhaus',
  [MissionLocationKeys.geburtshaus]: 'Geburtshaus',
  [MissionLocationKeys.oeffentlicherraum]: 'öffentlicher Raum',
  [MissionLocationKeys.strasse]: 'Straße',
  [MissionLocationKeys.schule]: 'Schule',
  [MissionLocationKeys.bildungseinrichtung]: 'Bildungseinrichtung',
  [MissionLocationKeys.massenveranstaltung]: 'Massenveranstaltung',
  [MissionLocationKeys.sonstige]: 'Sonstige',
};

export function getMissionLocationNameTuple(key: MissionLocationKeys): [MissionLocationKeys, string] {
  return [key, missionLocationNameTuples[key]];
}

export function getMissionLocationNameTuplesFor(keys: MissionLocationKeys[]): [MissionLocationKeys, string][] {
  return keys.map((k) => {
    return getMissionLocationNameTuple(k);
  });
}

const missionLocationKeysTranslation = new Map<string, MissionLocationKeys>([
  [MissionLocationKeys.wohnung.toLowerCase(), MissionLocationKeys.wohnung],
  ['wohnung', MissionLocationKeys.wohnung],
  [MissionLocationKeys.altenheim.toLowerCase(), MissionLocationKeys.altenheim],
  ['altenheim', MissionLocationKeys.altenheim],
  [MissionLocationKeys.arbeitsplatz.toLowerCase(), MissionLocationKeys.arbeitsplatz],
  ['arbeitsplatz', MissionLocationKeys.arbeitsplatz],
  [MissionLocationKeys.sportstaette.toLowerCase(), MissionLocationKeys.sportstaette],
  ['sportstätte', MissionLocationKeys.sportstaette],
  [MissionLocationKeys.arztpraxis.toLowerCase(), MissionLocationKeys.arztpraxis],
  ['arztpraxis', MissionLocationKeys.arztpraxis],
  [MissionLocationKeys.krankenhaus.toLowerCase(), MissionLocationKeys.krankenhaus],
  ['krankenhaus', MissionLocationKeys.krankenhaus],
  [MissionLocationKeys.geburtshaus.toLowerCase(), MissionLocationKeys.geburtshaus],
  ['geburtshaus', MissionLocationKeys.geburtshaus],
  [MissionLocationKeys.oeffentlicherraum.toLowerCase(), MissionLocationKeys.oeffentlicherraum],
  ['öff. raum', MissionLocationKeys.oeffentlicherraum],
  ['öffentlicher raum', MissionLocationKeys.oeffentlicherraum],
  [MissionLocationKeys.strasse.toLowerCase(), MissionLocationKeys.strasse],
  ['straße', MissionLocationKeys.strasse],
  [MissionLocationKeys.schule.toLowerCase(), MissionLocationKeys.schule],
  ['schule', MissionLocationKeys.schule],
  [MissionLocationKeys.bildungseinrichtung.toLowerCase(), MissionLocationKeys.bildungseinrichtung],
  ['bildungseinrichtung', MissionLocationKeys.bildungseinrichtung],
  [MissionLocationKeys.massenveranstaltung.toLowerCase(), MissionLocationKeys.massenveranstaltung],
  ['massenveranstaltung', MissionLocationKeys.massenveranstaltung],
  [MissionLocationKeys.sonstige.toLowerCase(), MissionLocationKeys.sonstige],
  ['sonstige', MissionLocationKeys.sonstige],
]);

export function mapStringToMissionLocationKey(value: string | null | undefined) {
  if (value == null) {
    return undefined;
  }
  return missionLocationKeysTranslation.get(value.toLowerCase());
}
