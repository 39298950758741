export class ManualEventEmitEvent extends Event {
  effectiveTimestamp: number;
  name: string;

  constructor(type: string, effectiveTimestamp: number, name: string) {
    super(type, { bubbles: true });

    this.effectiveTimestamp = effectiveTimestamp;
    this.name = name;
  }
}
