import { Button, Menu, MenuItem } from '@mui/material';
import { useContext, useState } from 'react';
import style from './Acronyms.scss?inline';
import { useCSS } from '../../../provider/CSSProvider';
import { getPageRoute, PageType } from '../../../models/diviPage';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '../../../assets/images/icons/category_search.svg?react';

export function Acronyms() {
  useCSS(style);

  const { acronyms } = useContext(ReportsAPIContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const menuItems =
    acronyms != null
      ? Object.getOwnPropertyNames(acronyms).map((key: string) => (
          <MenuItem
            key={key}
            className='acronym-button'
            onClick={() => {
              navigate(getPageRoute(PageType.Acronym) + '/' + encodeURIComponent(key));
              handleClose();
            }}
          >
            {key}
          </MenuItem>
        ))
      : [];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='acronym-header'>
      <Button
        id='acronym-menu-button'
        variant='outlined'
        disableElevation
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        startIcon={<SearchIcon />}
      >
        Akronym - Schema
      </Button>

      <Menu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            navigate(getPageRoute(PageType.SAMPLERPlusS));
            handleClose();
          }}
        >
          SAMPLER+S
        </MenuItem>
        {menuItems}
      </Menu>
    </div>
  );
}
