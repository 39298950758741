import { createContext, ReactNode, useCallback, useMemo, useSyncExternalStore } from 'react';

const localStoragePrefix = 'divi-';

type asStateType = <T>(key: string) => [value: T, setValue: (mutator: (oldData?: T) => T) => void];

export interface LocalStorageContextType {
  getData: <T>(key: string) => T | undefined;
  setData: <T>(key: string, mutator: (oldData?: T) => T) => void;
  asState: asStateType;
}

const localStorageContextDefaultValue: LocalStorageContextType = {
  getData: () => {
    throw new Error('Uninitialized');
  },
  setData: () => {
    throw new Error('Uninitialized');
  },
  asState: () => {
    throw new Error('Uninitialized');
  },
};

export interface LocalStorageProviderProps {
  children: ReactNode;
  diviProtocolId: string;
}

export const LocalStorageContext = createContext<LocalStorageContextType>(localStorageContextDefaultValue);

export function LocalStorageProvider({ children, diviProtocolId }: LocalStorageProviderProps) {
  let localStorageCallback = () => {};
  const localStorageString = useSyncExternalStore(
    (callback) => {
      localStorageCallback = callback;
      window.addEventListener('storage', callback);
      return () => window.removeEventListener('storage', callback);
    },
    () => localStorage.getItem(`${localStoragePrefix}${diviProtocolId}`),
  );
  const localStorageData = useMemo(
    () => (localStorageString ? JSON.parse(localStorageString) : {}),
    [localStorageString],
  );

  const getData = useCallback(
    function <T>(key: string): T {
      return localStorageData[key];
    },
    [localStorageData],
  );

  const setData = useCallback(
    function <T>(key: string, mutator: (oldData: T) => T) {
      const localStorageString = localStorage.getItem(`${localStoragePrefix}${diviProtocolId}`);
      const oldData = localStorageString ? JSON.parse(localStorageString) : {};
      const newData = { ...oldData, [key]: mutator(oldData[key]) };
      localStorage.setItem(`${localStoragePrefix}${diviProtocolId}`, JSON.stringify(newData));
      // Explicitly trigger the callback, since changes from the same context do not dispatch the event.
      localStorageCallback();
    },
    [diviProtocolId],
  );

  const asState: asStateType = useCallback(
    function <T>(key: string) {
      return [localStorageData[key], (mutator: (oldData: T) => T) => setData(key, mutator)];
    },
    [localStorageData, setData],
  );

  const localStorageContextValue = useMemo(() => {
    return {
      getData,
      setData,
      asState,
    };
  }, [getData, setData, asState]);

  return <LocalStorageContext.Provider value={localStorageContextValue}>{children}</LocalStorageContext.Provider>;
}
