import AmiodaronIcon from '../../assets/images/events/amiodaron.svg?react';
import DefibrillationIcon from '../../assets/images/events/defibrillation.svg?react';
import ExtubationIcon from '../../assets/images/events/extubation.svg?react';
import HDMIcon from '../../assets/images/events/hdm.svg?react';
import IntubationIcon from '../../assets/images/events/intubation.svg?react';
import SuprareninIcon from '../../assets/images/events/suprarenin.svg?react';
import TransportStartIcon from '../../assets/images/events/transport-start.svg?react';
import TransportEndIcon from '../../assets/images/events/transport-end.svg?react';
import ArrivedAtPatientIcon from '../../assets/images/events/arrived-at-patient.svg?react';
import FirstCommunicationIcon from '../../assets/images/events/first-communication.svg?react';
import { ReactNode } from 'react';
import { PredefinedEvent } from './predefinedEvent';

export const eventIconMap = new Map([
  [PredefinedEvent.amiodaron, AmiodaronIcon],
  [PredefinedEvent.defibrillation, DefibrillationIcon],
  [PredefinedEvent.extubation, ExtubationIcon],
  [PredefinedEvent.hdm, HDMIcon],
  [PredefinedEvent.intubation, IntubationIcon],
  [PredefinedEvent.suprarenin, SuprareninIcon],
  [PredefinedEvent.transportStart, TransportStartIcon],
  [PredefinedEvent.transportEnd, TransportEndIcon],
  [PredefinedEvent.arrivedAtPatient, ArrivedAtPatientIcon],
  [PredefinedEvent.firstCommunication, FirstCommunicationIcon],
]);

export function getIconForName(name: PredefinedEvent | string, className: string): ReactNode {
  const Icon = eventIconMap.get(name as PredefinedEvent);
  if (Icon == null) {
    return <span className={className} />;
  }

  return <Icon className={className}></Icon>;
}
