import { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { NodeType } from '../../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { InputState } from '../../../../backendModels/general.model';
import { Draft } from 'immer';
import {
  MEDICAL_CONDITION_PEDIATRICS_ELEMENT_KEY,
  MedicalConditionPediatricsRecord,
  getPediatricsTupelsFor,
  MedicalConditionPediatricsKeys,
} from '../../../../models/genericElements/medicalConditions/pediatrics';
import { isGenericRecordDeletable, setGenericRecordToNormal } from '../../../../models/generic';

const pediatricsNameTuples: [MedicalConditionPediatricsKeys, string][] = getPediatricsTupelsFor([
  MedicalConditionPediatricsKeys.fieberkrampf,
  MedicalConditionPediatricsKeys.pseudokrupp,
  MedicalConditionPediatricsKeys.sids,
]);

interface MedicalConditionPediatricsProps {
  nodeType: NodeType;
}

export default function MedicalConditionPediatrics({ nodeType }: MedicalConditionPediatricsProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault(
    'generic',
    nodeType,
    MEDICAL_CONDITION_PEDIATRICS_ELEMENT_KEY,
  ) as MedicalConditionPediatricsRecord;
  const conditions = record.inputState === InputState.ENTERED ? (record.values.erkrankungenPaed?.options ?? []) : [];

  const conditionsChange = (newConditions: MedicalConditionPediatricsKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<MedicalConditionPediatricsRecord>, deleteRecord) => {
        if (newConditions.length > 0) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            erkrankungenPaed: {
              fieldType: 'multiSelect',
              options: newConditions,
            },
          };
        } else {
          delete draft.values?.erkrankungenPaed;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      MEDICAL_CONDITION_PEDIATRICS_ELEMENT_KEY,
    );
  };
  const otherChange = (value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<MedicalConditionPediatricsRecord>, deleteRecord) => {
        if (value !== '') {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            erkrankungenPaedSonstige: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.erkrankungenPaedSonstige;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      MEDICAL_CONDITION_PEDIATRICS_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard
      cardType={CardId.MedicalConditionPediatrics}
      nodeType={nodeType}
      recordType='generic'
      elementKey={MEDICAL_CONDITION_PEDIATRICS_ELEMENT_KEY}
      normalStateButtonVisible={false}
      onNormalStateButtonClicked={() =>
        setGenericRecordToNormal(adaptRecord, nodeType, MEDICAL_CONDITION_PEDIATRICS_ELEMENT_KEY)
      }
    >
      <div className='symptoms-list'>
        <CheckboxList items={pediatricsNameTuples} selectedValues={conditions} onValuesChange={conditionsChange} />
      </div>
      <DebouncedTextField
        fullWidth
        label='Sonstige...'
        value={record.inputState === InputState.ENTERED ? (record.values.erkrankungenPaedSonstige?.text ?? '') : ''}
        onDebounceChange={otherChange}
        inputProps={{ maxLength: 18 }}
      />
    </DiviFieldCard>
  );
}
