import { DiviNavigationIds } from '../../../models/diviProgress';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { NodeType } from '../../../backendModels/report.model';
import { getPageTitle, PageType } from '../../../models/diviPage';
import Ventilation from './Ventilation/Ventilation';
import Defibrillation from './Defibrillation/Defibrillation';
import AccessRoute from './AccessRoute/AccessRoute';
import Airway from './Airway/Airway';
import style from './Treatments.scss?inline';
import { useCSS } from '../../../provider/CSSProvider';
import { CardId } from '../../../models/diviCard';
import Other from './Other/Other';
import TreatmentsReanimation from './Reanimation/TreatmentsReanimation';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { DEFIBRILLATION_RECORD_ELEMENT_KEY } from '../../../models/genericElements/treatments/defibrillation';
import { REANIMATION_RECORD_ELEMENT_KEY } from '../../../models/genericElements/treatments/reanimation';
import { ACCESS_ROUTE_DIFFICULTY_RECORD_ELEMENT_KEY } from '../../../models/genericElements/treatments/accessRouteDifficult';
import { STORAGE_RECORD_ELEMENT_KEY } from '../../../models/genericElements/treatments/storage';
import { EQUIPMENT_RECORD_ELEMENT_KEY } from '../../../models/genericElements/treatments/equipment';
import { VENTILATION_RECORD_ELEMENT_KEY } from '../../../models/genericElements/treatments/ventilation';
import { OXYGEN_MEASSURES_RECORD_ELEMENT_KEY } from '../../../models/genericElements/oxygenMeassures';

export function Treatments() {
  useCSS(style);

  const recordTypesOfPage = ['accessRoute', 'airwayManagement'] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.GENERIC;
  const elementKeysOfPage = [
    DEFIBRILLATION_RECORD_ELEMENT_KEY,
    REANIMATION_RECORD_ELEMENT_KEY,
    ACCESS_ROUTE_DIFFICULTY_RECORD_ELEMENT_KEY,
    STORAGE_RECORD_ELEMENT_KEY,
    EQUIPMENT_RECORD_ELEMENT_KEY,
    VENTILATION_RECORD_ELEMENT_KEY,
    OXYGEN_MEASSURES_RECORD_ELEMENT_KEY,
  ];

  const pageHeader = (
    <PageHeader
      heading={getPageTitle(PageType.Measures)}
      ListOfCardIds={[
        CardId.AccessRoute,
        CardId.Airway,
        CardId.TreatmentsVentilation,
        CardId.TreatmentsDefibrillation,
        CardId.TreatmentsReanimation,
        CardId.TreatmentsOther,
      ]}
    />
  );

  const pageFooter = <PageFooter pageType={PageType.Measures} diviId={DiviNavigationIds.Measures} />;

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      elementKeys={elementKeysOfPage}
      diviId={DiviNavigationIds.Measures}
    >
      <div className='wireframe-container-measures'>
        <AccessRoute nodeType={nodeTypeOfPage} />
        <Ventilation nodeType={nodeTypeOfPage} />
        <Airway nodeType={nodeTypeOfPage} />
        <Defibrillation nodeType={nodeTypeOfPage} />
        <TreatmentsReanimation nodeType={nodeTypeOfPage} />
        <Other nodeType={nodeTypeOfPage} />
      </div>
    </DiviPage>
  );
}
