import Dialog from '@mui/material/Dialog/Dialog';
import React, { useEffect, useState } from 'react';
import globalStyle from '../../../global.scss?inline';
import style from './TimeDialog.scss?inline';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useCSS } from '../../../provider/CSSProvider';

export default function TimeDialog(props: {
  timestamp: { timestamp: dayjs.Dayjs; setTimestamp: (t: dayjs.Dayjs) => void };
  dialogVisible: { open: boolean; setOpen: (b: boolean) => void };
}) {
  useCSS(style);
  useCSS(globalStyle);

  const [localDialogTime, setLocalDialogTime] = useState<dayjs.Dayjs>(props.timestamp.timestamp);

  useEffect(() => {
    setLocalDialogTime(props.timestamp.timestamp);
  }, [props.timestamp.timestamp]);

  function handleClose() {
    props.dialogVisible.setOpen(false);
  }

  function increaseDay() {
    setLocalDialogTime(localDialogTime.add(1, 'day'));
  }

  function decreaseDay() {
    setLocalDialogTime(localDialogTime.subtract(1, 'day'));
  }

  function submit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const tempTime = dayjs(`0000-0-0T${formJson.time}`);
    const newTime = localDialogTime.set('hour', tempTime.hour()).set('minute', tempTime.minute());
    setLocalDialogTime(newTime);
    props.timestamp.setTimestamp(newTime);
    handleClose();
  }

  return (
    <Dialog
      open={props.dialogVisible.open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: submit,
      }}
      closeAfterTransition={false}
      className='time-dialog'
    >
      <DialogTitle>Zeitpunkt des Events</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box textAlign='center' className='centered'>
              <TimeField name='time' label='' defaultValue={localDialogTime} format='HH:mm' />
            </Box>
            <Typography textAlign='center'>
              <Button variant='text' onClick={decreaseDay}>
                <Box component='span' fontWeight='bold'>
                  &lt;
                </Box>
              </Button>
              {localDialogTime.format('D/M/YYYY')}
              <Button variant='text' onClick={increaseDay}>
                <Box component='span' fontWeight='bold'>
                  &gt;
                </Box>
              </Button>
            </Typography>
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button className='cancel-button' onClick={handleClose} variant='outlined'>
          Abbrechen
        </Button>
        <Button type='submit' variant='contained'>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
