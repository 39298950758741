import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { TextFieldValue } from '../../../backendModels/generic-element.model';
import { MANUAL_EVENT_RECORD_ELEMENT_KEY, ManualEventRecord } from './manualEvent';

export const ALARM_EVENT_RECORD_ELEMENT_KEY = 'diviAlarmEvent';

export type AlarmEventRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof ALARM_EVENT_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          alarmTimestamp?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export function alarmEventsToVirtualManualEvents(records: AlarmEventRecord[]) {
  const resultingEvents: ManualEventRecord[] = [];

  for (const record of records) {
    if (record.inputState === InputState.ENTERED && record.values.alarmTimestamp?.text != null) {
      resultingEvents.push({
        type: 'generic',
        elementKey: MANUAL_EVENT_RECORD_ELEMENT_KEY,
        inputState: InputState.ENTERED,
        values: {
          eventName: {
            fieldType: 'text',
            text: 'Alarm',
          },
          eventTimestamp: {
            fieldType: 'text',
            text: record.values.alarmTimestamp.text,
          },
        },
      });
    }
  }

  return resultingEvents;
}
