import { useCSS } from '../../../provider/CSSProvider';
import style from './AddEventDialog.scss?inline';
import { useContext, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { CheckCircleOutline } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs, { Dayjs } from 'dayjs';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import { getIconForName } from '../../../models/manualEvents/iconMap';
import { ManualEventType } from '../../../models/manualEvents/config';

interface AddEventDialogProps {
  open: boolean;
  onOpenChanged: (open: boolean) => void;
  presetEvent: ManualEventType | null;
}

export default function AddEventDialog({ open, onOpenChanged, presetEvent }: AddEventDialogProps) {
  useCSS(style);

  const { manualEventTypes, manualEventEmit } = useContext(ReportsAPIContext);

  const [eventName, setEventName] = useState(presetEvent?.name || '');
  const [timestamp, setTimestamp] = useState<Dayjs | null>(dayjs());
  const [isConfirmed, setIsConfirmed] = useState(false);

  const close = () => {
    onOpenChanged(false);
    setTimeout(() => {
      setTimestamp(dayjs());
      setEventName('');
      setIsConfirmed(false);
    }, 200);
  };

  function saveAndClose() {
    if (timestamp?.isValid() && eventName !== '') {
      manualEventEmit(timestamp.valueOf(), eventName);
    }

    setIsConfirmed(true);
    setTimeout(close, 1000);
  }

  useEffect(() => {
    if (open) {
      setTimestamp(dayjs());
      setEventName(presetEvent?.name || '');
    }
  }, [open, presetEvent]);

  return (
    <Dialog open={open} onClose={close} className='add-event-dialog' closeAfterTransition={false}>
      <DialogTitle className='title'>Neues Ereignis</DialogTitle>
      <DialogContent>
        {isConfirmed ? (
          <div className='add-event-dialog-confirmed'>
            <CheckCircleOutline className='check-circle' />
          </div>
        ) : (
          <div className='row add-margin-top'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                name='eventTimestamp'
                label='Uhrzeit'
                format='HH:mm'
                value={timestamp}
                onChange={setTimestamp}
                className='time-field'
              />
            </LocalizationProvider>
            <FormControl className='event-name-select'>
              <InputLabel id='event-name-label'>Ereignis wählen</InputLabel>
              <Select
                label='Ereignis wählen'
                labelId='event-name-label'
                value={eventName}
                onChange={(event) => setEventName(event.target.value)}
                className='event-select'
              >
                {manualEventTypes?.map((eventType) => (
                  <MenuItem className='add-event-menu-item' key={eventType.name} value={eventType.name}>
                    <div className='icon'>{getIconForName(eventType.name, 'icon-svg')}</div>
                    <div className='name'>{eventType.name}</div>
                    <div className='explanation'>{eventType.explanation}</div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
      </DialogContent>
      {!isConfirmed && (
        <DialogActions>
          <Button variant='outlined' className='cancel-button' onClick={close}>
            Abbrechen
          </Button>
          <Button variant='contained' onClick={saveAndClose} disabled={!timestamp?.isValid() || eventName === ''}>
            Hinzufügen
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
