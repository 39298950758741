import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { TextFieldValue } from '../../backendModels/generic-element.model';
import { SingleSelectFieldValueTyped } from '../generic';

export enum HandoverToKeys {
  einsatzstelle = 'UEBERGABE_ORT_EINSATZSTELLE',
  znaina = 'UEBERGABE_ORT_ZNA',
  strokeunit = 'UEBERGABE_ORT_STROKE',
  intensivstation = 'UEBERGABE_ORT_INTENSIV',
  opdirekt = 'UEBERGABE_ORT_OP',
  praxis = 'UEBERGABE_ORT_PRAXIS',
  hausarzt = 'UEBERGABE_ORT_HAUSARZT',
  fachambulanz = 'UEBERGABE_ORT_FACHAMBULANZ',
  schockraum = 'UEBERGABE_ORT_SCHOCKRAUM',
  herzkatheterlabor = 'UEBERGABE_ORT_HKL',
  cpu = 'UEBERGABE_ORT_CHEST_PAIN_UNIT',
  allgemeinstation = 'UEBERGABE_ORT_ALLGEMEIN',
}

export const HANDOVER_TO_RECORD_ELEMENT_KEY = 'uebergabeOrt';

export type HandoverToRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof HANDOVER_TO_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          uebergabeOrt?: SingleSelectFieldValueTyped<HandoverToKeys>;
          uebergabeOrtSonstige?: TextFieldValue;
          uebergabeOrtName?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export const handoverToLabels: Record<HandoverToKeys, string> = {
  [HandoverToKeys.einsatzstelle]: 'Einsatzstelle',
  [HandoverToKeys.znaina]: 'ZNA / INA',
  [HandoverToKeys.strokeunit]: 'Stroke Unit',
  [HandoverToKeys.intensivstation]: 'Intensivstation',
  [HandoverToKeys.opdirekt]: 'OP direkt',
  [HandoverToKeys.praxis]: 'Praxis',
  [HandoverToKeys.hausarzt]: 'Hausarzt / KV-Arzt vor Ort',
  [HandoverToKeys.fachambulanz]: 'Fachambulanz',
  [HandoverToKeys.schockraum]: 'Schockraum',
  [HandoverToKeys.herzkatheterlabor]: 'Herzkatheterlabor',
  [HandoverToKeys.cpu]: 'CPU',
  [HandoverToKeys.allgemeinstation]: 'Allgemeinstation',
};

export function getHandoverToTuple(key: HandoverToKeys): [HandoverToKeys, string] {
  return [key, handoverToLabels[key]];
}

export function getHandoverToTuplesFor(keys: HandoverToKeys[]): [HandoverToKeys, string][] {
  return keys.map((k) => {
    return getHandoverToTuple(k);
  });
}
