import { useContext } from 'react';
import style from './Reanimation.scss?inline';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { Typography } from '@mui/material';
import { useCSS } from '../../../../provider/CSSProvider';
import { CardId } from '../../../../models/diviCard';
import {
  getReanimationTuplesFor,
  REANIMATION_DEATH_RECORD_ELEMENT_KEY,
  ReanimationByKeys,
  ReanimationDeathRecord,
  ReanimationDescriptionKeys,
  ReanimationHospitalConditionKeys,
  ReanimationHospitalKeys,
  ReanimationNoKeys,
  ReanimationNoReasonKeys,
  ReanimationYesKeys,
} from '../../../../models/genericElements/reanimationDeath';
import RadioList from '../../../customs/radioList/RadioList';
import { NodeType } from '../../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { InputState } from '../../../../backendModels/general.model';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { Draft } from 'immer';
import {
  GenericRecordValues,
  GenericRecordMultiSelects,
  GenericRecordMultiSelectType,
  GenericRecordSingleSelects,
  GenericRecordSingleSelectType,
  isGenericRecordDeletable,
} from '../../../../models/generic';

const reanimationCheckboxItem: [ReanimationYesKeys, string][] = getReanimationTuplesFor([
  ReanimationYesKeys.reanimation,
]);

const reanimationRadioItems: [ReanimationDescriptionKeys, string][] = getReanimationTuplesFor([
  ReanimationDescriptionKeys.roscimverlauf,
  ReanimationDescriptionKeys.niemalsrosc,
  ReanimationDescriptionKeys.erfolglos,
]);

const reanimationByRadioItems: [ReanimationByKeys, string][] = getReanimationTuplesFor([
  ReanimationByKeys.ersthelfer,
  ReanimationByKeys.firstresponder,
  ReanimationByKeys.rettungsdienst,
  ReanimationByKeys.notarzt,
]);

const noReanimationCheckboxItem: [ReanimationNoKeys, string][] = getReanimationTuplesFor([
  ReanimationNoKeys.keinereanimation,
]);

const noReanimationRadioItems: [ReanimationNoReasonKeys, string][] = getReanimationTuplesFor([
  ReanimationNoReasonKeys.nichtgewuenscht,
  ReanimationNoReasonKeys.zuspaet,
  ReanimationNoReasonKeys.aussichtslosegrunderkrankung,
]);

const hospitalCheckboxItem: [ReanimationHospitalKeys, string][] = getReanimationTuplesFor([
  ReanimationHospitalKeys.khaufnahme,
]);

const hospitalRadioItems: [ReanimationHospitalConditionKeys, string][] = getReanimationTuplesFor([
  ReanimationHospitalConditionKeys.mitrosc,
  ReanimationHospitalConditionKeys.laufendereanimation,
]);

interface ReanimationProps {
  nodeType: NodeType;
}

export default function Reanimation({ nodeType }: ReanimationProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(
    'generic',
    nodeType,
    REANIMATION_DEATH_RECORD_ELEMENT_KEY,
  ) as ReanimationDeathRecord;

  const updateParentCheckbox = <FIELD_NAME extends keyof GenericRecordMultiSelects<ReanimationDeathRecord>>(
    fieldName: FIELD_NAME,
    newValues: GenericRecordMultiSelectType<ReanimationDeathRecord, FIELD_NAME>[],
    subFieldNames: (keyof GenericRecordValues<ReanimationDeathRecord>)[],
  ) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ReanimationDeathRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (newValues.length > 0) {
          draft.values = {
            ...draft.values,
            [fieldName]: {
              fieldType: 'multiSelect',
              options: newValues,
            },
          };
        } else {
          delete draft.values?.[fieldName];
          for (const subFieldName of subFieldNames) {
            delete draft.values?.[subFieldName];
          }

          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      REANIMATION_DEATH_RECORD_ELEMENT_KEY,
    );
  };

  const updateChildRadio = <
    FIELD_NAME extends keyof GenericRecordSingleSelects<ReanimationDeathRecord>,
    PARENT_FIELD_NAME extends keyof GenericRecordMultiSelects<ReanimationDeathRecord>,
  >(
    fieldName: FIELD_NAME,
    newValue: GenericRecordSingleSelectType<ReanimationDeathRecord, FIELD_NAME>,
    parentFieldName: PARENT_FIELD_NAME,
    parentOptions: GenericRecordMultiSelectType<ReanimationDeathRecord, PARENT_FIELD_NAME>[],
    conflictingFields: (keyof GenericRecordValues<ReanimationDeathRecord>)[] = [],
  ) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ReanimationDeathRecord>) => {
        draft.inputState = InputState.ENTERED;
        for (const conflictingFieldName of conflictingFields) {
          delete draft.values?.[conflictingFieldName];
        }
        draft.values = {
          ...draft.values,
          [fieldName]: {
            fieldType: 'singleSelect',
            option: newValue,
          },
          [parentFieldName]: {
            fieldType: 'multiSelect',
            options: parentOptions,
          },
        };
      },
      REANIMATION_DEATH_RECORD_ELEMENT_KEY,
    );
  };

  const updateOther = (value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ReanimationDeathRecord>) => {
        draft.inputState = InputState.ENTERED;
        if (value !== '') {
          delete draft.values?.reanimationZusaetzeKeineReaGrund;
          draft.values = {
            ...draft.values,
            reanimationZusaetzeKeineReaGrundSonstiges: {
              fieldType: 'text',
              text: value,
            },
            reanimationZusaetzeKeineRea: {
              fieldType: 'multiSelect',
              options: [ReanimationNoKeys.keinereanimation],
            },
          };
        } else {
          delete draft.values?.reanimationZusaetzeKeineReaGrundSonstiges;
        }
      },
      REANIMATION_DEATH_RECORD_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard cardType={CardId.Reanimation}>
      <CheckboxList
        className='symptoms-list'
        items={reanimationCheckboxItem}
        selectedValues={
          record.inputState === InputState.ENTERED ? (record.values.reanimationZusaetzeRea?.options ?? []) : []
        }
        onValuesChange={(newValues) =>
          updateParentCheckbox('reanimationZusaetzeRea', newValues, [
            'reanimationZusaetzeBeschreibung',
            'reanimationZusaetzePersonalBeginn',
          ])
        }
      />
      <div className='symptoms-list list-1 radio indented-checkbox-parent'>
        <RadioList
          items={reanimationRadioItems}
          name='reanimation-group'
          value={
            record.inputState === InputState.ENTERED
              ? (record.values.reanimationZusaetzeBeschreibung?.option ?? null)
              : null
          }
          onChange={(event) =>
            updateChildRadio(
              'reanimationZusaetzeBeschreibung',
              event.target.value as ReanimationDescriptionKeys,
              'reanimationZusaetzeRea',
              [ReanimationYesKeys.reanimation],
            )
          }
        />
        <Typography variant='body1' className='list-subheading'>
          Beginn Reanimation:
        </Typography>
        <RadioList
          items={reanimationByRadioItems}
          name='reanimation-group'
          value={
            record.inputState === InputState.ENTERED
              ? (record.values.reanimationZusaetzePersonalBeginn?.option ?? null)
              : null
          }
          onChange={(event) =>
            updateChildRadio(
              'reanimationZusaetzePersonalBeginn',
              event.target.value as ReanimationByKeys,
              'reanimationZusaetzeRea',
              [ReanimationYesKeys.reanimation],
            )
          }
        />
      </div>
      <CheckboxList
        className='symptoms-list bold-checkbox-label'
        items={noReanimationCheckboxItem}
        selectedValues={
          record.inputState === InputState.ENTERED ? (record.values.reanimationZusaetzeKeineRea?.options ?? []) : []
        }
        onValuesChange={(newValues) =>
          updateParentCheckbox('reanimationZusaetzeKeineRea', newValues, [
            'reanimationZusaetzeKeineReaGrund',
            'reanimationZusaetzeKeineReaGrundSonstiges',
          ])
        }
      />
      <div className='symptoms-list indented-checkbox-parent'>
        <RadioList
          items={noReanimationRadioItems}
          name='no-reanimation-group'
          value={
            record.inputState === InputState.ENTERED
              ? (record.values.reanimationZusaetzeKeineReaGrund?.option ?? null)
              : null
          }
          onChange={(event) =>
            updateChildRadio(
              'reanimationZusaetzeKeineReaGrund',
              event.target.value as ReanimationNoReasonKeys,
              'reanimationZusaetzeKeineRea',
              [ReanimationNoKeys.keinereanimation],
              ['reanimationZusaetzeKeineReaGrundSonstiges'],
            )
          }
        />
        <DebouncedTextField
          className='txt-other'
          fullWidth
          label='Sonstige'
          value={
            record.inputState === InputState.ENTERED
              ? (record.values.reanimationZusaetzeKeineReaGrundSonstiges?.text ?? '')
              : ''
          }
          onDebounceChange={updateOther}
          inputProps={{ maxLength: 13 }}
        />
      </div>
      <CheckboxList
        className='symptoms-list bold-checkbox-label'
        items={hospitalCheckboxItem}
        selectedValues={
          record.inputState === InputState.ENTERED ? (record.values.reanimationZusaetzeKhAufnahme?.options ?? []) : []
        }
        onValuesChange={(newValues) =>
          updateParentCheckbox('reanimationZusaetzeKhAufnahme', newValues, ['reanimationZusaetzeKhAufnahmeZustand'])
        }
      />
      <div className='symptoms-list indented-checkbox-parent'>
        <RadioList
          items={hospitalRadioItems}
          name='hospital-group'
          value={
            record.inputState === InputState.ENTERED
              ? (record.values.reanimationZusaetzeKhAufnahmeZustand?.option ?? null)
              : null
          }
          onChange={(event) =>
            updateChildRadio(
              'reanimationZusaetzeKhAufnahmeZustand',
              event.target.value as ReanimationHospitalConditionKeys,
              'reanimationZusaetzeKhAufnahme',
              [ReanimationHospitalKeys.khaufnahme],
            )
          }
        />
      </div>
    </DiviFieldCard>
  );
}
