import { getPageRoute, PageType } from '../../../models/diviPage';
import { CardId } from '../../../models/diviCard';

export interface OptionType {
  label: string;
  path: string;
  keywords?: string[];
  fields?: OptionFieldType[];
}

export interface OptionFieldType {
  label: string;
  cardId: string;
  keywords?: string[];
  children?: OptionChildType[];
}
export interface OptionChildType {
  label: string;
  keywords?: string[];
}

export const searchOptions: OptionType[] = [
  {
    label: 'Pat-Stammdaten',
    path: getPageRoute(PageType.PatientMasterData),
    keywords: ['Patient', 'Patienten-Stammdaten'],
    fields: [
      {
        label: 'Pseudonym',
        cardId: CardId.PatientData,
        children: [
          { label: 'Kasse / Nr.', keywords: ['Krankenkasse'] },
          { label: 'Geschlecht' },
          { label: 'BMI' },
          { label: 'Alter' },
        ],
      },
      {
        label: 'Einsatz',
        cardId: CardId.Mission,
        children: [
          { label: 'Versicherungs Nr.', keywords: ['Versicherungsnummer'] },
          { label: 'Einsatz Nr.', keywords: ['Einsatznummer'] },
          { label: 'Pat. / Auftr. Nr.', keywords: ['Patient', 'Auftrag', 'Nummer'] },
          { label: 'Standort' },
          { label: 'Pers. Nr.', keywords: ['Personalnummer'] },
          { label: 'Leitstelle (KFZ-Kennz.)' },
        ],
      },
    ],
  },
  {
    label: 'Einsatztechnische Daten', // COR-409
    path: getPageRoute(PageType.OperationalData),
    fields: [
      {
        label: 'Beteiligt',
        cardId: CardId.Participants,
        children: [
          { label: 'NEF', keywords: ['Notarzteinsatzfahrzeug'] },
          { label: 'Selbstfahrer' },
          { label: 'NAW', keywords: ['Notarztwagen'] },
          { label: 'RTW', keywords: ['Rettungswagen'] },
          { label: 'KTW', keywords: ['Krankentransportwagen'] },
          { label: 'RTH', keywords: ['Rettungshubschrauber'] },
          { label: 'ITH', keywords: ['Intensivtransporthubschrauber'] },
          { label: 'Bergrettung' },
          { label: 'Wasserrettung' },
          { label: 'TeleNA', keywords: ['Telenotarzt', 'TNA'] },
        ],
      },
      {
        label: 'Einsatzart',
        cardId: CardId.MissionType,
        children: [
          { label: 'Primäreinsatz' },
          { label: 'Folgeeinsatz' },
          { label: 'Fehleinsatz', keywords: ['abbestellt', 'abtransportiert', 'böswillige Alarmierung'] },
          { label: 'Einsatzabbruch', keywords: ['Wetter'] },
          { label: 'Notarzt-Nachforderung' },
          { label: 'Sekundäreinsatz z.B. Verlegung' },
          { label: 'vorsorgliche Bereitstellung' },
        ],
      },
      {
        label: 'Einsatzort',
        cardId: CardId.MissionLocation,
        children: [
          { label: 'Wohnung' },
          { label: 'Altenheim' },
          { label: 'Arbeitsplatz' },
          { label: 'Sportstätte' },
          { label: 'Arztpraxis' },
          { label: 'Krankenhaus' },
          { label: 'Geburtshaus' },
          { label: 'öffentlicher Raum', keywords: ['Öffentlichkeit'] },
          { label: 'Straße' },
          { label: 'Schule' },
          { label: 'Bildunseinrichtung' },
          { label: 'Massenveranstaltung' },
        ],
      },
      {
        label: 'Symptom Beginn',
        cardId: CardId.SymptomStart,
      },
      {
        label: 'Transportziel',
        cardId: CardId.Destination,
        children: [{ label: 'Voranmeldung', keywords: ['Stroke Unit', 'Herzkatheter', 'Traumazentrum'] }],
      },
    ],
  },
  {
    label: 'Notfallgeschehen, Anamnese',
    path: getPageRoute(PageType.Anamnese),
    keywords: ['Erstbefunde', 'Vormedikation', 'Vorbehandlung'],
    fields: [
      {
        label: 'Versorgungssituation',
        cardId: CardId.AnamneseNote,
        keywords: ['Erstbefunde'],
      },
      {
        label: 'Ersthelfermaßnahmen (Laien)',
        cardId: CardId.FirstAider,
      },
      {
        label: 'Firstresponder',
        cardId: CardId.FirstResponder,
      },
      {
        label: 'Multiresistente Keime',
        cardId: CardId.Multiresistant,
        keywords: ['Erstbefunde', 'Besiedelung mit multiresitenten Keimen'],
      },
      {
        label: 'Anzeichen des Patienten vor dem Ereignis',
        cardId: CardId.GeneralCondition,
        keywords: ['Erstbefunde', 'ASA'],
      },
      {
        label: 'Antikoagulanzienmedikation',
        cardId: CardId.Anticoagulants,
        keywords: ['Erstbefund', 'Blutverdünner'],
        children: [
          { label: 'TZ-Aggregationshemmer', keywords: ['Thrombozyten'] },
          { label: 'VitK-Antagonist', keywords: ['VKA', 'Vitamin'] },
          { label: 'DOAK', keywords: ['Antikoagulanzien', 'oral'] },
          { label: 'Heparin' },
        ],
      },
    ],
  },
  {
    label: 'Erstbefunde - Neurologie',
    path: getPageRoute(PageType.Neurology),
    fields: [
      {
        label: 'Lichtreaktion',
        cardId: CardId.Pupils,
        keywords: ['Erstbefunde'],
      },
      {
        label: 'Pupillenweite',
        cardId: CardId.Pupils,
        keywords: ['Erstbefunde'],
      },
      {
        label: 'Neurologische Auffälligkeiten',
        cardId: CardId.NeurologicalFeatures,
        keywords: ['Erstbefunde'],
        children: [
          { label: 'Seitenzeichen' },
          { label: 'kein Lächeln' },
          { label: 'Sprachstörung' },
          { label: 'Sehstörung' },
          { label: 'Demenz' },
          { label: 'Querschnittssymptomatik' },
          { label: 'Babinksi Zeichen' },
          { label: 'Meningismus' },
          { label: 'vorbestehende neurologische Defizite' },
        ],
      },
      {
        label: 'Bewusstseinslage - Erstbefunde',
        cardId: CardId.Consciousness,
        children: [
          { label: 'getrübt - Messwerte initial' },
          { label: 'bewusstlos - Messwerte initial' },
          { label: 'analgosediert / Narkose - Messwerte initial' },
        ],
      },
      {
        label: 'Glasgow Coma Scale (Erstbefunde)',
        cardId: CardId.Gcs,
        keywords: ['GCS', 'Erstbefunde', 'Glasgow Coma Scale'],
      },
    ],
  },
  {
    label: 'Erstbefunde - Messwerte Initial',
    path: getPageRoute(PageType.DataInitial),
    fields: [
      {
        label: 'Schmerzen',
        cardId: CardId.Pain,
      },
      {
        label: 'Vitalparameter',
        cardId: CardId.VitalParameter,
        keywords: ['qSOFA', 'CO2', 'AF', 'RR', 'HF', 'SpO2', 'Temperatur', 'EtCO2'],
      },
      {
        label: 'EKG',
        cardId: CardId.Ecg,
        children: [
          { label: 'Sinusrhythmus - Messwerte initial' },
          { label: 'Absolute Arrythmie - Messwerte initial' },
          { label: 'AV-Block II - Messwerte initial' },
          { label: 'AV-Block III - Messwerte initial' },
          { label: 'Schrittmacherrythmus - Messwerte initial' },
          { label: 'Kammerflimmern - Messwerte initial' },
          { label: 'PEA / EMD - Messwerte initial' },
          { label: 'Asystolie - Messwerte initial' },
          { label: 'STEMI - Messwerte initial', keywords: ['Infarkt', 'Myokardinfarkt'] },
          { label: 'schmale QRS-Tachykardie - Messwerte initial' },
          { label: 'breite QRS-Tachykardie - Messwerte initial' },
          { label: 'SVES / VES monomorph - Messwerte initial', keywords: ['supraventikulär', 'ventrikulär'] },
          { label: 'VES polymorph - Messwerte initial', keywords: ['ventrikulär'] },
        ],
      },
      {
        label: 'Atmung',
        cardId: CardId.Breathing,
        children: [
          { label: 'Dyspnoe - Messwerte initial' },
          { label: 'Apnoe - Messwerte initial' },
          { label: 'Beatmung - Messwerte initial' },
          { label: 'Schnappatmung - Messwerte initial' },
          { label: 'Stridor - Messwerte initial' },
          { label: 'Zyanose - Messwerte initial' },
          { label: 'Spastik - Messwerte initial' },
          { label: 'Hyperventilation - Messwerte initial' },
          { label: 'Atemwegsverlegung - Messwerte initial' },
          { label: 'Rasselgeräusche - Messwerte initial' },
        ],
      },
      {
        label: 'Haut',
        cardId: CardId.Skin,
        children: [
          { label: 'pathologisch' },
          { label: 'stehende Hautfalten' },
          { label: 'Dekubitus' },
          { label: 'Exanthem' },
          { label: 'Oedeme' },
          { label: 'kaltschweißig' },
        ],
      },
      {
        label: 'Psyche',
        cardId: CardId.Psyche,
        children: [
          { label: 'wahnhaft' },
          { label: 'depressiv' },
          { label: 'verwirrt' },
          { label: 'aggressiv' },
          { label: 'ängstlich' },
          { label: 'suizidal' },
          { label: 'verlangsamt / stuporös' },
          { label: 'euphorisch' },
          { label: 'motorisch unruhig' },
        ],
      },
    ],
  },
  {
    label: 'Erstbefunde - Erkrankungen', //COR-32
    path: getPageRoute(PageType.MedicalConditions),
    fields: [
      {
        label: 'ZNS',
        cardId: CardId.MedicalConditionZNS,
        children: [
          { label: 'Schlaganfall, TIA, intrakranielle Blutung', keywords: ['transitorisch', 'ischämisch'] },
          { label: 'im Lysefenster' },
          { label: 'Krampfanfall', keywords: ['Status epilepticus'] },
          { label: 'Menengitis / Encephalitis' },
          { label: 'Synkope' },
        ],
      },
      {
        label: 'Herz-Kreislauf',
        cardId: CardId.MedicalConditionCardiovascular,
        children: [
          { label: 'Akutes Koronarsyndrom' },
          { label: 'STEMI - Erkrankungen', keywords: ['Infarkt', 'Myokardinfarkt', 'Vorderwand', 'Hinterwand'] },
          { label: 'Rhythmusstörung', keywords: ['tachy', 'brady'] },
          { label: 'Lungenembolie' },
          { label: 'orthostatische Fehlregulation' },
          { label: 'Herzinsuffizienz' },
          { label: 'Lungenödem' },
          { label: 'hypertensiver Notfall / hypertensive Krise' },
          { label: 'kardiogener Schock' },
          { label: 'Schrittmacher-/ ICD-Fehlfunktion' },
        ],
      },
      {
        label: 'Atmung - Erkrankungen',
        cardId: CardId.MedicalConditionBreathing,
        children: [
          { label: 'Asthma (Anfall)', keywords: ['Status asthmaticus'] },
          { label: 'COPD (ggf. Exazerbation)', keywords: ['Heimbeatmung', 'Heimsauerstoff'] },
          { label: 'Pneumonie, Bronchitis' },
          { label: 'Hyperventilationssyndrom' },
          { label: 'Aspiration' },
          { label: 'Haemoptysen', keywords: ['Hämoptysen'] },
        ],
      },
      {
        label: 'Abdomen - Erkrankungen',
        cardId: CardId.MedicalConditionAbdomen,
        children: [
          { label: 'Akutes Abdomen' },
          { label: 'GI-Blutung', keywords: ['gastrointestinal, obere, untere'] },
          { label: 'Kolik (z.B. Niere, Galle)' },
          { label: 'Enteritis' },
        ],
      },
      {
        label: 'Psychiatrie',
        cardId: CardId.MedicalConditionPsychiatry,
        children: [
          { label: 'Psychose, Manie, Erregungszustand' },
          { label: 'Angst, Depression' },
          { label: 'Intoxikation', keywords: ['akizentell', 'Alkohol', 'Drogen', 'Medikamente'] },
          { label: 'Entzug, Delir' },
          { label: 'Suizid(versuch)' },
          { label: 'Psychosoziale Krise' },
        ],
      },
      {
        label: 'Stoffwechsel',
        cardId: CardId.MedicalConditionMetabolism,
        children: [
          { label: 'Hypoglykämie' },
          { label: 'Hyperglykömie' },
          { label: 'Exsiccose' },
          { label: 'Urämie / ANV', keywords: ['akut', 'Nierenversagen', 'dialysepflichtig'] },
          { label: 'bek. Dialysepflichtig' },
        ],
      },
      {
        label: 'Pädiatrie',
        cardId: CardId.MedicalConditionPediatrics,
        children: [
          { label: 'Fieberkrampf' },
          { label: 'Pseudokrupp' },
          { label: 'SIDS / Near-SIDS', keywords: ['sudden', 'infant'] },
        ],
      },
      {
        label: 'Gynäkologie',
        cardId: CardId.MedicalConditionGynecology,
        children: [
          { label: 'Schwangerschaft', keywords: ['drohende Geburt', 'präklinische Geburt'] },
          { label: '(Prä-)Eklampsie', keywords: ['Krampfanfall'] },
          { label: 'vaginale Blutung' },
        ],
      },
      {
        label: 'Sonstige - Erkrankungen',
        cardId: CardId.MedicalConditionOther,
        children: [
          {
            label: 'anaphylakt. Reaktion',
            keywords: ['anaphylaktisch', 'Allergie'],
          },
          { label: 'Hitzeerschöpfung, Hitzschlag' },
          { label: 'Unterkühlung / Erfrierung' },
          {
            label: 'hochfieb. Infekt / Sepsis / septischer Schock',
            keywords: ['hochfiebrig'],
          },
          {
            label: 'CoVid-19 / -Verdacht',
            keywords: ['Covid', 'Corona'],
          },
          { label: 'Influenza' },
          { label: 'Hepatitis / HIV' },
          { label: 'akute Lumbago' },
          { label: 'Epistaxis' },
          { label: 'soziales Problem (ohne psychische Störung)' },
          { label: 'medizinische Behandlungskomplikation' },
        ],
      },
    ],
  },
  {
    label: 'Erstbefunde - Verletzungen',
    path: getPageRoute(PageType.Injuries),
    fields: [
      {
        label: 'Verletzungen',
        cardId: CardId.InjuryCategory,
        children: [
          { label: 'Schädel-Hirn' },
          { label: 'Gesicht' },
          { label: 'Hals' },
          { label: 'Thorax' },
          { label: 'Abdomen' },
          { label: 'Wirbelsäule' },
          { label: 'Becken' },
          { label: 'Obere Extremitäten' },
          { label: 'Untere Extremitäten' },
          { label: 'Weichteile' },
          { label: 'Plytrauma' },
        ],
      },
      {
        label: 'Art / Ursache',
        cardId: CardId.InjuryCause,
        children: [
          { label: 'Verbrennung, Verbrühung' },
          { label: 'Verätzung' },
          { label: 'Verschüttung' },
          { label: 'Einklemmung' },
          { label: 'Inahalationstrauma' },
          { label: 'Elektrounfall' },
          { label: 'Beinahe Ertrinken' },
          { label: 'Tauchunfall' },
          { label: 'haemorrhargischer Schock' },
        ],
      },
      {
        label: 'Unfallmechanismus',
        cardId: CardId.InjuryAccidentMechanism,
        children: [
          { label: 'Trauma', keywords: ['stumpf', 'penetrierend'] },
          { label: 'Sturz' },
          {
            label: 'Verkehrsteilnehmer',
            keywords: ['Fußgänger', 'E-Scooter', 'Fahrrad', 'E-Bike', 'Motorrad', 'Sozius', 'PKW', 'LKW', 'Bus'],
          },
          { label: 'Gewaltanwendung', keywords: ['Schlag', 'Stich', 'Schuss', 'Gewaltverbrechen'] },
        ],
      },
      {
        label: 'Aktivität',
        cardId: CardId.InjuryActivity,
        keywords: ['sportliche', 'berufliche'],
      },
    ],
  },
  {
    label: 'Erstbefunde - Erstdiagnosen',
    path: getPageRoute(PageType.DiagnosisInitial),
    fields: [
      {
        label: 'Palliative Situation',
        cardId: CardId.DiagnosisNote,
      },
      {
        label: 'NACA Score',
        cardId: CardId.NacaScore,
      },
    ],
  },
  {
    label: 'Verlaufsbeschreibung',
    path: getPageRoute(PageType.Course),
    fields: [
      {
        label: 'Verlaufsbeschreibung - TrendsHistory',
        cardId: CardId.Trends,
      },
      {
        label: 'Sauerstoffgabe',
        cardId: CardId.Oxygenation,
        keywords: ['O2'],
      },
    ],
  },
  {
    label: 'Maßnahmen',
    path: getPageRoute(PageType.Measures),
    fields: [
      {
        label: 'Zugänge',
        cardId: CardId.TreatmentsVentilation,
        keywords: ['Vigo', 'Viggo'],
        children: [{ label: 'Zugang erschwert' }, { label: 'Zugang unmöglich' }],
      },
      {
        label: 'Beatmung - Maßnahmen',
        cardId: CardId.TreatmentsVentilation,
        keywords: ['FiO2', 'AMV', 'AF', 'PEEP', 'Pmax'],
        children: [
          { label: 'manuell', keywords: ['Beatmung'] },
          { label: 'maschinell kontrolliert', keywords: ['Beatmung'] },
          { label: 'maschinell assistiert', keywords: ['Beatmung'] },
          { label: 'maschinell NIV', keywords: ['Beatmung'] },
          { label: 'Norfallnarkose' },
        ],
      },
      {
        label: 'Atemweg - Maßnahmen',
        cardId: CardId.Airway,
        children: [
          { label: 'Sauerstoffgabe', keywords: ['Präoxygenierung', 'O2'] },
          { label: 'Freimachen der Atemwege', keywords: ['absaugen', 'Absaugpumpe'] },
          { label: 'Masken-/Beutel-Beatmung', keywords: ['Demand-Ventil'] },
          { label: 'supraglottische Atemwegshilfe', keywords: ['Larynxmaske', 'Larynxtubus'] },
          { label: 'endotracheale Intubation' },
          { label: 'Videolaryngoskop' },
          { label: 'Koniotomie / chirurgischer Atemweg', keywords: ['Atemweg, Trachealkanülwechsel'] },
          { label: 'sonstiger Atemwegszugang' },
        ],
      },
      {
        label: 'Defibrillation',
        cardId: CardId.TreatmentsDefibrillation,
        children: [
          {
            label: 'AED',
            keywords: ['Defibrillator'],
          },
        ],
      },
      {
        label: 'Reanimation - Maßnahmen',
        cardId: CardId.TreatmentsReanimation,
        children: [
          { label: 'Herzdruckmassage', keywords: ['Feedbacksystem', 'Thoraxkompressionssystem'] },
          { label: 'VV-ECMO' },
          { label: 'VA-ECMO' },
          { label: 'REBOA' },
          { label: 'Aktive Kühlung' },
        ],
      },
      {
        label: 'Sonstige - Maßnahmen',
        cardId: CardId.TreatmentsOther,
        children: [
          {
            label: 'Spezielle Lagerung',
            keywords: ['Zervikalstütze', 'Vakuumschiene', 'Vakuummatratze', 'Schaufeltrage', 'Spineboard'],
          },
          { label: '12-Kanal EKG' },
          { label: 'Spritzenpumpe(n)' },
          { label: 'Verband' },
          { label: 'Thoraxdrainage rechts' },
          { label: 'Thoraxdrainage links' },
          { label: 'Thorakotomie' },
          { label: 'Ultraschall' },
          { label: 'Entbindung' },
          { label: 'Tourniquet' },
          { label: 'Stabile Seitenlage' },
          { label: 'Schocklagerung' },
          { label: 'Oberkörper Hochlagerung' },
          { label: 'Flachlagerung' },
          { label: 'Sitzend' },
          { label: 'Funkübermittlung' },
          { label: 'Invasiver RR' },
          { label: 'Reposition' },
          { label: 'Nadelkompression' },
          { label: 'Magensonde' },
          { label: 'Krisenintervention' },
          { label: 'Haemostyptikum' },
          { label: 'Wärmeerhalt' },
          { label: 'Inkubator' },
          { label: 'externer Schrittmacher' },
          { label: 'Kardioversion' },
          { label: 'Beckenschlinge' },
          { label: 'Blasenkatheter' },
          { label: 'besondere Hygiene' },
          { label: 'Thoraxverschlusspflaster' },
        ],
      },
    ],
  },
  {
    label: 'Medikation',
    path: getPageRoute(PageType.Medication),
    fields: [
      {
        label: 'Vorabintervention durch Rettungsdienst',
        cardId: CardId.PreIntervention,
      },
      {
        label: 'Lysetherapie',
        cardId: CardId.LysisTherapy,
        children: [
          { label: 'vor Kreislaufstillstand' },
          { label: 'während Kreislaufstillstand' },
          { label: 'nach ROSC' },
        ],
      },
    ],
  },
  {
    label: 'Reanimation / Tod / Todesfeststellung',
    path: getPageRoute(PageType.ReanimationDeath),
    fields: [
      {
        label: 'Reanimation',
        cardId: CardId.Reanimation,
      },
      {
        label: 'Tod',
        cardId: CardId.Death,
        children: [{ label: 'Todeszeitpunkt' }],
      },
    ],
  },
  {
    label: 'Übergabe',
    path: getPageRoute(PageType.Handover),
    fields: [
      {
        label: 'Schmerzen - Übergabe',
        cardId: CardId.Pain,
      },
      {
        label: 'Vitalparameter - Übergabe',
        cardId: CardId.VitalParameter,
        keywords: ['qSOFA', 'CO2', 'AF', 'RR', 'HF', 'SpO2', 'Temperatur', 'EtCO2'],
      },
      {
        label: 'EKG - Übergabe',
        cardId: CardId.Ecg,
        children: [
          { label: 'Sinusrhythmus - Übergabe' },
          { label: 'Absolute Arrythmie - Übergabe' },
          { label: 'AV-Block II - Übergabe' },
          { label: 'AV-Block III - Übergabe' },
          { label: 'Schrittmacherrythmus - Übergabe' },
          { label: 'Kammerflimmern - Übergabe' },
          { label: 'PEA / EMD - Übergabe' },
          { label: 'Asystolie - Übergabe' },
          { label: 'STEMI - Übergabe', keywords: ['Infarkt', 'Myokardinfarkt'] },
          { label: 'schmale QRS-Tachykardie - Übergabe' },
          { label: 'breite QRS-Tachykardie - Übergabe' },
          { label: 'SVES / VES monomorph - Übergabe', keywords: ['supraventikulär', 'ventrikulär'] },
          { label: 'VES polymorph - Übergabe', keywords: ['ventrikulär'] },
        ],
      },
      {
        label: 'Atmung - Übergabe',
        cardId: CardId.Breathing,
        children: [
          { label: 'Dyspnoe - Übergabe' },
          { label: 'Apnoe - Übergabe' },
          { label: 'Beatmung - Übergabe' },
          { label: 'Schnappatmung - Übergabe' },
          { label: 'Stridor - Übergabe' },
          { label: 'Zyanose - Übergabe' },
          { label: 'Spastik - Übergabe' },
          { label: 'Hyperventilation - Übergabe' },
          { label: 'Atemwegsverlegung - Übergabe' },
          { label: 'Rasselgeräusche - Übergabe' },
        ],
      },
      {
        label: 'Bewusstseinslage - Übergabe',
        cardId: CardId.Consciousness,
        children: [
          { label: 'getrübt - Übergabe' },
          { label: 'bewusstlos - Übergabe' },
          { label: 'analgosediert / Narkose - Übergabe' },
        ],
      },
    ],
  },
  {
    label: 'Einsatzverlauf - Besonderheiten', // COR-40
    path: getPageRoute(PageType.Specifics),
    fields: [
      {
        label: 'Besonderheiten',
        cardId: CardId.Specifics,
        children: [
          { label: 'Übernahme aus arztbesetztem Rettungsmittel' },
          { label: 'Übernahme an arztbesetztes Rettungsmittel' },
          { label: 'Transport ins Krankenhaus' },
          { label: 'Nur Untersuchung und Behandlung' },
          { label: 'mit Arzt' },
          { label: 'RTH', keywords: ['Rettungshubschrauber'] },
          { label: 'Transport mit Sonderzeichen' },
          { label: 'mindestens eine Klinik nimmt nicht auf' },
          { label: 'Zwangsbelegung' },
          { label: 'Zwangsunterbringung' },
          { label: 'Transportverweigerung' },
          { label: 'Patient nicht transportfähig' },
          { label: 'Therapieverzicht / -beschränkung bewusst' },
          { label: 'erhöhter Hygieneaufwand' },
          { label: 'erschwerter Patienten-Zugang' },
          { label: 'techn. Rettung', keywords: ['technische Rettung'] },
          { label: 'Schwerlasttransport erforderlich' },
          { label: 'LNA am Einsatz', keywords: ['Leitender Notarzt am Einsatz', 'Ltd. Notarzt am Einsatz'] },
          { label: 'MANV', keywords: ['Massenanfall von Verletzten'] },
          { label: 'Behandlung mehrerer Patienten' },
        ],
      },
    ],
  },
  {
    label: 'Übergabe an',
    path: getPageRoute(PageType.HandoverTo),
    fields: [
      {
        label: 'Einsatzstelle',
        cardId: CardId.HandoverTo,
        children: [
          { label: 'ZNA / INA', keywords: ['Notaufnahme'] },
          { label: 'Stroke Unit' },
          { label: 'Intensivstation' },
          { label: 'OP direkt' },
          { label: 'Praxis' },
          { label: 'Hausarzt / KV-Arzt vor Ort', keywords: [' Notaufnahme'] },
          { label: 'Fachambulanz' },
          { label: 'Schockraum' },
          { label: 'Herzkatheterlabor' },
          { label: 'CPU' },
          { label: 'Allgemeinstation' },
        ],
      },
    ],
  },
  {
    label: 'Bemerkungen',
    path: getPageRoute(PageType.Remarks),
    fields: [
      {
        label: 'Maßnahmenverweigerung',
        cardId: CardId.Remarks,
      },
    ],
  },
  {
    label: 'Akronym - SAMPLER+S',
    path: getPageRoute(PageType.SAMPLERPlusS),
  },
];
