import { OxygenValue } from '../backendModels/oxygen.model';
import { RecordId } from '../backendModels/records.model';

export type OxygenRecord = { readonly id?: RecordId; readonly type: 'oxygen' } & OxygenValue;

export type OxygenTimestampRecord = { readonly timestamp: string } & OxygenRecord;

export enum OxygenApplicationMethodKeys {
  praeoxygenierung = 'PREOXYGENATION',
}
