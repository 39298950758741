export enum PredefinedEvent {
  amiodaron = 'Amiodaron',
  defibrillation = 'Defibrillation',
  extubation = 'Extubation',
  hdm = 'Start HDM',
  intubation = 'Intubation',
  suprarenin = 'Suprarenin',
  transportStart = 'Transport Start',
  transportEnd = 'Transport Ende',
  arrivedAtPatient = 'Ankunft an Patient',
  firstCommunication = 'Erstkontakt Patient',
}

export const predefinedEventsWithQuickButton = new Set([
  PredefinedEvent.defibrillation,
  PredefinedEvent.extubation,
  PredefinedEvent.hdm,
  PredefinedEvent.intubation,
  PredefinedEvent.transportStart,
  PredefinedEvent.transportEnd,
  PredefinedEvent.arrivedAtPatient,
  PredefinedEvent.firstCommunication,
]);
