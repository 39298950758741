import { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { InputState } from '../../../../backendModels/general.model';
import { NodeType } from '../../../../backendModels/report.model';
import {
  MEDICAL_CONDITION_CARDIOVASCULAR_ELEMENT_KEY,
  MedicalConditionCardiovascularRecord,
  getCardiovascularTupelsFor,
  MedicalConditionCardiovascularKeys,
} from '../../../../models/genericElements/medicalConditions/cardiovascular';
import { Draft } from 'immer';
import { checkParent, uncheckChildren } from '../../../../utils/util';
import { isGenericRecordDeletable, setGenericRecordToNormal } from '../../../../models/generic';

const checkboxes1: [MedicalConditionCardiovascularKeys, string][] = getCardiovascularTupelsFor([
  MedicalConditionCardiovascularKeys.acs,
  MedicalConditionCardiovascularKeys.stemi,
]);

const checkboxes2: [MedicalConditionCardiovascularKeys, string][] = getCardiovascularTupelsFor([
  MedicalConditionCardiovascularKeys.stemiVorderwand,
  MedicalConditionCardiovascularKeys.stemiHinterwand,
]);

const checkbox3: [MedicalConditionCardiovascularKeys, string][] = getCardiovascularTupelsFor([
  MedicalConditionCardiovascularKeys.rhythmusstoerung,
]);

const checkboxes4: [MedicalConditionCardiovascularKeys, string][] = getCardiovascularTupelsFor([
  MedicalConditionCardiovascularKeys.rhythmusstoerungTachycard,
  MedicalConditionCardiovascularKeys.rhythmusstoerungBradycard,
]);

const checkboxes5: [MedicalConditionCardiovascularKeys, string][] = getCardiovascularTupelsFor([
  MedicalConditionCardiovascularKeys.lungenembolie,
  MedicalConditionCardiovascularKeys.orthostase,
  MedicalConditionCardiovascularKeys.herzinsuffizienz,
  MedicalConditionCardiovascularKeys.lungenoedem,
  MedicalConditionCardiovascularKeys.hypertensiverNotfall,
  MedicalConditionCardiovascularKeys.kardiogenerSchock,
  MedicalConditionCardiovascularKeys.schrittmacherFehler,
]);

interface MedicalConditionCardiovascularProps {
  nodeType: NodeType;
}

export default function MedicalConditionCardiovascular({ nodeType }: MedicalConditionCardiovascularProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault(
    'generic',
    nodeType,
    MEDICAL_CONDITION_CARDIOVASCULAR_ELEMENT_KEY,
  ) as MedicalConditionCardiovascularRecord;
  const conditions =
    record.inputState === InputState.ENTERED ? (record.values.erkrankungenKreislauf?.options ?? []) : [];

  const conditionsChange = (newConditions: MedicalConditionCardiovascularKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<MedicalConditionCardiovascularRecord>, deleteRecord) => {
        if (newConditions.length > 0) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            erkrankungenKreislauf: {
              fieldType: 'multiSelect',
              options: newConditions,
            },
          };
        } else {
          delete draft.values?.erkrankungenKreislauf;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      MEDICAL_CONDITION_CARDIOVASCULAR_ELEMENT_KEY,
    );
  };
  const otherChange = (value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<MedicalConditionCardiovascularRecord>, deleteRecord) => {
        if (value !== '') {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            erkrankungenKreislaufSonstige: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.erkrankungenKreislaufSonstige;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      MEDICAL_CONDITION_CARDIOVASCULAR_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard
      cardType={CardId.MedicalConditionCardiovascular}
      nodeType={nodeType}
      recordType='generic'
      elementKey={MEDICAL_CONDITION_CARDIOVASCULAR_ELEMENT_KEY}
      normalStateButtonVisible={false}
      onNormalStateButtonClicked={() =>
        setGenericRecordToNormal(adaptRecord, nodeType, MEDICAL_CONDITION_CARDIOVASCULAR_ELEMENT_KEY)
      }
    >
      <div className='symptoms-list'>
        <CheckboxList
          items={checkboxes1}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              uncheckChildren(newConditions, MedicalConditionCardiovascularKeys.stemi, [
                MedicalConditionCardiovascularKeys.stemiVorderwand,
                MedicalConditionCardiovascularKeys.stemiHinterwand,
              ]),
            )
          }
        />
        <CheckboxList
          className='indented'
          items={checkboxes2}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              checkParent(newConditions, MedicalConditionCardiovascularKeys.stemi, [
                MedicalConditionCardiovascularKeys.stemiVorderwand,
                MedicalConditionCardiovascularKeys.stemiHinterwand,
              ]),
            )
          }
        />
        <CheckboxList
          items={checkbox3}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              uncheckChildren(newConditions, MedicalConditionCardiovascularKeys.rhythmusstoerung, [
                MedicalConditionCardiovascularKeys.rhythmusstoerungTachycard,
                MedicalConditionCardiovascularKeys.rhythmusstoerungBradycard,
              ]),
            )
          }
        />
        <CheckboxList
          className='indented'
          items={checkboxes4}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              checkParent(newConditions, MedicalConditionCardiovascularKeys.rhythmusstoerung, [
                MedicalConditionCardiovascularKeys.rhythmusstoerungTachycard,
                MedicalConditionCardiovascularKeys.rhythmusstoerungBradycard,
              ]),
            )
          }
        />
        <CheckboxList items={checkboxes5} selectedValues={conditions} onValuesChange={conditionsChange} />
      </div>
      <DebouncedTextField
        fullWidth
        label='Sonstige...'
        value={
          record.inputState === InputState.ENTERED ? (record.values.erkrankungenKreislaufSonstige?.text ?? '') : ''
        }
        onDebounceChange={otherChange}
        inputProps={{ maxLength: 18 }}
      />
    </DiviFieldCard>
  );
}
