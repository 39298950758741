import { CardId } from '../../../models/diviCard';
import Gcs from '../Neurology/Gcs/Gcs';
import LimbMovements from '../Neurology/LimbMovements/LimbMovements';
import Consciousness from '../Neurology/Consciousness/Consciousness';
import NeurologicalFeatures from '../Neurology/NeurologicalFeatures/NeurologicalFeatures';
import Pupils from '../Neurology/Pupils/Pupils';
import Breathing from '../InitialFindings/Breathing/Breathing';
import Ecg from '../InitialFindings/Ecg/Ecg';
import Pain from '../InitialFindings/Pain/Pain';
import Psyche from '../InitialFindings/Psyche/Psyche';
import Skin from '../InitialFindings/Skin/Skin';
import VitalParameter from '../InitialFindings/VitalParameter/VitalParameter';
import InjuriesActivity from '../Injuries/InjuriesActivity/InjuriesActivity';
import InjuriesCause from '../Injuries/InjuriesCause/InjuriesCause';
import InjuriesCategory from '../Injuries/InjuriesCategory/InjuriesCategory';
import InjuriesAccidentMechanism from '../Injuries/InjuriesAccidentMechanism/InjuriesAccidentMechanism';
import NacaScore from '../InitialDiagnosis/NacaScore/NacaScore';
import DiagnosisNote from '../InitialDiagnosis/DiagnosisNote/DiagnosisNote';
import PalliativeSituation from '../InitialDiagnosis/PalliativeSituation/PalliativeSituation';
import PatientData from '../PatientMasterData/PatientData/PatientData';
import Gender from '../PatientMasterData/Gender/Gender';
import Bmi from '../PatientMasterData/Bmi/Bmi';
import Age from '../PatientMasterData/Age/Age';
import Mission from '../PatientMasterData/Mission/Mission';
import Participants from '../OperationalData/Participants/Participants';
import MissionType from '../OperationalData/MissionType/Type';
import MissionLocation from '../OperationalData/MissionLocation/Location';
import SymptomStart from '../OperationalData/SymptomStart/SymptomStart';
import Destination from '../OperationalData/Destination/Destination';
import RemarksNote from '../Remarks/RemarksNote/RemarksNote';
import Specifics from '../Specifics/Specifics';
import TreatmentsReanimation from '../Treatments/Reanimation/TreatmentsReanimation';
import Death from '../ReanimationDeath/Death/Death';
import AnamneseNote from '../Anamnese/AnamneseNote/AnamneseNote';
import TreatmentSituation from '../Anamnese/TreatmentSituation/TreatmentSituation';
import Multiresistant from '../Anamnese/Multiresistant/Multiresistant';
import Anticoagulants from '../Anamnese/Anticoagulants/Anticoagulants';
import GeneralCondition from '../Anamnese/GeneralCondition/GeneralCondition';
import FirstResponder from '../Anamnese/FirstResponder/FirstResponder';
import FirstAider from '../Anamnese/FirstAider/FirstAider';
import AccessRoute from '../Treatments/AccessRoute/AccessRoute';
import Airway from '../Treatments/Airway/Airway';
import PreIntervention from '../Medication/PreIntervention/PreIntervention';
import LysisTherapy from '../Medication/LysisTherapy/LysisTherapy';
import TreatmentsVentilation from '../Treatments/Ventilation/Ventilation';
import TreatmentsDefibrillation from '../Treatments/Defibrillation/Defibrillation';
import TreatmentsOther from '../Treatments/Other/Other';
import MedicalConditionZNS from '../MedicalConditions/ZNS/MedicalConditionZNS';
import MedicalConditionCardiovascular from '../MedicalConditions/Cardiovascular/MedicalConditionCardiovascular';
import MedicalConditionBreathing from '../MedicalConditions/Breathing/MedicalConditionBreathing';
import MedicalConditionAbdomen from '../MedicalConditions/Abdomen/MedicalConditionAbdomen';
import MedicalConditionPsychiatry from '../MedicalConditions/Psychiatry/MedicalConditionPsychiatry';
import MedicalConditionMetabolism from '../MedicalConditions/Metabolism/MedicalConditionMetabolism';
import MedicalConditionPediatrics from '../MedicalConditions/Pediatrics/MedicalConditionPediatrics';
import MedicalConditionGynecology from '../MedicalConditions/Gynecology/MedicalConditionGynecology';
import MedicalConditionOther from '../MedicalConditions/Other/MedicalConditionOther';
import SAMPLERPlusSNote from '../SAMPLER+S/SAMPLER+SNote/SAMPLERPlusSNote';
import TrendsHistory from '../Trends/TrendsHistory/TrendsHistory';
import TrendsOxygenation from '../Trends/Oxygenation/TrendsOxygenation';
import Reanimation from '../ReanimationDeath/Reanimation/Reanimation';
import HandoverTo from '../HandoverTo/HandoverToDetails/HandoverTo';
import MedicationTable from '../Medication/Medication/MedicationTable';
import { NodeType } from '../../../backendModels/report.model';

export const COMPONENT_MAP: { [key: string]: React.ElementType } = {
  //type = initialSurvey
  AnamneseNote: AnamneseNote,
  FirstAider: FirstAider,
  FirstResponder: FirstResponder,
  Anticoagulants: Anticoagulants,
  GeneralCondition: GeneralCondition,
  Multiresistant: Multiresistant,
  TreatmentSituation: TreatmentSituation,
  LimbMovements: LimbMovements,
  Consciousness: Consciousness,
  NeurologicalFeatures: NeurologicalFeatures,
  Pupils: Pupils,
  InjuriesActivity: InjuriesActivity,
  InjuriesCause: InjuriesCause,
  InjuriesCategory: InjuriesCategory,
  InjuriesAccidentMechanism: InjuriesAccidentMechanism,
  NacaScore: NacaScore,
  DiagnosisNote: DiagnosisNote,
  PalliativeSituation: PalliativeSituation,
  MedicalConditionZNS: MedicalConditionZNS,
  MedicalConditionCardiovascular: MedicalConditionCardiovascular,
  MedicalConditionBreathing: MedicalConditionBreathing,
  MedicalConditionAbdomen: MedicalConditionAbdomen,
  MedicalConditionPsychiatry: MedicalConditionPsychiatry,
  MedicalConditionMetabolism: MedicalConditionMetabolism,
  MedicalConditionPediatrics: MedicalConditionPediatrics,
  MedicalConditionGynecology: MedicalConditionGynecology,
  MedicalConditionOther: MedicalConditionOther,
  SamplerPlusSNote: SAMPLERPlusSNote,

  // type = handover || initialSurvey
  Breathing: Breathing,
  Gcs: Gcs,
  Ecg: Ecg,
  Pain: Pain,
  Psyche: Psyche,
  Skin: Skin,
  VitalParameter: VitalParameter,

  // type = handover
  HandoverTo: HandoverTo,

  // type = metadata
  PatientData: PatientData,
  Gender: Gender,
  Bmi: Bmi,
  Age: Age,
  Mission: Mission,
  Participants: Participants,
  MissionType: MissionType,
  MissionLocation: MissionLocation,
  SymptomStart: SymptomStart,
  Destination: Destination,

  // type = generic
  AccessRoute: AccessRoute,
  Airway: Airway,
  MedicationTable: MedicationTable,
  PreIntervention: PreIntervention,
  LysisTherapy: LysisTherapy,
  Reanimation: Reanimation,
  Death: Death,
  Specifics: Specifics,
  RemarksNote: RemarksNote,
  TreatmentsVentilation: TreatmentsVentilation,
  TreatmentsDefibrillation: TreatmentsDefibrillation,
  TreatmentsReanimation: TreatmentsReanimation,
  TreatmentsOther: TreatmentsOther,
  TrendsHistory: TrendsHistory,
  TrendsOxygenation: TrendsOxygenation,
};

export const CARDID_MAP: { [key: string]: CardId } = {
  AnamneseNote: CardId.AnamneseNote,
  FirstAider: CardId.FirstAider,
  FirstResponder: CardId.FirstResponder,
  Anticoagulants: CardId.Anticoagulants,
  GeneralCondition: CardId.GeneralCondition,
  Multiresistant: CardId.Multiresistant,
  TreatmentSituation: CardId.TreatmentSituation,
  LimbMovements: CardId.LimbMovements,
  Consciousness: CardId.Consciousness,
  NeurologicalFeatures: CardId.NeurologicalFeatures,
  Pupils: CardId.Pupils,
  Psyche: CardId.Psyche,
  Skin: CardId.Skin,
  InjuriesActivity: CardId.InjuryActivity,
  InjuriesCause: CardId.InjuryCause,
  InjuriesCategory: CardId.InjuryCategory,
  InjuriesAccidentMechanism: CardId.InjuryAccidentMechanism,
  NacaScore: CardId.NacaScore,
  DiagnosisNote: CardId.DiagnosisNote,
  PalliativeSituation: CardId.PalliativeSituation,
  MedicalConditionZNS: CardId.MedicalConditionZNS,
  MedicalConditionCardiovascular: CardId.MedicalConditionCardiovascular,
  MedicalConditionBreathing: CardId.MedicalConditionBreathing,
  MedicalConditionAbdomen: CardId.MedicalConditionAbdomen,
  MedicalConditionPsychiatry: CardId.MedicalConditionPsychiatry,
  MedicalConditionMetabolism: CardId.MedicalConditionMetabolism,
  MedicalConditionPediatrics: CardId.MedicalConditionPediatrics,
  MedicalConditionGynecology: CardId.MedicalConditionGynecology,
  MedicalConditionOther: CardId.MedicalConditionOther,
  SamplerPlusSNote: CardId.SamplerPlusSNote,

  Breathing: CardId.Breathing,
  Gcs: CardId.Gcs,
  Ecg: CardId.Ecg,
  Pain: CardId.Pain,
  VitalParameter: CardId.VitalParameter,

  HandoverTo: CardId.HandoverTo,

  PatientData: CardId.PatientData,
  Gender: CardId.Gender,
  Bmi: CardId.Bmi,
  Age: CardId.Age,
  Mission: CardId.Mission,
  Participants: CardId.Participants,
  MissionType: CardId.MissionType,
  MissionLocation: CardId.MissionLocation,
  SymptomStart: CardId.SymptomStart,
  Destination: CardId.Destination,

  AccessRoute: CardId.AccessRoute,
  Airway: CardId.Airway,
  MedicationTable: CardId.MedicationTable,
  PreIntervention: CardId.PreIntervention,
  LysisTherapy: CardId.LysisTherapy,
  Reanimation: CardId.Reanimation,
  Death: CardId.Death,
  Specifics: CardId.Specifics,
  RemarksNote: CardId.Remarks,
  TreatmentsVentilation: CardId.TreatmentsVentilation,
  TreatmentsDefibrillation: CardId.TreatmentsDefibrillation,
  TreatmentsReanimation: CardId.TreatmentsReanimation,
  TreatmentsOther: CardId.TreatmentsOther,
  TrendsHistory: CardId.Trends,
  TrendsOxygenation: CardId.Oxygenation,
};

export const CARD_TYPE_MAP: { [key: string]: NodeType } = {
  AnamneseNote: NodeType.INITIAL_SURVEY,
  FirstAider: NodeType.INITIAL_SURVEY,
  FirstResponder: NodeType.INITIAL_SURVEY,
  Anticoagulants: NodeType.INITIAL_SURVEY,
  GeneralCondition: NodeType.INITIAL_SURVEY,
  Multiresistant: NodeType.INITIAL_SURVEY,
  TreatmentSituation: NodeType.INITIAL_SURVEY,
  LimbMovements: NodeType.INITIAL_SURVEY,
  Psyche: NodeType.INITIAL_SURVEY,
  Skin: NodeType.INITIAL_SURVEY,
  NeurologicalFeatures: NodeType.INITIAL_SURVEY,
  Pupils: NodeType.INITIAL_SURVEY,
  InjuriesActivity: NodeType.INITIAL_SURVEY,
  InjuriesCause: NodeType.INITIAL_SURVEY,
  InjuriesCategory: NodeType.INITIAL_SURVEY,
  InjuriesAccidentMechanism: NodeType.INITIAL_SURVEY,
  NacaScore: NodeType.INITIAL_SURVEY,
  DiagnosisNote: NodeType.INITIAL_SURVEY,
  PalliativeSituation: NodeType.INITIAL_SURVEY,
  MedicalConditionZNS: NodeType.INITIAL_SURVEY,
  MedicalConditionCardiovascular: NodeType.INITIAL_SURVEY,
  MedicalConditionBreathing: NodeType.INITIAL_SURVEY,
  MedicalConditionAbdomen: NodeType.INITIAL_SURVEY,
  MedicalConditionPsychiatry: NodeType.INITIAL_SURVEY,
  MedicalConditionMetabolism: NodeType.INITIAL_SURVEY,
  MedicalConditionPediatrics: NodeType.INITIAL_SURVEY,
  MedicalConditionGynecology: NodeType.INITIAL_SURVEY,
  MedicalConditionOther: NodeType.INITIAL_SURVEY,
  SamplerPlusSNote: NodeType.INITIAL_SURVEY,

  HandoverTo: NodeType.HANDOVER,

  PatientData: NodeType.METADATA,
  Gender: NodeType.METADATA,
  Bmi: NodeType.METADATA,
  Age: NodeType.METADATA,
  Mission: NodeType.METADATA,
  Participants: NodeType.METADATA,
  MissionType: NodeType.METADATA,
  MissionLocation: NodeType.METADATA,
  SymptomStart: NodeType.METADATA,
  Destination: NodeType.METADATA,

  AccessRoute: NodeType.GENERIC,
  Airway: NodeType.GENERIC,
  MedicationTable: NodeType.GENERIC,
  PreIntervention: NodeType.GENERIC,
  LysisTherapy: NodeType.GENERIC,
  Reanimation: NodeType.GENERIC,
  Death: NodeType.GENERIC,
  Specifics: NodeType.GENERIC,
  RemarksNote: NodeType.GENERIC,
  TreatmentsVentilation: NodeType.GENERIC,
  TreatmentsDefibrillation: NodeType.GENERIC,
  TreatmentsReanimation: NodeType.GENERIC,
  TreatmentsOther: NodeType.GENERIC,
  TrendsHistory: NodeType.GENERIC,
  TrendsOxygenation: NodeType.GENERIC,
};
