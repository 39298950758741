import { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import style from './Death.scss?inline';
import { Box } from '@mui/material';
import {
  DeathKindKeys,
  DeathUnknownTimeKeys,
  DeathYesKeys,
  getDeathTuplesFor,
  REANIMATION_DEATH_RECORD_ELEMENT_KEY,
  ReanimationDeathRecord,
} from '../../../../models/genericElements/reanimationDeath';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs, { Dayjs } from 'dayjs';
import { useCSS } from '../../../../provider/CSSProvider';
import { CardId } from '../../../../models/diviCard';
import RadioList from '../../../customs/radioList/RadioList';
import { NodeType } from '../../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { InputState } from '../../../../backendModels/general.model';
import { Draft } from 'immer';
import { isGenericRecordDeletable } from '../../../../models/generic';

const determinationCheckboxItem: [DeathYesKeys, string][] = getDeathTuplesFor([DeathYesKeys.todesfeststellung]);

const determinationRadioItems: [DeathKindKeys, string][] = getDeathTuplesFor([
  DeathKindKeys.todesfeststellungnatuerlich,
  DeathKindKeys.todesfeststellungunklar,
  DeathKindKeys.todesfeststellungnichtnatuerlich,
]);

const timeOfDeathItem: [DeathUnknownTimeKeys, string][] = getDeathTuplesFor([
  DeathUnknownTimeKeys.todeszeitpunktnichtbestimmbar,
]);

interface DeathProps {
  nodeType: NodeType;
}

export default function Death({ nodeType }: DeathProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(
    'generic',
    nodeType,
    REANIMATION_DEATH_RECORD_ELEMENT_KEY,
  ) as ReanimationDeathRecord;

  const timeString =
    record.inputState == InputState.ENTERED ? (record.values.reanimationZusaetzeTodeszeitpunkt?.text ?? null) : null;
  const timeDayjs = timeString != null ? dayjs(timeString, 'HH:mm') : null;

  const updateDidDie = (newValues: DeathYesKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ReanimationDeathRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (newValues.length > 0) {
          draft.values = {
            ...draft.values,
            reanimationZusaetzeTod: {
              fieldType: 'multiSelect',
              options: newValues,
            },
          };
        } else {
          delete draft.values?.reanimationZusaetzeTod;
          delete draft.values?.reanimationZusaetzeTodArt;
          delete draft.values?.reanimationZusaetzeTodeszeitpunkt;
          delete draft.values?.reanimationZusaetzeTodeszeitpunktUnklar;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      REANIMATION_DEATH_RECORD_ELEMENT_KEY,
    );
  };

  const updateKind = (newValue: DeathKindKeys) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ReanimationDeathRecord>) => {
        draft.inputState = InputState.ENTERED;
        draft.values = {
          ...draft.values,
          reanimationZusaetzeTod: {
            fieldType: 'multiSelect',
            options: [DeathYesKeys.todesfeststellung],
          },
          reanimationZusaetzeTodArt: {
            fieldType: 'singleSelect',
            option: newValue,
          },
        };
      },
      REANIMATION_DEATH_RECORD_ELEMENT_KEY,
    );
  };

  const updateTime = (newTime: Dayjs | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ReanimationDeathRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (newTime != null && newTime.isValid()) {
          delete draft.values?.reanimationZusaetzeTodeszeitpunktUnklar;
          draft.values = {
            ...draft.values,
            reanimationZusaetzeTod: {
              fieldType: 'multiSelect',
              options: [DeathYesKeys.todesfeststellung],
            },
            reanimationZusaetzeTodeszeitpunkt: {
              fieldType: 'text',
              text: newTime.format('HH:mm'),
            },
          };
        } else {
          delete draft.values?.reanimationZusaetzeTodeszeitpunkt;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      REANIMATION_DEATH_RECORD_ELEMENT_KEY,
    );
  };

  const updateUnknownTime = (newValues: DeathUnknownTimeKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ReanimationDeathRecord>) => {
        draft.inputState = InputState.ENTERED;
        if (newValues.length > 0) {
          delete draft.values?.reanimationZusaetzeTodeszeitpunkt;
          draft.values = {
            ...draft.values,
            reanimationZusaetzeTod: {
              fieldType: 'multiSelect',
              options: [DeathYesKeys.todesfeststellung],
            },
            reanimationZusaetzeTodeszeitpunktUnklar: {
              fieldType: 'multiSelect',
              options: newValues,
            },
          };
        } else {
          delete draft.values?.reanimationZusaetzeTodeszeitpunktUnklar;
        }
      },
      REANIMATION_DEATH_RECORD_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard cardType={CardId.Death}>
      <CheckboxList
        className='symptoms-list'
        items={determinationCheckboxItem}
        selectedValues={
          record.inputState === InputState.ENTERED ? (record.values.reanimationZusaetzeTod?.options ?? []) : []
        }
        onValuesChange={updateDidDie}
      />
      <div className='symptoms-list indented-checkbox-parent'>
        <RadioList
          items={determinationRadioItems}
          name='death-group'
          value={
            record.inputState === InputState.ENTERED ? (record.values.reanimationZusaetzeTodArt?.option ?? null) : null
          }
          onChange={(event) => updateKind(event.target.value as DeathKindKeys)}
        />
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box textAlign='center' className='centered time-of-death'>
          <TimeField
            clearable
            name='time of death'
            label='Todeszeitpunkt'
            fullWidth
            format='HH:mm'
            value={timeDayjs}
            onChange={updateTime}
          />
        </Box>
      </LocalizationProvider>
      <div className='symptoms-list'>
        <CheckboxList
          items={timeOfDeathItem}
          selectedValues={
            record.inputState === InputState.ENTERED
              ? (record.values.reanimationZusaetzeTodeszeitpunktUnklar?.options ?? [])
              : []
          }
          onValuesChange={updateUnknownTime}
        />
      </div>
    </DiviFieldCard>
  );
}
